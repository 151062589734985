import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HotkeysService } from 'angular2-hotkeys';
import * as moment from 'moment';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { RptsalessummaryreportService } from 'src/app/core/Services/reports/Sales/RptSalesSummaryReport/rptsalessummaryreport.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { ExportUtility } from 'src/assets/js/export-utility';



@Component({
  selector: 'app-rptsalessummary',
  templateUrl: './rptsalessummary.component.html',
  styleUrls: ['./rptsalessummary.component.css']
})
export class RptsalessummaryComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptsalesform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _currencylist: Currency[] = [];
  userid: number;
  _SalesReportPrintObj: any;
  branchid: number[] = [];
  currencyid: number[] = [];
  branchselectionlimit: number = 1;
  _Action: IAction;
  _exceldisable: boolean = true;
  _ReportLists: any[] = []
  mindate: Date;
  maxdate: Date;
  _Rightslist: any[];
  _Series = sessionStorage["Series"];

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptsalesService: RptsalessummaryreportService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService,
    private sanitizer: DomSanitizer,
    private exportUtility: ExportUtility
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(150);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._exceldisable = true;
    this._rptsalesform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      currencyid: [0],
      rights: []

    })
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesform.controls['fromdate'].setValue(new Date());
      this._rptsalesform.controls['todate'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._rptsalesform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }

    this._rptsalesform.controls['currencyid'].setValue(1);
    this.OnChangeDate();
    this._IsProgressSpinner = true;
    this._RptsalesService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._currencylist = resultJSON.currencyList;
      this._Rightslist = resultJSON.rightslist
      this.RightsChange();
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnChangeDate() {
    var fromdate = moment(this._rptsalesform.get("fromdate").value, "YYYY-MM-DD"); //Previous used query
    var todate = moment(this._rptsalesform.get("todate").value, "YYYY-MM-DD");   //Previous used query
    var caldays = todate.diff(fromdate, 'days');
    const startOfMonth = moment().startOf('month').format("YYYY-MM-DD");
    const endOfMonth = moment().endOf('month').format("YYYY-MM-DD");
    if (caldays > 0) {
      this.branchselectionlimit = caldays;
      this.branchid = [];
      this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
      if (this._userid == 1 && caldays <= 31) {
        this.branchselectionlimit = 0;
        this.branchid = [];
        this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
      }
    } else {
      this.branchselectionlimit = 0;
      this.branchid = [];
      this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    }
  }
  clear(event) {
    this._rptsalesform.reset();
    this._exceldisable = true;
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesform.controls['fromdate'].setValue(new Date());
      this._rptsalesform.controls['todate'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._rptsalesform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    this.branchid = [];
    this.currencyid = [];
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.RightsChange();
  }
  GenerateReport(event) {
    var _fromdate = this._rptsalesform.get("fromdate").value;
    var _todate = this._rptsalesform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var Rights = this._rptsalesform.get("rights").value;

    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var branchid = this.branchid;
    var currencyid = this.currencyid;
    var mediaType = 'application/pdf';
    this._exceldisable = false;
    this._IsProgressSpinner = true;
    this._SalesReportPrintObj = {
      "Branchid": branchid || parseInt(sessionStorage["currentbranchid"]), "Currency": currencyid, "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate, "ToDate": todate, "Branch": parseInt(sessionStorage["currentbranchid"]),
      "rights": Rights
    }
    this._RptsalesService.Print(this._SalesReportPrintObj).subscribe((result) => {

      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  GenerateReportexcel() {
    var _fromdate = this._rptsalesform.get("fromdate").value;
    var _todate = this._rptsalesform.get("todate").value;
    var Rights = this._rptsalesform.get("rights").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var branchid = this.branchid;
    var currencyid = this.currencyid;
    this._exceldisable = false;
    this._IsProgressSpinner = true;
    this._SalesReportPrintObj = {
      "Branchid": branchid || parseInt(sessionStorage["currentbranchid"]),
      "Currency": currencyid,
      "Userid": parseInt(sessionStorage["userid"]),
      "FromDate": fromdate, "ToDate": todate,
      "Branch": parseInt(sessionStorage["currentbranchid"]),
      "rights": Rights
    }
    this._RptsalesService.Printexcel(this._SalesReportPrintObj).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // var blob = new Blob([result], { type: mediaType });
      // var url = window.URL.createObjectURL(blob);
      // this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._ReportLists = resultJSON.rptSalesSummaryHeaders;
      this._IsProgressSpinner = false;
      this.exportExcel();
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  exportExcel() {
    let SalesReportList = this.GetSalesList();
    this.exportUtility.exportExcel(SalesReportList, 'Sales_Summary_Report', 'xlsx');
  }

  GetSalesList() {
    let salesList = [];
    if (this._ReportLists == undefined) {
      this._CustomExceptionService.handleError("No Data available");
      //return
    }
    this._ReportLists.forEach(element => {
      let sales: any = {};
      sales["Branch Name"] = element.branchname
      sales["Invoice Amount"] = element.invoiceamount;
      sales["Return Amount"] = element.salesreturnamount;
      sales["Total Balance Amount"] = element.totalinvoiceamount;
      sales["Amount in USD"] = element.amountinusd;

      salesList.push(sales);
    });
    return salesList;
  }

  RightsChange() {
    if (this._Series == "MSC00093") {
      this._rptsalesform.controls["rights"].setValue("MSC00093");
      this._rptsalesform.get("rights").disable();
    }
    else {
      this._rptsalesform.controls["rights"].setValue("MSC00339");
    }
  }
}