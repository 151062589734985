<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Goods Receipt Note</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" [disabled]="isDisabledsave" (click)="onSave()" title="Save"
                                icon="pi pi-save" class=""></button>
                            <button pButton pRipple label="" [disabled]="isDisablereset" (click)="reset($event)"
                                title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRipple title="Send To Approval" icon="las la-check" type="button"
                                [disabled]="_isDisabledsendtoapproval" (click)="SendToApproval($event)"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search"
                                (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_grnform">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText formControlName="grnno">
                                    <label for="id">Goods Receipt Note No.</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" name="grndate" formControlName="grndate"
                                        dateFormat="dd/mm/yy" [minDate]="mindate" [maxDate]="maxdate">
                                    </p-calendar>
                                    <label for="quoteDate">Goods Receipt Note Date <span
                                            class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" (onChange)="onchangeinwardtype($event)"
                                        [options]="_inwardtype" optionValue="metasubcode"
                                        [disabled]="isDisabledinwardtype" optionLabel="metasubdescription"
                                        formControlName="inwardtype">
                                    </p-dropdown>
                                    <label for="productid">Inward Type<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_grnform.get('inwardtype').touched || _submitted) && _grnform.get('inwardtype').errors?.SelectInwardType">
                                    Please Select Inward Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [disabled]="isDisabledinwardno"
                                        (onChange)="onSelectinwardno($event)" [filter]="true" filterBy="inwardno"
                                        [options]="_inwardno" optionValue="inwardid" optionLabel="inwardno"
                                        formControlName="inwardid">
                                    </p-dropdown>
                                    <label for="productid">Inward No<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_grnform.get('inwardid').touched || _submitted) && _grnform.get('inwardid').errors?.InwardNumberRequired">
                                    Please Select Inward No
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="invoicebarcode" autocomplete="off"
                                        [(ngModel)]="invoicebarcode" [ngModelOptions]="{standalone: true}" pInputText
                                        [disabled]=isdisabledbarcode (keyup.enter)="FetchBarcode()">
                                    <label for="invoicebarcode">Bar code</label>
                                </span>
                            </td>
                        </tr>
                        <tr>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_supplier_branch"
                                        [disabled]="true" optionValue="supplierid" optionLabel="suppliername"
                                        formControlName="supplierorbranchid">
                                    </p-dropdown>
                                    <label for="id">Supplier/Branch Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_grnform.get('supplierorbranchid').touched || _submitted) && _grnform.get('supplierorbranchid').errors?.SupplierorbranchRequired">
                                    Please Select Supplier/Branch Name
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_receivedbranch" [disabled]="true"
                                        optionValue="branchid" optionLabel="branchname"
                                        formControlName="receivedbranch">
                                    </p-dropdown>
                                    <label for="quoteDate">Received Branch <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_grnform.get('receivedbranch').touched || _submitted) && _grnform.get('receivedbranch').errors?.SelectReceivedBranch">
                                    Please Select Supplier/Branch Name
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_currency" [disabled]="ISdisable"
                                        optionValue="currencyid" (onChange)="GetCurrency($event)"
                                        optionLabel="currencyname" formControlName="currencyid">
                                    </p-dropdown>
                                    <label for="productid">Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_grnform.get('currencyid').touched || _submitted) && _grnform.get('currencyid').errors?.SelectCurrencyName">
                                    Please Select Currency
                                </span>
                            </td>
                            <td>
                                <span class="p-float-label">
                                    <input type="text" disabled pInputText [(ngModel)]="InVoiceNo"
                                        [ngModelOptions]="{standalone: true}">
                                    <label for="id">Invoice No</label>
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-6">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-6">
                        </div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_GrnDetails" [rows]="50" [paginator]="true"
                        [rowsPerPageOptions]="[50,100,150,200]" [globalFilterFields]="['productname','variantdescription','referenceno','lotnumber','invoicequantity',
                        'receivequantity','damagequantity', 'remainingquantity', 'invoiceunitrice', 'taxamount',
                        'productamount', 'expirydate', 'remarks']" [rowHover]="true" dataKey="productid"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [(selection)]="selectedProductDetailsRows">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width:100px;" class="text-center">Action</th>
                                <th>Reference No.</th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>Lot Number</th>
                                <th>Po Quantity</th>
                                <th>Receive Quantity</th>
                                <th>Damage Quantity</th>
                                <th>Missing Quantity</th>
                                <th>UOM</th>
                                <th>Rate</th>
                                <th> Tax % </th>
                                <th>Tax Amount</th>
                                <th>Value</th>
                                <th> Expiry Date</th>
                                <th style="text-align: center;"> Is Consignment Product</th>
                                <th>Remarks</th>
                                <th *ngIf="_action == 'view'">Actual Stock Adjustment</th>
                                <th>Supplier Stock Update</th>
                                <th *ngIf="inwardtype == 'MSC00189'">Price Update</th>
                                <th>Receiver Stock Update</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_GrnDetail let-i="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple title="Remove" [disabled]="isDisabledremoveproduct"
                                        icon="pi pi-trash" class="p-button-danger p-mr-2"
                                        (click)="RemoveProductDetail(_GrnDetail)"></button>
                                </td>
                                <td>
                                    {{_GrnDetail.referenceno}}
                                </td>
                                <td>
                                    {{_GrnDetail.productname}}
                                </td>
                                <td>
                                    {{_GrnDetail.variantdescription}}
                                </td>
                                <td>
                                    {{_GrnDetail.lotnumber}}
                                </td>
                                <td>
                                    {{_GrnDetail.invoicequantity}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangereceivedquantity(_GrnDetail,$event)"
                                                *ngIf="_GrnDetail.uomcode != 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (keyup.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (focusout)="onchangereceivedquantity(_GrnDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                (keydown)="PreventMinus($event)"
                                                [(ngModel)]="_GrnDetail.receivequantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangereceivedquantity(_GrnDetail,$event)"
                                                *ngIf="_GrnDetail.uomcode == 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (keyup.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (focusout)="onchangereceivedquantity(_GrnDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                (keydown)="PreventMinus($event)"
                                                [(ngModel)]="_GrnDetail.receivequantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_GrnDetail.receivequantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_GrnDetail.receivequantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangereceivedquantity(_GrnDetail,$event)"
                                                *ngIf="_GrnDetail.uomcode != 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (keyup.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (focusout)="onchangereceivedquantity(_GrnDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_GrnDetail.damagequantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangereceivedquantity(_GrnDetail,$event)"
                                                *ngIf="_GrnDetail.uomcode == 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (keyup.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (focusout)="onchangereceivedquantity(_GrnDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_GrnDetail.damagequantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_GrnDetail.damagequantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_GrnDetail.damagequantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangereceivedquantity(_GrnDetail,$event)"
                                                *ngIf="_GrnDetail.uomcode != 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (keyup.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (focusout)="onchangereceivedquantity(_GrnDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_GrnDetail.remainingquantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0">
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off"
                                                (onBlur)="onchangereceivedquantity(_GrnDetail,$event)"
                                                *ngIf="_GrnDetail.uomcode == 'MSC00039'&& _action != 'view' "
                                                (keyup)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (keyup.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (focusout)="onchangereceivedquantity(_GrnDetail,$event)" maxlength="13"
                                                (keypress)="keypress.kpNumber($event)"
                                                [(ngModel)]="_GrnDetail.remainingquantity" inputId="minmaxfraction"
                                                mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3">
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">{{_GrnDetail.remainingquantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_GrnDetail.remainingquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText name="remainingquantity" 
                                            (keyup)="onchangereceivedquantity(_GrnDetail,$event)" 
                                            (onBlur)="onchangereceivedquantity(_GrnDetail,$event)" 
                                            [(ngModel)]="_GrnDetail.remainingquantity" *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_GrnDetail.remainingquantity}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_GrnDetail.remainingquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td>
                                    {{_GrnDetail.uomname}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" pInputText name="invoiceunitprice"
                                                (keyup)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (Keydown.enter)="onchangereceivedquantity(_GrnDetail,$event)"
                                                (onBlur)="onchangereceivedquantity(_GrnDetail,$event)"
                                                [(ngModel)]="_GrnDetail.invoiceunitprice" *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_GrnDetail.invoiceunitrice}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_GrnDetail.invoiceunitprice}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="false"
                                                [disabled]="isDisabledtaxpercentage" [options]="_taxdetails"
                                                optionLabel="taxcode" optionValue="taxcode" [showClear]="true"
                                                [disabled]="!_GrnDetail.vatapplicable" [(ngModel)]="_GrnDetail.taxcode"
                                                (onChange)="onchangereceivedquantity(_GrnDetail,$event)"
                                                *ngIf="_action != 'view'">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown #id [autoDisplayFirst]="false" [options]="_taxdetails"
                                                optionLabel="taxcode" optionValue="taxcode"
                                                [(ngModel)]="_GrnDetail.taxcode">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td>
                                    {{_GrnDetail.taxamount | number: '1.2-4' }}
                                </td>
                                <td>
                                    {{_GrnDetail.productamount | number: '1.2-4' }}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [(ngModel)]="_GrnDetail.expirydate" [showIcon]="false"
                                                dateFormat="dd/mm/yy" *ngIf="_action != 'view'">
                                            </p-calendar>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_GrnDetail.expirydate | date:'dd/MM/yyyy'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td style="text-align: center">
                                    <p-checkbox [(ngModel)]="_GrnDetail.consignmentproduct"
                                        [disabled]="isDisabledconsignmentproduct" binary="true">
                                    </p-checkbox>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" pInputText name="remarks"
                                                [(ngModel)]="_GrnDetail.remarks" *ngIf="_action != 'view'">
                                            <div *ngIf="_action == 'view'">{{_GrnDetail.remarks }}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_GrnDetail.remarks}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td *ngIf="_action == 'view'" class="text-center">
                                    {{_GrnDetail.actualreceivequantity}}
                                </td>
                                <td class="text-center">
                                    <button pButton pRipple title="Update Stock" icon="las la-check"
                                        [disabled]="_GrnDetail.remainingquantity == 0 || _action != 'view' || _GrnDetail.grnquantity==0 ||  _GrnDetail.inwardtype !='STOCKTRANSFERORDER' || (_userid !=1 && _userid !=499 && _userid !=500) || !isGrnApproved  "
                                        (click)="stockupdate(_GrnDetail)">
                                    </button>
                                </td>
                                <td class="text-center" *ngIf="inwardtype == 'MSC00189'">
                                    <button pButton pRipple title="Price Update" icon="las la-check"
                                        [disabled]="_GrnDetail.ispriceupdate == true || roleid != 1 || _action !='view' "
                                        (click)="confirmpriceupdate(_GrnDetail)">
                                    </button>
                                </td>
                                <td class="text-center">
                                    <button pButton pRipple title="Update Receiver Stock" icon="las la-check"
                                        [disabled]="_GrnDetail.isreceiverstockupdate == true || _GrnDetail.remainingquantity == 0 || _GrnDetail.inwardtype !='STOCKTRANSFERORDER' || _action != 'view' || _GrnDetail.grnquantity==0 ||  (_userid !=1 && _userid !=499 && _userid !=500) || !isGrnApproved "
                                        (click)="confirmreceiverstockupdate(_GrnDetail)">
                                    </button>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Document Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <form [formGroup]="_grndocumentform">
                    <table>
                        <tr>
                            <td style="width:40%">
                                <table class="normal-table">
                                    <tr>
                                        <td style="width:50%;">
                                            <div class="p-inputgroup">
                                                <span class="p-float-label">
                                                    <div class="browse-links">
                                                        <p-button styleClass="p-button-link" label="Document"
                                                            title="Document"
                                                            (click)="showdocumentDialog(document,'bottom-right')"
                                                            tooltipPosition="top">
                                                        </p-button>
                                                    </div>
                                                </span>
                                                <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                    pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                                    (onSelect)="onDocumentSelect($event,document)"
                                                    accept="image/*,.pdf,.xlsx,.xls,.xlsm,.docx,.txt"
                                                    [disabled]="_action=='view'" [auto]="true">
                                                </p-fileUpload>
                                                <button class="p-inputgroup-addon p-button" pTooltip="Cancel"
                                                    type="button" (click)="onDocumentClear()" tooltipPosition="top"
                                                    [disabled]="_action=='view'">
                                                    <i class="pi pi-times"></i>
                                                </button>
                                            </div>
                                        </td>
                                        <td style="width:50%;">
                                            <span class="p-float-label">
                                                <p-dropdown [autoDisplayFirst]="false" [options]="_DocumentDescriptions"
                                                    optionValue="documentdescriptionname"
                                                    optionLabel="documentdescriptionname" formControlName="documentname"
                                                    [filter]="true" filterBy="documentdescriptionname">
                                                </p-dropdown>
                                                <label for="documentname">Document Name<span
                                                        class="hlt-txt">*</span></label>
                                            </span>
                                        </td>
                                    </tr>
                                    <tr>
                                        <td colspan="2">
                                            <button pButton pRipple label="Add" [disabled]="isDisabledadddocument"
                                                icon="las la-check" (click)="onAdddocument($event)"
                                                class="p-button-sm p-button-success"></button>
                                            <button pButton pRipple label="Clear" [disabled]="isDisabledcleardocument"
                                                icon="pi pi-times" (click)="onDocumentClear()"
                                                class="p-button-danger"></button>
                                        </td>
                                    </tr>
                                </table>
                            </td>
                            <td style="width:60%; vertical-align:top;">
                                <div class="card">
                                    <p-table #dt [value]="_grndocuments" [rows]="5" [paginator]="true"
                                        [rowsPerPageOptions]="[5,10,25,50,100]"
                                        [globalFilterFields]="['attachmenturl','documentname','documentdescription']"
                                        [rowHover]="true" dataKey="attachmenturl" [(selection)]="selectedrows"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                        [showCurrentPageReport]="true">
                                        <ng-template pTemplate="header">
                        <tr>
                            <th style="width: 100px;text-align: center;">Action</th>
                            <th>Attachment Name</th>
                            <th>Document Name</th>
                        </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_grndocument let-ri="rowIndex">
                            <tr>
                                <td style="text-align:center">
                                    <button pButton pRipple label="" title="Remove" icon="pi pi-trash"
                                        (click)="RemoveDocumentDetail(_grndocument)" class="p-button-danger p-mr-2">
                                    </button>
                                </td>
                                <td>
                                    <a href="javascript:void(0);"
                                        (click)="showdocumentGridDialog('bottom-right',_grndocument)">
                                        {{_grndocument.attachmentname}}
                                    </a>
                                </td>
                                <td>{{_grndocument.documentname}}</td>
                            </tr>
                        </ng-template>
                        </p-table>
            </div>
            </td>
            </tr>
            </table>
            </form>
            <div class="row">
                <div class="col-md-5">
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Other Charges</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <div class="card">
                        <p-table [value]="_OtherCharges" [rows]="10" [paginator]="true"
                            [rowsPerPageOptions]="[5,10,25,50,100]"
                            [globalFilterFields]="['otherchargesid','otherchargesamount']" [rowHover]="true"
                            dataKey="otherchargesid"
                            currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                            [showCurrentPageReport]="true" [(selection)]="selectedOtherChargesRows">
                            <ng-template pTemplate="header">
                                <tr>
                                    <th style="width:100px;text-align: center;">Action</th>
                                    <th>Description</th>
                                    <th>Value</th>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-_othercharge>
                                <tr>
                                    <td style="text-align:center">
                                        <button pButton pRipple title="Add" icon="pi pi-plus"
                                            class="p-button-success p-mr-2" [disabled]="isDisabledother"
                                            (click)="AddOtherCharges($event)"></button>
                                        <button pButton pRipple title="Remove" icon="pi pi-trash"
                                            class="p-button-danger" [disabled]="isDisabledremove"
                                            (click)="RemoveOtherCharges(_othercharge)"></button>
                                    </td>
                                    <td pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <p-dropdown #id [autoDisplayFirst]="false" [options]="_Descriptions"
                                                    *ngIf="_action != 'view'" optionLabel="otherchargename"
                                                    optionValue="otherchargeid" [(ngModel)]="_othercharge.otherchargeid"
                                                    (onChange)="OnchangeOtherCharge(_othercharge,$event)">
                                                </p-dropdown>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                <p-dropdown [disabled]="true" [autoDisplayFirst]="false"
                                                    [options]="_Descriptions" optionLabel="otherchargename"
                                                    optionValue="otherchargeid"
                                                    [(ngModel)]="_othercharge.otherchargeid">
                                                </p-dropdown>
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                    <td pEditableColumn>
                                        <p-cellEditor>
                                            <ng-template pTemplate="input">
                                                <input type="number" pInputText name="otherchargesamount"
                                                    (keyup)="OnChangeotherCharge(_othercharge,$event)"
                                                    [(ngModel)]="_othercharge.otherchargesamount"
                                                    *ngIf="_action != 'view'">
                                                <div *ngIf="_action == 'view'">{{_othercharge.otherchargesamount}}</div>
                                            </ng-template>
                                            <ng-template pTemplate="output">
                                                {{_othercharge.otherchargesamount}}
                                            </ng-template>
                                        </p-cellEditor>
                                    </td>
                                </tr>
                            </ng-template>
                        </p-table>
                    </div>
                </div>
                <div class="col-md-7">
                    <div class="sub-title">
                        <div class="row">
                            <div class="col-md-5">
                                <h5>Amount Details</h5>
                            </div>
                            <div class="col-md-7"></div>
                        </div>
                    </div>
                    <form [formGroup]="_grnform">
                        <table class="normal-table column-2">
                            <tr>
                                <td style="width: 25%;">
                                    <span class="p-float-label">
                                        <input type="number" name="grossamount" formControlName="grossamount" disabled
                                            pInputText>
                                        <label for="totalpoamount">Gross Amount</label>
                                    </span>
                                </td>
                                <td style="width: 25%;">
                                    <span class="p-float-label">
                                        <input type="number" name="taxamount" formControlName="taxamount" disabled
                                            pInputText>
                                        <label for="taxamount">Tax Amount</label>
                                    </span>
                                </td>
                                <td style="width: 25%;">
                                    <span class="p-float-label">
                                        <input type="number" name="otherchargesamount"
                                            formControlName="otherchargesamount" disabled pInputText>
                                        <label for="otherchargesamount">Other Amount</label>
                                    </span>
                                </td>
                                <td style="width: 25%;">
                                    <span class="p-float-label">
                                        <input type="number" formControlName="invoiceamount" name="invoiceamount"
                                            disabled pInputText>
                                        <label for="invoiceamount">Net Amount</label>
                                    </span>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
            </div>
            <p-dialog header="Document" [(visible)]="displaydocumentgridpopup" [position]="position"
                [style]="{width: '500px'}" [baseZIndex]="10000">
                <img [src]="documentimage" alt="" class="img-preview">
                <ng-template pTemplate="footer">
                    <p-button icon="pi pi-check" (click)="closePopupGridDialog()" label="Ok" styleClass="p-button-text">
                    </p-button>
                </ng-template>
            </p-dialog>
            <table>
                <td>
                    <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                </td>
            </table>
        </div>
        <p-dialog #warning [style]="{width: '500px'}" header="Confirm" [(visible)]="Stockmodel.showdialog"
            [modal]="true" [baseZIndex]="10000" [draggable]="false" [resizable]="false" [closable]="false">
            <ng-template pTemplate="content">
                <label [innerHTML]="Stockmodel.message"></label>
            </ng-template>
            <ng-template pTemplate="footer">
                <button type="button" pButton icon="las la-check" label="Yes" autofocus (click)="Yes()"></button>
                <button type="button" pButton icon="pi pi-times" label="No" autofocus (click)="Cancel()"></button>
            </ng-template>
        </p-dialog>
        <p-confirmDialog [style]="{width: '750px'}"></p-confirmDialog>
    </div>
</div>