import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { usererrors } from 'src/app/core/errors/usererrors';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { MessageService } from 'primeng/api';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Metadatum } from 'src/app/core/Models/metadatum';
import * as ace from "ace-builds";
import { PurchasereturnService } from 'src/app/core/Services/purchase/purchasereturn.service';
import { Purchasereturngetproductdetailsview } from 'src/app/core/Models/Purchasereturngetproductdetail';
import { vDateSelect, vInvoiceNoSelect, vPurchaseReturnReason } from 'src/app/core/Validators/validation';
import { Purchasereturnheaderview } from 'src/app/core/Models/Purchasereturn';
import { Purchasereturngetrefnoview } from 'src/app/core/Models/Purchasereturngetrefno';
import { Purchasereturndetailsview } from 'src/app/core/Models/Purchasereturndetails';
import { HotKeyIntegration } from 'src/assets/js/hot-key-integration';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Supplier } from 'src/app/core/Models/Supplier';
import { KeyPress } from 'src/assets/js/KeyPress';
@Component({
  selector: 'app-c-purchasereturn',
  templateUrl: './c-purchasereturn.component.html',
  styleUrls: ['./c-purchasereturn.component.css']
})
export class CPurchasereturnComponent implements OnInit {
  _purchasereturnform: FormGroup;
  _ProductNames: Productheader[];
  _Status: Metadatum;
  _purchasereturn: Purchasereturnheaderview;
  _purchasereturnrefno: Purchasereturngetrefnoview[];
  _temppurchasereturnrefno: Purchasereturngetrefnoview[];
  _suppliers: Supplier[];
  _purchasereturndetails: Purchasereturndetailsview[];
  _ProductDetails: Purchasereturngetproductdetailsview[];
  _ProductDetails1: Purchasereturngetproductdetailsview[];
  invoiceno: string;
  _OtherBalance: number;
  displayBasic: boolean;
  minDate: Date;
  selectedProductDetailsRows: any;
  _selectedlevel: number;
  selectedOtherChargesRows: any;
  _purchaseinvoices: string[];
  _Returnreason: string;
  _userid = sessionStorage["userid"];
  _branchid = sessionStorage["currentbranchid"];
  _action: string = "create";
  _purchasereturnObj: any;
  _purchasereturnid: number;
  _purchasereturnno: string;
  _submitted = false;
  _customersubmitted = false;
  isDisabledsave: boolean;
  isDisablededit: boolean;
  _IsProgressSpinner: boolean = true;
  _PurchaseReturnNo: string;
  isDisabledsendapparoval = true;
  _Action: IAction;
  _currencyList: any[];
  mindate: Date;
  maxdate: Date;
  _ReturnReasons: Metadatum[] = [];
  @ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private _router: Router,
    private utility: CommonUtilities,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _PurchaseReturnService: PurchasereturnService,
    private _hotkeysService: HotKeyIntegration<void>,
    private _AccessRightsService: AccessRightsService,
    public keypress: KeyPress
  ) {
    this._purchasereturnid = history.state?.purchasereturnid ? history.state?.purchasereturnid : 0;
    this._purchasereturnno = history.state?.purchasereturnno ? history.state?.purchasereturnno : 0;
    this._action = history.state.action == null ? 'create' : history.state.action;
    this._Action = this._AccessRightsService.getAccessRights(42);
    this._hotkeysService.Save(event => { if (!this.isDisabledsave) { this.onSave(); } }, this._Action).Clear(event => { this.reset(event); }).Navigate(event => {
      this.goBack(event);
    }, this._Action, 'left');
  }
  InitializeForm() {
    this._purchasereturnform = this.fb.group({
      companycode: [0],
      branchid: [],
      purchasereturnid: [0],
      purchasereturnno: [],
      purchasereturndate: ['', vDateSelect],
      invoiceno: ['', vInvoiceNoSelect],
      supplierid: [],
      suppliername: [],
      currencyid: [],
      currencyname: [],
      purchasereturnamount: [],
      transactionamount: [],
      taxamount: [],
      amountinusd: [],
      purchasereturnstatus: [],
      accountingyear: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      series: [],
      currencyreferenceid: [],
      isreplacementreturn: [false],
      returnreason: ['',vPurchaseReturnReason]
    });
    if (sessionStorage['Environmentenddate'] == "null") {
      this._purchasereturnform.controls['purchasereturndate'].setValue(new Date());
      this.maxdate = new Date();
    }
    else {
      this._purchasereturnform.controls['purchasereturndate'].setValue(new Date(sessionStorage['Environmentenddate']));
    }

  }
  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._PurchaseReturnService.PageOnLoad(this._userid, this._purchasereturnid, this._purchasereturnno, this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      // this._purchasereturnform.controls['purchasereturndate'].setValue(new Date());
      this._purchasereturnrefno = resultJSON.purchasereturngetrefnolist;
      this._Returnreason = resultJSON.returnReasonList;
      this._suppliers = resultJSON.suppliers;
      this._ReturnReasons = resultJSON.purchaseReturnReasons;
      this._IsProgressSpinner = false;
      if (this._action == 'edit' || this._action == 'view') {
        const updateJSON = JSON.parse(JSON.stringify(result));
        updateJSON.purchasereturnheaderviews[0].purchasereturndate = new Date(updateJSON.purchasereturnheaderviews[0].purchasereturndate);
        this._temppurchasereturnrefno = updateJSON.purchasereturngetrefnolist;
        this._purchasereturnform.setValue(updateJSON.purchasereturnheaderviews[0]);
        this._ProductDetails = updateJSON.purchasereturndetailsviews;
      }
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    if (this._action == 'view') {
      this._purchasereturnform.disable();
      this.isDisabledsave = true;
    }
    else {
      this.isDisabledsave = false;
      this.isDisablededit = false;
    }
    if (this._action == 'edit') {
      this.isDisablededit = true;
    }
  }
  OnInvoiceChange(event) {
    var invoiceno = event.value;
    this._IsProgressSpinner = true;

    this._PurchaseReturnService.GetInvoicedetails(invoiceno,this._branchid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
        this._ProductDetails = resultJSON.purchasereturngetproductdetailsviews;
        this._ProductDetails1 = resultJSON.purchasereturngetproductdetailsviews;
        this._currencyList = resultJSON.currencyreference;
        this._IsProgressSpinner = false;
        var referenceinfo = (this._purchasereturnrefno || []).filter(f => f.invoiceno == event.value);
        this._purchasereturnform.controls['supplierid'].setValue(referenceinfo[0].supplierid);
        this._purchasereturnform.controls['suppliername'].setValue(referenceinfo[0].suppliername);
        this._purchasereturnform.controls['currencyid'].setValue(referenceinfo[0].currencyid);
        this._purchasereturnform.controls['currencyname'].setValue(referenceinfo[0].currencyname);
        var currencyid = this._purchasereturnform.get("currencyid").value;
        var currencyrefernceid = this._currencyList.filter(f => f.currencyid == currencyid)[0].versionid;
        this._purchasereturnform.controls['currencyreferenceid'].setValue(currencyrefernceid);
      }
      else {
        this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
        this._IsProgressSpinner = false;
      }
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  OnSupplierChange(event) {
    this._IsProgressSpinner = true;
    var referenceinfo = (this._purchasereturnrefno || []).filter(f => f.supplierid == event.value);
    this._temppurchasereturnrefno = [];
    this._ProductDetails = [];
    this._temppurchasereturnrefno = referenceinfo;
    this._IsProgressSpinner = false;
  }
  allocatequantity(row: any, event) {
    var selectedindex = this._ProductDetails.findIndex(obj => obj == row);
    if (this._ProductDetails[selectedindex].returnquantity > this._ProductDetails[selectedindex].currentstock) {
      this._ProductDetails[selectedindex].returnquantity = 0
      this._CustomExceptionService.handleError("Return Quantity Should Not be Greater than the Current Stock");
      return
    }
    if (this._ProductDetails[selectedindex].quantity >= this._ProductDetails[selectedindex].returnquantity) {
      var taxPer = this._ProductDetails[selectedindex].taxpercentage / 100;
      this._ProductDetails[selectedindex].transactionamount = (row.returnquantity * this._ProductDetails[selectedindex].unitprice);
      this._ProductDetails[selectedindex].taxamount = taxPer * this._ProductDetails[selectedindex].transactionamount;
      this._ProductDetails[selectedindex].purchasereturnamount = this._ProductDetails[selectedindex].transactionamount;
      this._ProductDetails[selectedindex].productamount = this._ProductDetails[selectedindex].transactionamount;
      this._ProductDetails[selectedindex].referencecode = this._ProductDetails[selectedindex].invoiceno;
      this._ProductDetails[selectedindex].invoicequantity = this._ProductDetails[selectedindex].quantity;
      this._ProductDetails[selectedindex].finalamount = this._ProductDetails[selectedindex].purchasereturnamount + this._ProductDetails[selectedindex].taxamount;
    }
    else {
      this._ProductDetails[selectedindex].returnquantity = 0;
      this._CustomExceptionService.handleWarning("Return Quantity Should not greater than Invoice Quantity");
    }
    this.CalculateotherCharge();
  }
  CalculateotherCharge() {
    var grossamount = 0;
    var Taxamount = 0;
    var netamount = 0;
    let totalamount = 0;
    let amountinusd = 0
    for (var i = 0; i < this._ProductDetails.length; i++) {
      grossamount += (this._ProductDetails[i].transactionamount || 0);
      Taxamount += (this._ProductDetails[i].taxamount || 0);
      netamount += (this._ProductDetails[i].purchasereturnamount || 0);
    }
    this._purchasereturnform.controls['transactionamount'].setValue(grossamount.toFixed(3));
    this._purchasereturnform.controls['taxamount'].setValue(Taxamount.toFixed(3));
    this._purchasereturnform.controls['purchasereturnamount'].setValue((netamount + Taxamount).toFixed(3));
    totalamount = parseFloat(this._purchasereturnform.get("purchasereturnamount").value);
    var currencyid = this._purchasereturnform.get("currencyid").value;
    var selectedcurrencyexchangerate = this._currencyList.filter(f => f.currencyid == currencyid)[0].exchangerate;
    amountinusd = totalamount / selectedcurrencyexchangerate;
    this._purchasereturnform.controls['amountinusd'].setValue((amountinusd).toFixed(3));
  }
  onSave() {
    this._submitted = true;

    if (!this._purchasereturnform.valid)
    {
       return;
    }
    
    for (var i = 0; i < (this._ProductDetails || []).length; i++) {
      if (!this._ProductDetails[i].returnreason && this._ProductDetails[i].returnquantity > 0) {
        this._CustomExceptionService.handleWarning("Please select return reason");
        return;
      }
      if (this._ProductDetails[i].returnquantity > this._ProductDetails[i].invoicequantity) {
        this._CustomExceptionService.handleWarning("Return Quantity Should not greater than Invoice Quantity");
        return;
      }
    }
    let havignreturnqty = this._ProductDetails.some(s => s.returnquantity > 0);
    if (!havignreturnqty) {
      this._CustomExceptionService.handleWarning("Please enter return qty for at-least on product");
      return;
    }
    if (this._purchasereturnform.valid) {
      for (var i = 0; i < (this._ProductDetails || []).length; i++) {
        if (this._ProductDetails[i].returnreason && this._ProductDetails[i].returnquantity == 0) {
          this._ProductDetails.splice(i, 1);
        }
      }
      this.isDisabledsave = true;
      this._purchasereturn = this._purchasereturnform.getRawValue();
      this._purchasereturnObj = { "Purchasereturn": this._purchasereturn, "Purchasereturndetails": this._ProductDetails };
      if (this._action == 'create') {
        this._purchasereturn.createdby = sessionStorage["userid"];
        this._purchasereturn.createdon = new Date();
        this._purchasereturn.modifiedby = sessionStorage["userid"];
        this._purchasereturn.modifiedon = new Date();
        this._purchasereturn.branchid = sessionStorage["currentbranchid"];
        this._purchasereturn.accountingyear = new Date().getFullYear().toString();
        this._purchasereturn.purchasereturnstatus = "MSC00001";
        this._IsProgressSpinner = true;
        this._PurchaseReturnService.create(this._purchasereturnObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._PurchaseReturnNo = resultJSON.purchasereturnno;
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
            this._IsProgressSpinner = false;
            this.isDisabledsave = true;
            this.isDisabledsendapparoval = false;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this.isDisabledsave = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isDisabledsave = false; });
      }
      else if (this._action == 'edit') {
        this._purchasereturn.modifiedon = new Date();
        this._purchasereturn.modifiedby = sessionStorage["userid"];
        this._purchasereturnObj = { "Purchasereturn": this._purchasereturn, "Purchasereturndetails": this._ProductDetails };
        this._IsProgressSpinner = true;
        this._PurchaseReturnService.edit(this._purchasereturnObj).subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
            this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
            this.isDisabledsendapparoval = false;
            this.isDisabledsave = true;
            this._IsProgressSpinner = false;
          }
          else {
            this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message);
            this._IsProgressSpinner = false;
            this.isDisabledsave = false;
          }
          this._IsProgressSpinner = false;
        }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isDisabledsave = false; });
      }
    }
  }
  goBack(event) {
    this._router.navigate(['/vPurchasereturn']);
  }
  reset(event) {
    this._purchasereturnform.reset();
    this._ProductDetails = [];
    this._temppurchasereturnrefno = [];
    this.InitializeForm();
    this._submitted = false
  }
  SendToApproval(event) {
    this._IsProgressSpinner = true;
    this._PurchaseReturnService.SendToApproval(this._userid, this._branchid, this._PurchaseReturnNo).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this.isDisabledsave = false;
      this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
      this.reset(null);
      this._IsProgressSpinner = false;
      this.isDisabledsendapparoval = true;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  onEd() {
    ace.config.set("fontSize", "14px");
    ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode("ace/mode/json");
    var myObj = this._purchasereturnObj;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }
}