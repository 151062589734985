import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Customer } from 'src/app/core/Models/Customer';
import { RptsalessummaryService } from 'src/app/core/Services/reports/Sales/RptSalesSummary/rptsalessummary.service';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { RptsalesreturnService } from 'src/app/core/Services/reports/Sales/RptSalesReturn/rptsalesreturn.service';

@Component({
  selector: 'app-rptsalessummaryreport',
  templateUrl: './rptsalessummaryreport.component.html',
  styleUrls: ['./rptsalessummaryreport.component.css']
})
export class RptsalessummaryreportComponent implements OnInit {
  _IsProgressSpinner: boolean = true;
  _rptsalessummaryform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _customerlist: Customer[];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _currencylist: Currency[] = [];
  _SalesSummaryReportPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  currencyid: number[] = [];
  customerid: number[] = [];
  branchid: number[] = [];
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  _customertypelist: any[];
  _categorylist: any[];
  _subcategorylist: any[];
  _Rightslist: any[];
  _Series = sessionStorage["Series"];

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private _RptSalesSummaryService: RptsalessummaryService,
    private _CustomExceptionService: CustomExceptionService,
    private _RptSalesReturnService: RptsalesreturnService,
    private sanitizer: DomSanitizer,
    private _AccessRightsService: AccessRightsService
  ) { }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(132);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptsalessummaryform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [0],
      currencyid: [],
      customerid: [0],
      categoryid: [],
      subcategoryid: [],
      customertype: [],
      rights: [],
    })
    this._branchlist = parseInt(sessionStorage["BranchID"]);
    this._userid = parseInt(sessionStorage["userid"]);
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalessummaryform.get("fromdate").setValue(new Date);
      this._rptsalessummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalessummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalessummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptsalessummaryform.get("fromdate").value;
    dt.setDate(dt.getDate() - 1);
    this._rptsalessummaryform.controls['fromdate'].setValue(dt);
    this._rptsalessummaryform.controls['currencyid'].setValue(1);
    this._IsProgressSpinner = true;
    this._RptSalesSummaryService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branches;
      this._currencylist = resultJSON.currencies;
      this._customerlist = resultJSON.customers;
      this._customertypelist = resultJSON.customertypelist;
      this._Rightslist = resultJSON.rightslist;
      this.RightsChange();
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this._rptsalessummaryform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalessummaryform.get("fromdate").setValue(new Date);
      this._rptsalessummaryform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalessummaryform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalessummaryform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptsalessummaryform.get("fromdate").value;
    dt.setDate(dt.getDate() - 1);
    this._rptsalessummaryform.controls['fromdate'].setValue(dt);
    this._rptsalessummaryform.controls['currencyid'].setValue(1);
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.branchid = [];
    this.customerid = [];
    this._categorylist = [];
    this._subcategorylist = [];
    this._customerlist = [];
    this.RightsChange();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
  }
  GenerateReport(event) {
    var _fromdate = this._rptsalessummaryform.get("fromdate").value;
    var _todate = this._rptsalessummaryform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var customertype =
      this._rptsalessummaryform.get('customertype').value || null;
    var categoryid =
      this._rptsalessummaryform.get('categoryid').value || 0;
    var subcategoryid =
      this._rptsalessummaryform.get('subcategoryid').value || 0;
    var Currenctcurrenyid = this._rptsalessummaryform.get("currencyid").value;
    var Rights = this._rptsalessummaryform.get("rights").value;
    if (fromdate == null) {
      this._CustomExceptionService.handleError(usererrors.Rptfromdate);
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    if (fromdate > todate) {
      this._CustomExceptionService.handleError(usererrors.Rptfromtodate);
      return;
    }
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._SalesSummaryReportPrintObj = {
      "FromDate": fromdate,
      "ToDate": todate,
      "Branchid": this.branchid || parseInt(sessionStorage["BranchID"]) || [],
      "Customerid": this.customerid || [],
      "Userid": parseInt(sessionStorage["userid"]),
      "Branch": parseInt(sessionStorage["BranchID"]),
      "Currencyid": Currenctcurrenyid,
      "Customertype": customertype,
      "CustomerCategory": categoryid,
      "CustomerSubCategory": subcategoryid,
      "Rights": Rights
    }
    this._RptSalesSummaryService.Print(this._SalesSummaryReportPrintObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    }, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  onchangecustomertype(event) {
    var customertype = this._rptsalessummaryform.get('customertype').value || null;
    var categoryid = this._rptsalessummaryform.get('categoryid').value || 0;
    var subcategoryid = this._rptsalessummaryform.get('subcategoryid').value || 0;
    var rights = this._rptsalessummaryform.get("rights").value || null;
    this.customerid = [];
    
    let obj = {
      userid: this._userid,
      rights: rights,
      customertype: customertype,
      Categoryid: categoryid,
      SubCategoryid: subcategoryid,
    };
    this._IsProgressSpinner = true;
    this._RptSalesReturnService.GetCustomer(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._customerlist = resultJSON.customerList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetCategory(event) {
    this._categorylist = [];
    this._rptsalessummaryform.controls["categoryid"].reset();
    this._rptsalessummaryform.get('subcategoryid').reset();
    this._subcategorylist = [];
    this._customerlist = [];
    this.customerid = [];
    var customertype = event.value || 0;

    let obj = {
      userid: this._userid,
      customertype: customertype,
    };
    this._IsProgressSpinner = true;
    this._RptSalesReturnService.GetCategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._categorylist = resultJSON.categoryList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetSubcategory(event) {
    this._subcategorylist = [];
    this._rptsalessummaryform.get('subcategoryid').reset();
    this._customerlist = [];
    this.customerid = [];
    var customercategory = event.value || 0;

    let obj = {
      userid: this._userid,
      Customercategory: customercategory,
    };
    this._IsProgressSpinner = true;
    this._RptSalesReturnService.GetSubcategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._subcategorylist = resultJSON.subcategorylist;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  RightsChange() {
    if (this._Series == "MSC00093") {
      this._rptsalessummaryform.controls["rights"].setValue("MSC00093");
      this._rptsalessummaryform.get("rights").disable();
      this.onchangecustomertype(event);
    }
    else {
      this._rptsalessummaryform.controls["rights"].setValue("MSC00339");
      this.onchangecustomertype(event);
    }
  }
}