import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { DiscountreportService } from 'src/app/core/Services/reports/Sales/DiscountReport/discountreport.service';
import { Customerview } from 'src/app/core/Views/Customerview';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { RptsalesreturnService } from 'src/app/core/Services/reports/Sales/RptSalesReturn/rptsalesreturn.service';

@Component({
  selector: 'app-discountreport',
  templateUrl: './discountreport.component.html',
  styleUrls: ['./discountreport.component.css']
})
export class DiscountreportComponent implements OnInit {
  _discountreportform: FormGroup;
  _branchlist = sessionStorage["BranchID"];
  _userid: number;
  datepipe: any;
  _customerlist: Customerview[];
  _src: SafeResourceUrl;
  filteredProducts: any[];
  _currencylist: Currency[] = [];
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _SalesReportPrintObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _DiscountReportObj: any;
  _dailysalesreportform: FormGroup;
  _IsProgressSpinner: boolean = false;
  _Action: IAction;
  mindate: Date;
  maxdate: Date;
  customertypes: any[] = [];
  _categorylist: any[];
  _subcategorylist: any[];
  _Rightslist: any[];
  _Series = sessionStorage["Series"];
  _customertypelist: any[]
  _customers: any[];
  customerid: number;

  constructor(private utility: CommonUtilities, private fb: FormBuilder, private _AccessRightsService: AccessRightsService, private _RptSalesReturnService: RptsalesreturnService,
    private _router: Router, private _CustomExceptionService: CustomExceptionService, private sanitizer: DomSanitizer, private _DiscountReportService: DiscountreportService) {

  }
  InitializeDiscountReportForm() {
    this._discountreportform = this.fb.group({
      fromdate: [],
      todate: [],
      branchid: [],
      currencyid: [],
      customerid: [],
      rights: [],
      customertype: [],
      categoryid: [],
      subcategoryid: []
    });
    this._discountreportform.get("fromdate").setValue(new Date);
    this._discountreportform.get("todate").setValue(new Date);

    if (sessionStorage['Environmentenddate'] == "null") {
      this._discountreportform.get("fromdate").setValue(new Date);
      this._discountreportform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._discountreportform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._discountreportform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']));
    }
  }
  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(133);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeDiscountReportForm();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._userid = sessionStorage["userid"]
    this.RightsChange();
    this._IsProgressSpinner = true;
    this._DiscountReportService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._branchlist = resultJSON.branchList;
      this._customerlist = resultJSON.customerList;
      this._Rightslist = resultJSON.rightslist;
      this._customertypelist = resultJSON.customertypelist;
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }


  GenerateReport(event) {
    var _fromdate = this._discountreportform.get("fromdate").value;
    var _todate = this._discountreportform.get("todate").value;
    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var Rights = this._discountreportform.get("rights").value;
    var customertype = this._discountreportform.get("customertype").value;
    var subcategoryid = this._discountreportform.get("subcategoryid").value;
    var categoryid = this._discountreportform.get("categoryid").value;

    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    else if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("Effective From Date should be less than the Effective to Date");
      return;
    }
    var customerid = this._discountreportform.get("customerid").value || [];
    var branchid = this._discountreportform.get("branchid").value || [];

    this._IsProgressSpinner = true;
    var mediaType = 'application/pdf';
    this._DiscountReportObj = {
      "Branchid": branchid || parseInt(sessionStorage["currentbranchid"]),
      "Customerid": customerid || [],
      "FromDate": fromdate,
      "ToDate": todate,
      "Userid": parseInt(sessionStorage["userid"]),
      "Branch": parseInt(sessionStorage["currentbranchid"]),
      "customertype": customertype || "",
      "Subcategoryid": subcategoryid || 0,
      "Categoryid": categoryid || 0,
      "rights": Rights
    }
    this._DiscountReportService.Print(this._DiscountReportObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;

    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  clear(event) {
    this.InitializeDiscountReportForm();
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this._categorylist = [];
    this._subcategorylist = [];
    this._customerlist = [];
    this._categorylist = [];
    this._subcategorylist = [];
    this._discountreportform.get("customerid").reset();
    this.RightsChange();
  }

  onchangecustomertype(event) {
    var customertype = this._discountreportform.get('customertype').value || null;
    var categoryid = this._discountreportform.get('categoryid').value || 0;
    var subcategoryid = this._discountreportform.get('subcategoryid').value || 0;
    var rights = this._discountreportform.get("rights").value || null;
    this._discountreportform.get("customerid").reset();

    let obj = {
      userid: this._userid,
      rights: rights,
      customertype: customertype,
      Categoryid: categoryid,
      SubCategoryid: subcategoryid,
    };
    this._IsProgressSpinner = true;
    this._RptSalesReturnService.GetCustomer(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._customers = resultJSON.customerList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetCategory(event) {
    this._categorylist = [];
    this._discountreportform.controls["categoryid"].reset();
    this._discountreportform.get('subcategoryid').reset();
    this._subcategorylist = [];
    this._customerlist = [];
    this._discountreportform.get("customerid").reset();
    var customertype = event.value || 0;

    let obj = {
      userid: this._userid,
      customertype: customertype,
    };
    this._IsProgressSpinner = true;
    this._RptSalesReturnService.GetCategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._categorylist = resultJSON.categoryList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetSubcategory(event) {
    this._subcategorylist = [];
    this._discountreportform.get('subcategoryid').reset();
    this._customerlist = [];
    this._discountreportform.get("customerid").reset();
    var customercategory = event.value || 0;

    let obj = {
      userid: this._userid,
      Customercategory: customercategory,
    };
    this._IsProgressSpinner = true;
    this._RptSalesReturnService.GetSubcategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._subcategorylist = resultJSON.subcategorylist;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  RightsChange() {
    if (this._Series == "MSC00093") {
      this._discountreportform.controls["rights"].setValue("MSC00093");
      this._discountreportform.get("rights").disable();
      this.onchangecustomertype(event);
    }
    else {
      this._discountreportform.controls["rights"].setValue("MSC00339");
      this.onchangecustomertype(event);
    }
  }
}
