import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import autoTable from 'jspdf-autotable';
import * as moment from 'moment';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Level } from 'src/app/core/Models/Level';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { User } from 'src/app/core/Models/User';
import { RptbranchwisestockService } from 'src/app/core/Services/reports/Stock/RptBranchwiseStock/rptbranchwisestock.service';
import { PricecomparisonService } from 'src/app/core/Services/sales/pricecomparison.service';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Pricecomparisonview } from 'src/app/core/Views/Pricecomparisonview';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-v-pricecomparison',
  templateUrl: './v-pricecomparison.component.html',
  styleUrls: ['./v-pricecomparison.component.css']
})
export class VPricecomparisonComponent implements OnInit {
  selectedrows: any;
  pSelectableRow: any;
  _Add: Boolean;
  _Delete: Boolean;
  _Update: Boolean;
  _View: Boolean;
  _Action: IAction;
  _Pricecomparisonviews: any[] = [];
  _Pricecomparisonview: Pricecomparisonview;
  _IsProgressSpinner: boolean = true;
  _branchid = sessionStorage["currentbranchid"]
  _pricecomparisonform: FormGroup;
  _fromdate: Date;
  _todate: Date;
  mindate: Date;
  maxdate: Date;
  _producttypelist: Producttype[] = [];
	_productcategorylist: Productcategory[];
	_productlist: Productoverallview[] = [];
  _ProductTypeObj: any;
	_ProductCategoryObj: any;
	productcatid: number[] = [];
	producttypeid: number[] = [];
	productid: number[] = [];
	branchid: number[] = [];
  levelid: number[] = [];
  _Levellist: Level[] = [];
  _Createdby: User[] = [];
  _branchlist: Branch[] = [];
  _userslist: User[] = [];
  _ProductNames: Productoverallview[];
	_TempProductSearch: Productoverallview[];

  constructor(private fb: FormBuilder, private router: Router, private utility: CommonUtilities, 
    private _PricecomparisonService: PricecomparisonService, private _AccessRightsService: AccessRightsService, private _RptbranchwisestockService: RptbranchwisestockService,
    private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private exportUtility: ExportUtility) { }
  ngOnInit(): void {
    this._IsProgressSpinner = true;
    this._Action = this._AccessRightsService.getAccessRights(60);
    this.utility.pageLoadScript();
    this._pricecomparisonform = this.fb.group({
      fromdate: [''],
      todate: [''],
      // branchid: [0],
      // productid: [0],
      // productcategoryid: [0],
      // producttypeid: [0],
      userid: [],
    })
    this.HotKeyIntegration();
    this.OnChangeDate();
    this.SearchComparision();
    this._IsProgressSpinner = false;
    // this._PricecomparisonService.FetchAll().subscribe((result) => {
    //   const resultJSON = JSON.parse(JSON.stringify(result));
    //   this._Pricecomparisonviews = resultJSON.pricecomparisonviews;
    //   this._producttypelist = resultJSON.producttypelist;
    //   this._Levellist = resultJSON.levels;
    //   this._Createdby = resultJSON.users;
    //   this._userslist = resultJSON.userlist;

    //   this._IsProgressSpinner = false;
    // },
    //   error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  view(event) {
    this.router.navigate(['/cPricecomparison'], { state: { pricecomparisonid: event.pricecomparisonid, action: 'view' } });
  }
  delete(event) {
    alert("delete");
  }
  edit(event) {
    this.router.navigate(['/cPricecomparison'], { state: { pricecomparisonid: event.pricecomparisonid, action: 'edit' } });
  }
  GoToCreate(event) {
    this.router.navigate(['/cPricecomparison']);
  }
  //View
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._Action._Add) {
      this._hotkeysService.add(new Hotkey('ctrl+right', (event: KeyboardEvent): boolean => {
        this.GoToCreate(event);
        return false; // Prevent bubbling
      }, ['INPUT', 'SELECT', 'TEXTAREA']));
    }
  }
  exportExcel() {
    let finalizedList = this.GetFinalizedList();
    this.exportUtility.exportExcel(finalizedList, 'Price Comparision', 'xlsx');
  }

  ExportToPdf() {
    let finalizedList = this.GetFinalizedList();
    const data = finalizedList.map(Object.values);
    const head = [[
      'Product',
      'Level',
      'Bhola Price',
      'Competitor Name 1',
      'Competitor 1 Price',
      'Competitor Name 2',
      'Competitor 2 Price',
      'Competitor Name 3',
      'Competitor 3 Price',
      'Competitor Name 4',
      'Competitor 4 Price',
    ]]
    import("jspdf").then(jsPDF => {
      import("jspdf-autotable").then(x => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0
          },
          bodyStyles: {
            textColor: 0
          },
          didDrawCell: (data) => { },
        });
        doc.save('QuoteFinalize.pdf');
      })
    })
  }

  GetFinalizedList() {
    let finalizedList = [];
    this._Pricecomparisonviews.forEach(element => {
      let quotes: any = {};
      quotes["Product"] = element.productname;
      quotes["Level"] = element.levelname;
      quotes["Bhola Price"] = element.bholaprice;
      if (element.c1name) {
        quotes["Competitor Name 1"] = element.c1name;
      } else {
        quotes["Competitor Name 1"] = "";
      }
      if (element.c1unitprice) {
        quotes["Competitor 1 Price"] = element.c1unitprice;
      } else {
        quotes["Competitor 1 Price"] = "";
      }
      if (element.c2name) {
        quotes["Competitor Name 2"] = element.c2name;
      } else {
        quotes["Competitor Name 2"] = "";
      }
      if (element.c2unitprice) {
        quotes["Competitor 2 Price"] = element.c2unitprice;
      } else {
        quotes["Competitor 2 Price"] = "";
      }
      if (element.c3name) {
        quotes["Competitor Name 3"] = element.c3name;
      } else {
        quotes["Competitor Name 3"] = "";
      }
      if (element.c3unitprice) {
        quotes["Competitor 3 Price"] = element.c3unitprice;
      } else {
        quotes["Competitor 3 Price"] = "";
      }
      if (element.c4name) {
        quotes["Competitor Name 4"] = element.c4name;
      } else {
        quotes["Competitor Name 4"] = "";
      }
      if (element.c4unitprice) {
        quotes["Competitor 4 Price"] = element.c4unitprice;
      } else {
        quotes["Competitor 4 Price"] = "";
      }
      finalizedList.push(quotes);
    });
    return finalizedList;
  }

  Print(_Pricecomparisonview) {
		this._IsProgressSpinner = true;
		let Data = {
			"Pricecomparisionid": _Pricecomparisonview.pricecomparisonid,
			"Branchid": this._branchid,
		}
		this._PricecomparisonService.Print(Data).subscribe((result: any) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			let url: any = URL.createObjectURL(result);
			window.open(url, '_blank', 'location=yes,height=570,width=1000,scrollbars=yes,status=yes');
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}

  OnChangeDate() {
    var fromdate = moment(this._pricecomparisonform.get("fromdate").value, "YYYY-MM-DD"); //Previous used query
    var todate = moment(this._pricecomparisonform.get("todate").value, "YYYY-MM-DD");   //Previous used query
    var caldays = todate.diff(fromdate, 'days');
    const startOfMonth = moment().startOf('month').format("YYYY-MM-DD");
    const endOfMonth = moment().endOf('month').format("YYYY-MM-DD");

    if(caldays < 0)
    {
      this._CustomExceptionService.handleError("To date is always greater than from date")
    }
    // if (caldays > 0) {
    //   this.branchselectionlimit = caldays;
    //   this.branchid = [];
    //   this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    //   if (this._userid == 1 && caldays <= 31) {
    //     this.branchselectionlimit = 0;
    //     this.branchid = [];
    //     this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    //   }
    // } else {
    //   this.branchselectionlimit = 0;
    //   this.branchid = [];
    //   this.branchid.push(parseInt(sessionStorage["currentbranchid"]));
    // }
  }

  getProductCategory(event) {
		var selectedProductType = event.value;
		this.productcatid = [];
		this.productid = [];
		this._productlist = [];
		if (selectedProductType.length == 0 || selectedProductType == null) {
			this._productcategorylist = [];
			this._pricecomparisonform.controls["productcategoryid"].reset();
			return;
		}
		else {
			this._IsProgressSpinner = true;
			this._ProductTypeObj = {
				"Producttype": selectedProductType || [],
				"branchid": parseInt(sessionStorage["currentbranchid"])
			};
			this._RptbranchwisestockService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productcategorylist = resultJSON.productcategories;
				this._IsProgressSpinner = false;
			}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
		}
	}

  getProduct(event) {
		var selectedProductCategory = event.value;
		this.productid = [];
		var branchid = parseInt(sessionStorage["currentbranchid"]);
		if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
			this._productlist = [];
			return;
		} else {
			this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": branchid || parseInt(sessionStorage["currentbranchid"]) };
			this._RptbranchwisestockService.getProduct(this._ProductCategoryObj).subscribe((result) => {
				const resultJSON = JSON.parse(JSON.stringify(result));
				this._productlist = resultJSON.productoverallList;
			});
		}
	}

  SearchComparision()
  {
    var obj = 
    {
      "Producttype": this.producttypeid || [],
      "Productcategory": this.productcatid || [],
      "Product": this.productid || [],
      "Markuplevel": this.levelid || [],
      "Createdby": this._pricecomparisonform.get("userid").value || 0,
    }

    this._PricecomparisonService.Fetchdata(obj).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
      this._Pricecomparisonviews = resultJSON.pricecomparisonviews;
      this._producttypelist = resultJSON.producttypelist;
      this._Levellist = resultJSON.levels;
      this._Createdby = resultJSON.users;
      this._userslist = resultJSON.userlist;
    },
    error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }

  Clear()
  {
    this.producttypeid = [];
    this.productcatid = [];
    this.productid = [];
    this.levelid = [];
    this._pricecomparisonform.get("userid").reset();
    this._productcategorylist = [];
    this._productlist = []
  }

  // getProduct(event) {
	// 	var selectedProductCategory = event.value;
	// 	this._ProductNames = this._TempProductSearch;
	// 	if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
	// 		this.productid = [];
	// 		return;
	// 	} else {
	// 		this._IsProgressSpinner = true;
	// 		this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": this._branchid || 0 };
	// 		this._PricecomparisonService.GetProductName(this._ProductCategoryObj).subscribe((result) => {
	// 			const resultJSON = JSON.parse(JSON.stringify(result));
	// 			this._ProductNames = <Productoverallview[]>resultJSON.productsearch || [];
	// 			this._TempProductSearch = <Productoverallview[]>resultJSON.productsearch || [];
	// 			this._IsProgressSpinner = false;
	// 		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	// 	}
	// }
}
