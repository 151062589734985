import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
@Injectable({
	providedIn: 'root'
})
export class PurchasereturnService {
	public _Purchasereturnservice = environment.PurchasereturnService;
	public _httpOptions;
	token: any;
	header: any;

	constructor(private http: HttpClient) {
		this.token = sessionStorage['AuthToken'];
		this.header = this.createHeaders(this.token)

	}
	createHeaders(token: any): HttpHeaders {
		return new HttpHeaders({
			'Content-Type': 'application/json',
			Authorization: `Bearer ${token}`, // Include your token here	
		});
	}
	FetchAll(userid: number, branchid: number, roleid: number, fromdate: string, todate: string) {
		const headers = this.header;
		var Query = this._Purchasereturnservice + '/FetchAll' + '/' + userid + '/' + branchid + '/' + roleid + '/' + fromdate + '/' + todate;
		return this.http.get<{ data: any }>(Query, { headers }).pipe(map(data => {
			const resultJSON = JSON.parse(JSON.stringify(data));
			return data;
		}));
	}
	PageOnLoad(p_userid: number, p_purchasereturnid: number, p_purchasereturnno: string, branchid: number): Observable<any> {
		const headers = this.header;
		var Query = this._Purchasereturnservice + '/' + p_userid + '/' + p_purchasereturnid + '/' + p_purchasereturnno + '/' + branchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	GetInvoicedetails(p_invoiceno: string, branchid: number) {
		const headers = this.header;
		var Query = this._Purchasereturnservice + '/getTransactionDetails' + '/' + p_invoiceno + '/' + branchid;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	create(p_purchasereturndetails: object) {
		const headers = this.header;
		var Query = this._Purchasereturnservice;
		return this.http.post<{ data: any }>(Query, p_purchasereturndetails, { headers });
	}
	edit(p_purchasereturndetails: object) {
		const headers = this.header;
		var Query = this._Purchasereturnservice;
		return this.http.put<{ data: any }>(Query, p_purchasereturndetails, { headers });
	}
	SendToApproval(userid: number, branchid: number, purchasereturnno: string) {
		const headers = this.header;
		var Query = this._Purchasereturnservice + '/SendToApproval' + '/' + userid + '/' + branchid + '/' + purchasereturnno;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	Cancel(userid: number, purchasereturnno: string) {
		const headers = this.header;
		var Query = this._Purchasereturnservice + '/Cancel' + '/' + userid + '/' + purchasereturnno;
		return this.http.get<{ data: any }>(Query, { headers });
	}
	Print(obj: any) {
		const headers = this.header;
		var Query = this._Purchasereturnservice + '/Print';
		return this.http.post(Query, obj, { responseType: 'blob', headers });
	}
}