<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Sales Discount Report</h3>
                    </div>

                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_discountreportform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar name="fromdate" formControlName="fromdate" [showIcon]="true"
                                            dateFormat="dd/mm/yy" [monthNavigator]="true" [yearNavigator]="true"
                                            [minDate]="mindate" [maxDate]="maxdate" yearRange="1950:2050">
                                        </p-calendar>
                                        <label for="fromdate"> From Date <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar name="todate" formControlName="todate" [showIcon]="true"
                                            dateFormat="dd/mm/yy" [monthNavigator]="true" [minDate]="mindate" [maxDate]="maxdate"
                                            [yearNavigator]="true" yearRange="1950:2050">
                                        </p-calendar>
                                        <label for="todate">To Date <span class="hlt-txt">*</span></label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Rightslist" optionLabel="metasubdescription"
                                            optionValue="metasubcode" formControlName="rights" [filter]="true" (onChange)="onchangecustomertype($event)"
                                            filterBy="metasubdescription">
                                        </p-dropdown>
                                        <label for="userid">Rights</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_branchlist" optionValue="branchid"
                                            optionLabel="branchname" formControlName="branchid"
                                            [virtualScroll]="true" itemSize="30">
                                            <ng-template let-account pTemplate="item">
                                                <div
                                                    [style]="account.branchstatuscode == 'MSC00002' ? 'background-color: #f1f734;' : ''">
                                                    {{account.branchname}}</div>
                                            </ng-template>
                                        </p-multiSelect>
                                        <label for="branchname">Branch Name</label>
                                    </span>
                                </td>
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_customertypelist"
                                            optionValue="metasubcode" optionLabel="metasubdescription"
                                            (onChange)="onchangecustomertype($event);GetCategory($event)" formControlName="customertype"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Customer Type</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_categorylist"
                                            optionValue="categoryid" optionLabel="categoryname" (onChange)="onchangecustomertype($event);GetSubcategory($event)"
                                            formControlName="categoryid"
                                            [filter]="true" filterBy="categoryname">
                                        </p-dropdown>
                                        <label for="categoryname">Customer Category</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_subcategorylist"
                                            optionValue="subcategoryid" optionLabel="subcategoryname" (onChange)="onchangecustomertype($event)"
                                            formControlName="subcategoryid"
                                            [filter]="true" filterBy="subcategoryname">
                                        </p-dropdown>
                                        <label for="subcategoryname">Customer SubCategory</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [options]="_customerlist" optionLabel="customername"
                                            optionValue="customerid" formControlName = "customerid" [filter]="true"
                                            [virtualScroll]="true" itemSize="30" filterBy="customername">
                                        </p-multiSelect>
                                        <label for="customername">Customer Name</label>
                                    </span>
                                </td>
                                <td style="width: 20%;"></td>
                            </tr>

                            <tr>
                                <td>
                                    <button pButton pRipple label="Generate" icon="las la-print" (click)="GenerateReport($event)" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear($event)" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="output-container">
                    <iframe #iframe class="scroll-height" width="100%" [src]="_src"></iframe>
                </div>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>

        </div>
    </div>

</div>
<p-toast position="bottom-right"></p-toast>