import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { StockinwardService } from 'src/app/core/Services/inventory/stockinward.service';
import { Currency } from 'src/app/core/Models/Currency';
import { Stockinwardgetinvoicedetail } from 'src/app/core/Models/Stockinwardinvoicedetail';
import { Stockinwardgetsupllierorbranchview } from 'src/app/core/Models/Stockinwardgetsupllierorbranch';
import { Stockinwardgetrequestnoview } from 'src/app/core/Models/Stockinwardgetreferenceno';
import * as ace from 'ace-builds';
import {
  vSelectInwardDate,
  vSelectInwardType,
  vBranchSupplierSelect,
} from 'src/app/core/Validators/validation';
import { Stockinwardview } from 'src/app/core/Views/Stockinwardview';
import { Stockinwardgetproductdetailview } from 'src/app/core/Models/Stockinwardgetproductdetail';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Stockdelivery } from 'src/app/core/Models/Stockdelivery';
import { KeyPress } from 'src/assets/js/KeyPress';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';

declare var $: any;
@Component({
  selector: 'app-c-stockinward',
  templateUrl: './c-stockinward.component.html',
  styleUrls: ['./c-stockinward.component.css'],
})
export class CStockinwardComponent implements OnInit {
  // Form Group Declaration:
  _stockinwardform: FormGroup;
  _stockinwardproductdetailform: FormGroup;

  //List Declaration:
  _stockinward: Stockinwardview;
  _stockinwardlist: Stockinwardview[] = [];
  _stockinwardproductdetail: Stockinwardgetproductdetailview;
  _stockinwardinvoicedetail: Stockinwardgetinvoicedetail;
  _stockinwardproductdetails: Stockinwardgetproductdetailview[] = [];
  _tempstockinwardproductdetails: Stockinwardgetproductdetailview[] = [];
  _stockinwardinvoicedetails: Stockinwardgetinvoicedetail[] = [];
  _stockinwardgetsupplierorbranch: Stockinwardgetsupllierorbranchview[] = [];
  _stockinwardgetrequestno: Stockinwardgetrequestnoview[];
  _stockDelivery: Stockdelivery[];
  _stockinwardbarcode: Stockinwardgetrequestnoview[] = [];
  _barcodedeliveryno: Stockinwardgetrequestnoview[] = [];
  _referencenumber: any[];
  _inwardtype: Metadatum[];
  _uomdetails: Metadatum[];
  _currency: Currency[];
  _inwardstatus: Metadatum[];
  //Local Variable Declaration:
  _inwardid: number;
  _inwardtypes: string;
  _action: string = 'create';
  _submitted = false;
  currencyid: number;
  _userid = sessionStorage['userid'];
  _branchid = sessionStorage['currentbranchid'];

  _stockinwardOBJ: any;
  isDisabledSave = false;
  isDisabledClear = false;
  isDisabledadd = false;
  isDisabledinvoice = false;

  isConsignment = false;
  isConsignmentproduct = true;
  isDisabledremove = false;
  _IsProgressSpinner: boolean = true;
  hidepercentage: boolean = true;
  hide: boolean = true;
  Isbarcodeenable: boolean = true;
  selectedProductDetailsRows: Stockinwardgetproductdetailview[] = [];
  _Action: IAction;
  productbarcode: string = '';
  mindate: Date;
  maxdate: Date;
  Inwardbarcode: any;
  isbarcode: boolean = false;
  Isoverallbarcode: boolean = false;
  isloaded: boolean = false;
  event: any = {
    value: [],
  };
  barcodeinwartype: string = '';
  barcodevalue: string = '';
  _tempstockinwardgetrequestno: Stockinwardgetrequestnoview[] = [];
  isDisabledbarcode: boolean = false;

  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    public keypress: KeyPress,
    private _StockinwardService: StockinwardService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _hotkeysService: HotkeysService,
    private _AccessRightsService: AccessRightsService
  ) {
    this._inwardid = history.state?.inwardid ? history.state?.inwardid : 0;
    this._inwardtypes = history.state?.inwardtype
      ? history.state?.inwardtype
      : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    // this.HotKeyIntegration();
  }
  InitializeStockinwardForm() {
    // StockInward Form Creation:
    this._stockinwardform = this.fb.group({
      companyid: [0],
      branchid: [],
      inwardid: [0],
      inwardno: [],
      inwarddate: ['', vSelectInwardDate],
      inwardtype: ['', vSelectInwardType],
      referenceno: [],
      supplieridorbranchid: ['', vBranchSupplierSelect],
      vehicleno: [],
      drivername: [],
      blnumber: [],
      containernumber: [],
      status: [],
      createdby: [],
      createdon: [new Date()],
      modifiedby: [],
      modifiedon: [],
      accountingyear: [],
      timeStamp: [],
      purchaseinwarddetails: [],
      purchaseinwardinvoicedetails: [],
      eveidenceno: [],
      deliveryno: [],
      isbarcode: [],
      transactionincompleted: [1],
      isrpoductbarcode:[0],
    });
    // this._stockinwardform.controls['inwarddate'].setValue(new Date());
    if (sessionStorage['Environmentenddate'] == 'null') {
      this._stockinwardform.get('inwarddate').setValue(new Date());
      this.maxdate = new Date();
    } else {
      this._stockinwardform
        .get('inwarddate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
    }
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(48);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this.InitializeStockinwardForm();
    this._IsProgressSpinner = true;
    this.hidepercentage = true;
    this.hide = false;
    this._StockinwardService
      .PageOnload(this._inwardid, this._inwardtypes)
      .subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          this._currency = resultJSON.currencies;
          this._inwardtype = resultJSON.inwardTypes;
          this._uomdetails = resultJSON.uomCodes;
          this._IsProgressSpinner = false;
          // this._stockinwardform.controls['inwarddate'].setValue(new Date());
          if (this._action == 'create') {
            var l_stockinwardinvoicedetail = new Stockinwardgetinvoicedetail();
            l_stockinwardinvoicedetail.invoicedate = new Date();
            l_stockinwardinvoicedetail.invoiceamount = 0;
            l_stockinwardinvoicedetail.invoiceno = '';
            l_stockinwardinvoicedetail.currencyid = 1;
            l_stockinwardinvoicedetail.createdby = this._userid;
            l_stockinwardinvoicedetail.modifiedby = this._userid;
            l_stockinwardinvoicedetail.modifiedon = new Date();
            l_stockinwardinvoicedetail.createdon = new Date();
            this._stockinwardinvoicedetails.push(l_stockinwardinvoicedetail);
          }
          if (this._action == 'view') {
            this._stockinwardform.disable();
            this.isDisabledSave = true;
            this.isDisabledClear = true;
            this.isDisabledadd = true;
            this.isDisabledremove = true;
            this.isDisabledinvoice = true;
          }
          if (this._action == 'edit') {
            this.isDisabledSave = false;
            this.isDisabledClear = true;
            this.isDisabledinvoice = true;
            this.isDisabledadd = false;
            this.isDisabledremove = false;
          }
          if (this._action == 'edit' || this._action == 'view') {
            const updateJSON = JSON.parse(JSON.stringify(result));
            this._currency = updateJSON.currencies;
            this._inwardtype = updateJSON.inwardTypes;
            this._uomdetails = updateJSON.uomCodes;
            updateJSON.purchaseinward.inwarddate = new Date(
              updateJSON.purchaseinward.inwarddate
            );
            this._stockinwardform.setValue(updateJSON.purchaseinward);
            var inwardtype = this._stockinwardform.get('inwardtype').value;
            this._stockinwardproductdetails =
              updateJSON.stockinwardgetproductdetailviews;
            this.selectedProductDetailsRows =
              updateJSON.stockinwardgetproductdetailviews;
            this._stockinwardinvoicedetails =
              updateJSON.purchaseinwardinvoicedetails;
            for (var i = 0; i < this._stockinwardinvoicedetails.length; i++) {
              this._stockinwardinvoicedetails[i].invoicedate = new Date(
                this._stockinwardinvoicedetails[i].invoicedate
              );
            }
            this._stockinwardgetsupplierorbranch =
              updateJSON.stockinwardgetsupplierorbranchviews;
            this._stockinwardgetrequestno =
              updateJSON.stockinwardgetrequestnoviews;
            this._stockDelivery = updateJSON.stockinwardgetdeliverynoviews;
            if (inwardtype == 'MSC00277' || inwardtype == 'MSC00278') {
              this.isConsignment = true;
              this.isConsignmentproduct = false;
            } else {
              this.isConsignment = false;
              this.isConsignmentproduct = true;
            }
            if (
              inwardtype == 'MSC00189' ||
              inwardtype == 'MSC00225' ||
              inwardtype == 'MSC00190'
            ) {
              var referenceno = updateJSON.purchaseinward.referenceno;
              this._referencenumber = (
                this._stockinwardgetrequestno || []
              ).filter((f) => f.purchaseorderno == referenceno);
              this._stockinwardform.controls['referenceno'].setValue(
                this._referencenumber[0].purchaseorderno
              );
            }
            //this.getProductDetails(event);
            if (this._stockinwardinvoicedetails.length <= 0) {
              var l_stockinwardinvoicedetail =
                new Stockinwardgetinvoicedetail();
              l_stockinwardinvoicedetail.invoicedate = new Date();
              l_stockinwardinvoicedetail.invoiceamount = 0;
              l_stockinwardinvoicedetail.invoiceno = '';
              l_stockinwardinvoicedetail.currencyid = 1;
              (l_stockinwardinvoicedetail.createdby = this._userid),
                (l_stockinwardinvoicedetail.modifiedby = this._userid),
                (l_stockinwardinvoicedetail.modifiedon = new Date()),
                (l_stockinwardinvoicedetail.createdon = new Date()),
                this._stockinwardinvoicedetails.push(
                  l_stockinwardinvoicedetail
                );
            }
          }
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
  }
  //Get Supplier or Branch:
  getSupplierNameorBranchName(event) {
    var inwardtype = event.value || this.barcodeinwartype;
    this.Inwardbarcode = ""
    if(this.isbarcode == true)
    {
      this._stockinwardform.controls["inwardtype"].disable();
    } 
    //console.log(inwardtype);
    this._IsProgressSpinner = true;
    if (this.barcodeinwartype == '') {
      this._stockinwardform.controls['supplieridorbranchid'].reset();
    }

    this._stockinwardform.controls['referenceno'].reset();
    this._stockinwardform.controls['deliveryno'].reset();
    var supplierid = 
    this._stockinwardform.get('supplieridorbranchid').value || 0;
    this._referencenumber = [];
    for (var i = 0; i < this._stockinwardinvoicedetails.length; i++) {
      this._stockinwardinvoicedetails[i].invoiceamount = 0;
      this._stockinwardinvoicedetails[i].invoicedate = new Date();
      this._stockinwardinvoicedetails[i].invoiceno = '';
    }
    if (inwardtype == 'MSC00277' || inwardtype == 'MSC00278') {
      // this.Inwardbarcode.disable();
      this.isConsignment = true;
      this.isConsignmentproduct = false;
      this.isDisabledbarcode = true;
    } else {
      this.isConsignment = false;
      this.isConsignmentproduct = true;
      this.isDisabledbarcode = false;
    }
    if (inwardtype == 'MSC00278') {
      this.hidepercentage = false;
      this.hide = true;
      this.isDisabledbarcode = true;
    } else {
      this.hidepercentage = true;
      this.hide = false;
      this.isDisabledbarcode = false;
      if(inwardtype == 'MSC00277')
      {
        this.isDisabledbarcode = true;
      }
    }
    if(this.isbarcode == true)
    {
      this._stockinwardform.controls["isrpoductbarcode"].setValue(1);
    }
    this._StockinwardService
      .getSupplierNameorBranchName(
        inwardtype,
        this._branchid,
        supplierid,
        this._userid
      )
      .subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          //console.log(resultJSON);
          this._stockinwardgetsupplierorbranch =
            resultJSON.stockinwardgetsupplierorbranchviews;
          this._stockinwardgetrequestno =
            resultJSON.stockinwardgetrequestnoviews;
          this._tempstockinwardgetrequestno =
            resultJSON.stockinwardgetrequestnoviews;
          this._stockinwardproductdetails = [];
          if (this.isbarcode == true) {
            if(inwardtype == "MSC00277" || inwardtype == "MSC00278")
            {
              this._stockinwardform.controls['supplieridorbranchid'].reset();
              this._CustomExceptionService.handleWarning("!Invalid type");
              this._IsProgressSpinner = false;
            }
            else
            {
            this._referencenumber = this._tempstockinwardgetrequestno.filter(
              (f) => f.referenceno == this.barcodevalue
            );
            if(this._referencenumber.length == 0)
            {
              this._CustomExceptionService.handleError('Invalid Barcode');
              // window.location.reload();
              this._IsProgressSpinner = false;
              this._stockinwardform.controls["supplieridorbranchid"].reset();
            }
            else
            {
              this._stockinwardgetrequestno =this._tempstockinwardgetrequestno.filter((f) => f.referenceno == this.barcodevalue);
            let supplierid: number = parseInt(
              this._stockinwardgetrequestno[0].supplierid as unknown as string,10);
              

              this._stockinwardform.get('supplieridorbranchid').setValue(supplierid);
              // this._stockinwardform.controls["isproductbarcode"].setValue(true);

            if(this._stockinwardgetrequestno.length == 0 || this._referencenumber.length == 0)
            {
              this._CustomExceptionService.handleError('Invalid Barcode');
              // window.location.reload();
              this._IsProgressSpinner = false;
              this.Inwardbarcode = "";
              this._stockinwardform.controls["supplieridorbranchid"].reset();
              this._stockinwardform.controls["referenceno"].reset();
            }
            else
            {
              this._stockinwardform.get('referenceno').setValue(this._stockinwardgetrequestno[0].purchaseorderno);
              this._stockinwardform.controls["supplieridorbranchid"].disable();
              this._stockinwardform.controls["referenceno"].disable();
              this.getProductDetails(event);
            }
            }         
          } 
          }
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
  }

  barcodebased() {
    var supplier = this._stockinwardform.get('supplieridorbranchid').value;
    if (!supplier) return;
    var barcode = this._stockinwardform.get('isbarcode').value;
    if (barcode == true) {
      this.Isbarcodeenable = false;
      this.isConsignmentproduct = true;
    } else {
      this.Isbarcodeenable = true;
      this.isConsignmentproduct = false;
    }
    this.getReferenceNo();

    $("input[name='productbarcode']").focus();
  }

  FetchProductUsingBarcode() {
    setTimeout(() => {
      var productbarcodeno = <string>this.productbarcode;
      if (!productbarcodeno) {
        this.productbarcode = '';
        return;
      }
      //this.event.value = [];
      productbarcodeno = productbarcodeno.trim();
      this.productbarcode = '';

      var inwardtype = this._stockinwardform.get('inwardtype').value;
      var Deliveryno = this._stockinwardform.get('deliveryno').value || '0';
      var supplierid = this._stockinwardform.get('supplieridorbranchid').value;
      var Currentbranchid = this._branchid;
      var stocktransferno =
        this._stockinwardform.get('referenceno').value || '0';

      this._IsProgressSpinner = true;
      this._StockinwardService
        .getProductDetails(
          inwardtype,
          stocktransferno,
          supplierid,
          Deliveryno,
          Currentbranchid,
          productbarcodeno
        )
        .subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            //console.log(resultJSON);
            if (resultJSON.stockinwardgetproductdetailviews.length == 0) {
              this._CustomExceptionService.handleWarning(
                'No products mapped for this supplier'
              );
            }
            var lists = resultJSON.stockinwardgetproductdetailviews;
            for (var i = 0; i < lists.length; i++) {
              var Details = new Stockinwardgetproductdetailview();
              Details.purchaseinwarddetailid = lists[i].purchaseinwarddetailid;
              Details.purchaseinvoiceid = lists[i].purchaseinvoiceid;
              Details.purchaseorderno = lists[i].purchaseorderno;
              Details.productid = lists[i].productid;
              Details.productname = lists[i].productname;
              Details.variantid = lists[i].variantid;
              Details.variantdescription = lists[i].variantdescription;
              Details.quantity = lists[i].quantity;
              Details.orderqunatity = lists[i].orderqunatity;
              Details.receivedquantity = lists[i].receivedquantity;
              Details.invoicequantity = lists[i].invoicequantity;
              Details.shortagequantity = lists[i].shortagequantity;
              Details.uomcode = lists[i].uomcode;
              Details.uomname = lists[i].uomname;
              Details.lotno = lists[i].lotno;
              Details.requesttype = lists[i].requesttype;
              Details.createdby = lists[i].createdby;
              Details.createdon = lists[i].createdon;
              Details.modifiedby = lists[i].modifiedby;
              Details.modifiedon = lists[i].modifiedon;
              Details.eveidenceno = lists[i].eveidenceno;
              Details.purchaseordertype = lists[i].purchaseordertypes;
              let listcount1 = this._stockinwardproductdetails.filter(
                (f) => f.productid == lists[i].productid
              );
              if (listcount1.length > 0) {
                this._CustomExceptionService.handleWarning(
                  'This Product Is Already Exist'
                );
                this._IsProgressSpinner = false;
                return;
              }

              this._stockinwardproductdetails.push(Details);
              this.selectedProductDetailsRows.push(Details);
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
    }, 400);
  }
  onRowSelect(event) {
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  onRowUnselect(event) {
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  SelectAll(event) {
    //console.log(event.data);
    //console.log(this.selectedProductDetailsRows);
  }
  // Get ReferenceNo:
  getReferenceNo() {
    this._stockinwardproductdetails = [] = [];
    this.selectedProductDetailsRows = [] = [];
    var inwardtype = this._stockinwardform.get('inwardtype').value;
    var Deliveryno = this._stockinwardform.get('deliveryno').value || '0';
    var supplierid = this._stockinwardform.get('supplieridorbranchid').value;
    var isbarcodes = this._stockinwardform.get('isbarcode').value;
    var Currentbranchid = this._branchid;
    var stocktransferno = this._stockinwardform.get('referenceno').value || '0';
    var productbarcodeno: string = '0';
    if (inwardtype == 'MSC00277' || inwardtype == 'MSC00278') {
      if (
        inwardtype == 'MSC00277' ||
        (inwardtype == 'MSC00278' && isbarcodes != true)
      ) {
        this._IsProgressSpinner = true;
        this._StockinwardService
          .getProductDetails(
            inwardtype,
            stocktransferno,
            supplierid,
            Deliveryno,
            Currentbranchid,
            productbarcodeno
          )
          .subscribe(
            (result) => {
              const resultJSON = JSON.parse(JSON.stringify(result));
              //console.log(resultJSON);
              if (resultJSON.stockinwardgetproductdetailviews.length == 0) {
                this._CustomExceptionService.handleWarning(
                  'No products mapped for this supplier'
                );
              }
              this._stockinwardproductdetails =
                resultJSON.stockinwardgetproductdetailviews;
              this._IsProgressSpinner = false;
            },
            (error) => {
              this._CustomExceptionService.handleError(error);
              this._IsProgressSpinner = false;
            }
          );
      }
    } else {
      this._stockinwardproductdetails = [];
      this._stockinwardform.controls['referenceno'].reset();
      var supplierid = this._stockinwardform.get('supplieridorbranchid').value;
      this._referencenumber = (this._stockinwardgetrequestno || []).filter(
        (f) => f.supplierid == supplierid
      );
      if (this.isbarcode == true) {
        let refno = this._referencenumber[0].purchaseorderno;
        this._stockinwardform.controls['referenceno'].setValue(refno);
        this.getProductDetails(this.event);
      }
    }
  }

  // getProductDeliveryNo(event) {
  //   //var branchorsupplier = event.value
  //   var supplierid = this._branchid
  //   var referenceno = event.value;
  //   var inwardtype = this._stockinwardform.get("inwardtype").value;
  //   //console.log(referenceno);
  //   this._IsProgressSpinner = true;
  //   this._StockinwardService.getProductDetails(inwardtype, referenceno, supplierid).subscribe((result) => {
  //     const resultJSON = JSON.parse(JSON.stringify(result));
  //     //console.log(resultJSON);
  //     this._stockinwardproductdetails = resultJSON.stockinwardgetproductdetailviews;
  //     this._IsProgressSpinner = false;
  //   },
  //     error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  // }

  //Get ReferenceNo:
  getProductDetails(event) {
    //var branchorsupplier = event.value
    var Currentbranchid = this._branchid;
    // var referenceno = event.value;
    var inwardtype = this._stockinwardform.get('inwardtype').value;
    var Deliveryno = this._stockinwardform.get('deliveryno').value || '0';
    var supplierid = this._stockinwardform.get('supplieridorbranchid').value;
    var referenceno = this._stockinwardform.get('referenceno').value || '0';
    var productbarcodeno: string = '0';
    var BarcodeDeliveryno: string = '0';

    this._stockinwardproductdetails = [];

    if (inwardtype == 'MSC00189') {
      //PO
      if (!referenceno || referenceno == '0') return;
      //console.log(referenceno);
      this._IsProgressSpinner = true;
      this._StockinwardService
        .getProductDetails(
          inwardtype,
          referenceno,
          supplierid,
          Deliveryno,
          Currentbranchid,
          productbarcodeno
        )
        .subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            //console.log(resultJSON);
            this._stockinwardproductdetails = resultJSON.stockinwardgetproductdetailviews;
            for (var i = 0; i < this._stockinwardproductdetails.length; i++) {
              this._stockinwardproductdetails[i].invoicequantity = this._stockinwardproductdetails[i].receivedquantity;
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
    } else if (inwardtype == 'MSC00190' || inwardtype == 'MSC00225') {
      //STO AND STOCK ALLOCATION
      //console.log(referenceno);
      this._IsProgressSpinner = true;
      this._StockinwardService
        .getDeliveryno(referenceno, supplierid, Currentbranchid)
        .subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            //console.log(resultJSON);
            this._stockDelivery = resultJSON.stockinwardgetrequestnoviews;

            this._barcodedeliveryno = resultJSON.stockinwardgetrequestnoviews;
            if (this.isbarcode == true && this.isloaded == false) {
              let deliveryno = this._barcodedeliveryno[0].purchaseorderno;
              this._stockinwardform.get('deliveryno').setValue(deliveryno);
              BarcodeDeliveryno = this._stockinwardform.get('deliveryno').value;
              Deliveryno = BarcodeDeliveryno;
              this.isloaded = true;
              this.getProductDetails(event);
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
    }

    if (Deliveryno != '0') {
      this._IsProgressSpinner = true;
      this._StockinwardService
        .getProductDetails(
          inwardtype,
          referenceno,
          supplierid,
          Deliveryno,
          Currentbranchid,
          productbarcodeno
        )
        .subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            //console.log(resultJSON);
            this._stockinwardproductdetails =
              resultJSON.stockinwardgetproductdetailviews;
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
    }
  }
  onSave() {
    this._submitted = true;
    var inwardtype = this._stockinwardform.get('inwardtype').value;
    var ReferenceNo = this._stockinwardform.get('referenceno').value;
    if (inwardtype == 'MSC00277' || inwardtype == 'MSC00278') {
      if (
        !this.selectedProductDetailsRows ||
        this.selectedProductDetailsRows.length == 0
      ) {
        this._CustomExceptionService.handleError(
          'Please add at least one row in Product details'
        );
        return;
      }

      for (let i = 0; i < this.selectedProductDetailsRows.length; i++) {
        const x = this.selectedProductDetailsRows[i];

        if (x.receivedquantity != x.invoicequantity) {
          this._CustomExceptionService.handleWarning(
            'Invoice Qty & Received Qty Should be Equal.'
          );
          return;
        }

        // Add additional check for quantity values
        if (!x.receivedquantity || !x.invoicequantity) {
          this._CustomExceptionService.handleError(
            'Please enter values for Quantity.'
          );
          return;
        }
      }
    }
    if (
      inwardtype == 'MSC00189' &&
      ReferenceNo != '' &&
      this._stockinwardproductdetails.length > 0
    ) {
      for (var i = 0; i < this._stockinwardinvoicedetails.length; i++) {
        if (
          this._stockinwardinvoicedetails[i].invoiceno == '' ||
          this._stockinwardinvoicedetails[i].invoiceno == null ||
          this._stockinwardinvoicedetails[i].invoiceno == undefined
        ) {
          this._CustomExceptionService.handleError(
            'Please Enter Invoice Number'
          );
          return;
        }
      }
    }
    if (
      (inwardtype == 'MSC00189' ||
        inwardtype == 'MSC00225' ||
        inwardtype == 'MSC00190') &&
      !ReferenceNo
    ) {
      this._CustomExceptionService.handleError('Please select Reference no');
      return;
    }
    let isSatisfied = this._stockinwardproductdetails.some(
      (s) => s.receivedquantity > 0 && s.invoicequantity > 0
    );
    let isSatisfy = this._stockinwardproductdetails.some(
      (s) => s.receivedquantity > 0
    );
    if (
      !isSatisfied &&
      this._stockinwardform.get('inwardtype').value == 'MSC00189'
    ) {
      this._CustomExceptionService.handleWarning(
        'Must specify received quantity and invoice quantity in any of the row.'
      );
      return;
    } else if (
      !isSatisfy &&
      this._stockinwardform.get('inwardtype').value != 'MSC00189'
    ) {
      this._CustomExceptionService.handleWarning(
        'Must specify received quantity.'
      );
      return;
    }
    for (var i = 0; i < this._stockinwardproductdetails.length; i++) {
      this._stockinwardproductdetails[i].shortagequantity =
        this._stockinwardproductdetails[i].orderqunatity -
        this._stockinwardproductdetails[i].receivedquantity;
    }
    if (this._stockinwardform.valid) {
      var InwardDate = this._stockinwardform.get('inwarddate').value;
      var datePipe = new DatePipe('en-US');
      var pInwardDate = datePipe.transform(InwardDate, 'yyyy-MM-dd');
      this._stockinward = this._stockinwardform.getRawValue();
      this._stockinward.inwarddate = new Date(pInwardDate);
      (this._stockinward.branchid = this._branchid),
        (this._stockinward.createdby = this._userid),
        (this._stockinward.modifiedby = this._userid),
        (this._stockinward.modifiedon = new Date()),
        (this._stockinward.accountingyear = new Date().getFullYear());
      this._stockinward.status = 'MSC00001';
      if (inwardtype == 'MSC00277' || inwardtype == 'MSC00278') {
        this._stockinward.referenceno = '';
        this._stockinwardOBJ = {
          Purchaseinward: this._stockinward,
          Purchaseinwardinvoicedetail: this._stockinwardinvoicedetails,
          Purchaseinwarddetail: this.selectedProductDetailsRows,
        };
      } else {
        this._stockinwardOBJ = {
          Purchaseinward: this._stockinward,
          Purchaseinwardinvoicedetail: this._stockinwardinvoicedetails,
          Purchaseinwarddetail: this._stockinwardproductdetails,
        };
      }
      if (this._action == 'create') {
        this._IsProgressSpinner = true;
        this._StockinwardService.create(this._stockinwardOBJ).subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            if (
              resultJSON.tranStatus.result == true &&
              (resultJSON != null || resultJSON != undefined)
            ) {
              this._CustomExceptionService.handleSuccess(
                usererrors.Saved_Success_00
              );
              this._IsProgressSpinner = false;
              this.reset(null);
              // this.ngOnInit();
              window.location.reload();
            } else {
              this._CustomExceptionService.handleError(
                resultJSON.tranStatus.lstErrorItem[0].message
              );
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      } else if (this._action == 'edit') {
        this._IsProgressSpinner = true;
        this._StockinwardService.edit(this._stockinwardOBJ).subscribe(
          (result) => {
            const resultJSON = JSON.parse(JSON.stringify(result));
            if (
              resultJSON.tranStatus.result == true &&
              (resultJSON != null || resultJSON != undefined)
            ) {
              this._CustomExceptionService.handleSuccess(
                usererrors.Update_Success_04
              );
              this._IsProgressSpinner = false;
              this.isDisabledSave = true;
            } else {
              this._CustomExceptionService.handleError(
                resultJSON.tranStatus.lstErrorItem[0].message
              );
              this._IsProgressSpinner = false;
            }
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      }
      this.isDisabledSave = false;
    }
  }
  reset(event) {
    this._submitted = true;
    this._stockinwardform.reset();
    this._stockinwardinvoicedetails = [];
    this._stockinwardproductdetails = [];
    this.selectedProductDetailsRows = [];
    this.InitializeStockinwardForm();
    var l_stockinwardinvoicedetail = new Stockinwardgetinvoicedetail();
    l_stockinwardinvoicedetail.invoicedate = new Date();
    l_stockinwardinvoicedetail.invoiceamount = 0;
    l_stockinwardinvoicedetail.invoiceno = '';
    l_stockinwardinvoicedetail.createdby = this._userid;
    l_stockinwardinvoicedetail.currencyid = 1;
    (l_stockinwardinvoicedetail.modifiedby = this._userid),
      (l_stockinwardinvoicedetail.modifiedon = new Date()),
      (l_stockinwardinvoicedetail.createdon = new Date()),
      this._stockinwardinvoicedetails.push(l_stockinwardinvoicedetail);
    this._submitted = false;
  }
  goBack(event) {
    this._router.navigate(['/vStockinward']);
  }
  AddInvoiceDetails($event) {
    var l_stockinwardinvoicedetail = new Stockinwardgetinvoicedetail();
    l_stockinwardinvoicedetail.invoicedate = new Date();
    l_stockinwardinvoicedetail.invoiceamount = 0;
    l_stockinwardinvoicedetail.invoiceno = '';
    l_stockinwardinvoicedetail.currencyid = 1;
    (l_stockinwardinvoicedetail.createdby = this._userid),
      (l_stockinwardinvoicedetail.modifiedby = this._userid),
      (l_stockinwardinvoicedetail.modifiedon = new Date()),
      (l_stockinwardinvoicedetail.createdon = new Date()),
      this._stockinwardinvoicedetails.push(l_stockinwardinvoicedetail);
  }
  //Remove Invoice Event call:
  RemoveInvoiceDetails(
    p_stockinwardinvoicedetail: Stockinwardgetinvoicedetail
  ) {
    var index = this._stockinwardinvoicedetails.indexOf(
      p_stockinwardinvoicedetail
    );
    this._stockinwardinvoicedetails.splice(index, 1);
    if (this._stockinwardinvoicedetails.length <= 0) {
      var l_stockinwardinvoicedetail = new Stockinwardgetinvoicedetail();
      l_stockinwardinvoicedetail.invoicedate = new Date();
      l_stockinwardinvoicedetail.invoiceamount = 0;
      l_stockinwardinvoicedetail.invoiceno = '';
      l_stockinwardinvoicedetail.currencyid = 1;
      (l_stockinwardinvoicedetail.createdby = this._userid),
        (l_stockinwardinvoicedetail.modifiedby = this._userid),
        (l_stockinwardinvoicedetail.modifiedon = new Date()),
        (l_stockinwardinvoicedetail.createdon = new Date()),
        this._stockinwardinvoicedetails.push(l_stockinwardinvoicedetail);
    }
  }

  onEd() {
    alert('test');
    ace.config.set('fontSize', '14px');
    ace.config.set(
      'basePath',
      'https://unpkg.com/ace-builds@1.4.12/src-noconflict'
    );
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode('ace/mode/json');
    var myObj = this._stockinwardOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  // Create
  // HotKeyIntegration() {
  //   this._hotkeysService.reset();
  //   if (this._action != 'view') {
  //     this._hotkeysService.add(new Hotkey('ctrl+s', (event: KeyboardEvent): boolean => {
  //       this.onSave();
  //       return false; // Prevent bubbling
  //     }, ['INPUT', 'SELECT', 'TEXTAREA']));
  //   }
  //   if (this._action == 'create') {
  //     this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
  //       this.reset(null);
  //       return false; // Prevent bubbling
  //     }, ['INPUT', 'SELECT', 'TEXTAREA']));
  //   }
  //   this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
  //     this.goBack(null);
  //     return false; // Prevent bubbling
  //   }, ['INPUT', 'SELECT', 'TEXTAREA']));
  // }
  onchangeinvoicequantity(row: Stockinwardgetproductdetailview) {
    var inwardtype = this._stockinwardform.get('inwardtype').value;

    if (
      inwardtype == 'MSC00190' ||
      inwardtype == 'MSC00225' ||
      inwardtype == 'MSC00189'
    ) {
      //STO & STOCK ALLOCATION
      if (row.purchaseordertype != 'MSC00202') {
        if (row.orderqunatity < row.receivedquantity) {
          this._CustomExceptionService.handleWarning(
            'Received quantity should not greater than delivery qty'
          );
          row.receivedquantity = 0;
          return;
        }
        if (row.orderqunatity < row.invoicequantity) {
          this._CustomExceptionService.handleWarning(
            'Invoice quantity should not greater than delivery qty'
          );
          row.invoicequantity = 0;
          return;
        }
        if (row.lotno > '0') {
          if (row.invoicequantity < 0) {
            this._CustomExceptionService.handleWarning(
              'Invoice quantity must be positive'
            );
            row.invoicequantity = 0;
            return;
          }
        }
      }
    }
    if (row.invoicequantity != row.receivedquantity) {
      this._CustomExceptionService.handleWarning(
        'Received quantity and Invoice quantity should be equal'
      );
      row.receivedquantity = 0;
      return;
    }
  }

  FetchBarcode() {
    let type = this._stockinwardform.get('inwardtype').value;
    try {
      if (!type) {
        this._CustomExceptionService.handleWarning('Please Select Inward Type');
        return;
      }
      //setTimeout(() => {
      let barcodevalue = <string>this.Inwardbarcode;
      if (!barcodevalue) {
        this.Inwardbarcode = '';
        // this.Isoverallbarcode = false;
        return;
      }
      barcodevalue = barcodevalue.trim();
      this.isbarcode = true;
      if (barcodevalue) {
        this.barcodeinwartype = type;
        this.barcodevalue = barcodevalue;
        this.isloaded = false;
        this.getSupplierNameorBranchName(event);
      }
      this.Inwardbarcode = ""
    } catch {}
  }

  onKeydown(event: KeyboardEvent) {
    if (event.key == 'Backspace' && this.Inwardbarcode == '') 
    {
      this._stockinwardform.controls["supplieridorbranchid"].reset();
      this._stockinwardform.controls["referenceno"].reset();
      this._stockinwardform.controls["deliveryno"].reset();
    }
  }

  PreventMinus(event: KeyboardEvent) {
		if (event.key === '-' || event.keyCode === 189) {
			event.preventDefault();
		}
	}
}
