import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import autoTable from 'jspdf-autotable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Customer } from 'src/app/core/Models/Customer';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Subcategory } from 'src/app/core/Models/Subcategory';
import { RptlaybuyService } from 'src/app/core/Services/reports/Sales/RptLaybuy/rptlaybuy.service';
import { RptsalesService } from 'src/app/core/Services/reports/Sales/Rptsales/rptsales.service';
import { RptminusstockService } from 'src/app/core/Services/reports/Stock/Rptminusstock/rpt-minusstock.service';
import { Customerview } from 'src/app/core/Views/Customerview';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { ExportUtility } from 'src/assets/js/export-utility';

@Component({
  selector: 'app-laybuyreport',
  templateUrl: './laybuyreport.component.html',
  styleUrls: ['./laybuyreport.component.css'],
})
export class LaybuyreportComponent implements OnInit {
  _IsProgressSpinner: boolean = false;
  _POoutstandingform: FormGroup;
  _invoicetype: any;
  mindate: Date;
  maxdate: Date;
  _branchlist: any[] = [];
  branchid: any[] = [];
  currencyid: any[] = [];
  _customers: any[] = [];
  _laybuystslist: any[] = [];
  customerid: any[] = [];
  brandid: any[] = [];
  _producttypeid: any[] = [];
  _Laybuyreports: any[] = [];
  _Laybuydetails: any[] = [];
  _productwise: boolean = false;
  _laybuywise: boolean = false;
  customertype: boolean = false;
  _ProductWisepending: any[] = [];
  _producttypelist: Producttype[] = [];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  _Brands: any[] = [];
  _Currencies: any[] = [];
  _ProductTypeObj: any;
  _ProductObj: any;
  _brandlist: any;
  filteredProducts: any[];
  _ProductCategoryObj: any;
  _LaybuyStatus: any[] = [];
  laybuystatusid: number;
  laybuystatus: any;
  _customertypelist: any[] = [];
  _userid = parseInt(sessionStorage['userid']);
  _customerlist: any[];
  _categorylist: any[];
  _subcategorylist: Subcategory[];
  customertypeid: string;
  _Rightslist: any[];
  _Series= sessionStorage["Series"];

  constructor(
    private utility: CommonUtilities,
    private fb: FormBuilder,
    private exportUtility: ExportUtility,
    private _RptlaybuyService: RptlaybuyService,
    private _RptminusstockService: RptminusstockService,
    private _router: Router,
    private _CustomExceptionService: CustomExceptionService,
    private _RptsalesService: RptsalesService
  ) {}

  InitializeForm() {
    this._POoutstandingform = this.fb.group({
      fromdate: [],
      todate: [],
      customertype: [],
      categoryid: [],
      subcategoryid: [],
      rights: []
      // laybuystatusid: [],
    });
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    if (sessionStorage['Environmentenddate'] == 'null') {
      this._POoutstandingform.get('fromdate').setValue(new Date());
      this._POoutstandingform.get('todate').setValue(new Date());
      this.maxdate = new Date();
    } else {
      this._POoutstandingform
        .get('fromdate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
      this._POoutstandingform
        .get('todate')
        .setValue(new Date(sessionStorage['Environmentenddate']));
    }
  }

  ngOnInit(): void {
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    this._laybuystslist = [
      // { laybuystatusid: 0, laybuystatusdesc: 'All' },
      { laybuystatusid: 6, laybuystatusdesc: 'ALL' },
      { laybuystatusid: 1, laybuystatusdesc: 'Lay Buy Created' },
      { laybuystatusid: 2, laybuystatusdesc: 'Partially Paid' },
      { laybuystatusid: 3, laybuystatusdesc: 'Delivered' },
      { laybuystatusid: 4, laybuystatusdesc: 'Invoice Created' },
      { laybuystatusid: 5, laybuystatusdesc: 'Fully Paid' },
    ];
    // this.laybuystatus = 0;
    this.RightsChange();
    this._RptlaybuyService
      .PageOnLoad({ userid: sessionStorage['userid'] })
      .subscribe(
        (result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          // this._customers = resultJSON.customerList;
          this._branchlist = resultJSON.branchList;
          this._customertypelist = resultJSON.customertypelist;
          this._Rightslist = resultJSON.rightslist
          this._categorylist = [];
          this._subcategorylist = [];
          //this._laybuystslist = resultJSON.laybuyHeadersList;
          this._IsProgressSpinner = false;
        },
        (error) => {
          this._CustomExceptionService.handleError(error);
          this._IsProgressSpinner = false;
        }
      );
  }

  GenerateReport() {
    var _fromdate = this._POoutstandingform.get('fromdate').value;
    var _todate = this._POoutstandingform.get('todate').value;
    var datePipe = new DatePipe('en-US');
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    var rights = this._POoutstandingform.get("rights").value || null;
    var customertype =
      this._POoutstandingform.get('customertype').value || null;
    var customercategory = this._POoutstandingform.get('categoryid').value || 0;
    var customersubcategory =
      this._POoutstandingform.get('subcategoryid').value;
    if (fromdate == null) {
      this._CustomExceptionService.handleError('Please Select From Date');
      return;
    } else if (todate == null) {
      this._CustomExceptionService.handleError('Please Select To Date');
      return;
    } else if (fromdate > todate) {
      this._CustomExceptionService.handleError(
        'From Date should be less than To Date'
      );
      return;
    }
    let data = {
      Effectivefrom: fromdate,
      Effectiveto: todate,
      Branchid: this.branchid || [],
      customertypeid: customertype,
      Customerid: this.customerid || [],
      // "Userid": sessionStorage["userid"],
      Laybuystatusid: this.laybuystatus || 6,
      Categoryid: customercategory || 0,
      Subcategoryid: customersubcategory || 0,
      rights: rights,

      // "Branchid": this.branchid || [],
    };

    this._IsProgressSpinner = true;
    this._RptlaybuyService.GetLaybuysummary(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._Laybuyreports = resultJSON.laybuysummarylists;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  clear() {
    this._POoutstandingform.controls['fromdate'].setValue(new Date());
    this._POoutstandingform.controls['todate'].setValue(new Date());
    this.branchid = [];
    this.laybuystatus = 6;
    this._POoutstandingform.controls['categoryid'].reset();
    this._POoutstandingform.controls['subcategoryid'].reset();
    this._POoutstandingform.controls['customertype'].reset();
    this._categorylist = [];
    this._Laybuyreports = [];
    this._subcategorylist = [];
    this._customerlist = [];
    this.customerid = [];
    this.RightsChange();
  }

  exportExcel() {
    let branchwisestocklists = this.GetCustomerList();
    this.exportUtility.exportExcel(
      branchwisestocklists,
      'Laybuy_Report',
      'xlsx'
    );
  }

  ExportToPdf() {
    let branchwisestocklists = this.GetCustomerList();
    const data = branchwisestocklists.map(Object.values);
    const head = [
      [
        'Laybuy No',
        'Sales Invoice No',
        'Start Date',
        'End Date',
        'Customer Name',
        'Sales Invoice Date',
        'Currrency',
        'Total Amount',
        'Paid Amount',
        'Balance Amount',
        'Laybuy Status',
      ],
    ];
    import('jspdf').then((jsPDF) => {
      import('jspdf-autotable').then((x) => {
        const doc = new jsPDF.default('l', 'mm', 'a4');
        autoTable(doc, {
          head: head,
          body: data,
          tableLineColor: [0, 0, 0],
          tableLineWidth: 0,
          styles: {
            lineColor: [0, 0, 0],
            lineWidth: 0.1,
          },
          headStyles: {
            fillColor: [211, 211, 211],
            textColor: 0,
          },
          bodyStyles: {
            textColor: 0,
          },
          didDrawCell: (data) => {},
        });
        doc.save('Laybuy_Report.pdf');
      });
    });
  }

  GetCustomerList() {
    let laybuylist = [];
    this._Laybuyreports.forEach((element) => {
      let branchwisestock: any = {};
      branchwisestock['Laybuy No'] = element.LAYBUYCODE;
      branchwisestock['Sales Invoice No'] = element.SALESINVOICENO;
      branchwisestock['Start Date'] = element.STARTDATE;
      branchwisestock['End Date'] = element.ENDDATE;
      branchwisestock['Customer Name'] = element.CUSTOMERNAME;
      branchwisestock['Sales Invoice Date'] = element.SALESINVOICEDATE;
      branchwisestock['Currrency'] = element.CURRENCYNAME;
      branchwisestock['Total Amount'] = element.TOTALAMOUNT || 0;
      branchwisestock['Paid Amount'] = element.PAIDAMOUNT || 0;
      branchwisestock['Balance Amount'] = element.BALANCEAMOUNT || 0;
      branchwisestock['Laybuy Status'] = element.LAYBUYSTATUS;
      laybuylist.push(branchwisestock);
    });
    return laybuylist;
  }

  GetLaybuyDetails(_Laybuyreport: any) {
    this._IsProgressSpinner = true;
    let data = {
      laybuyno: _Laybuyreport.LAYBUYCODE,
    };

    this._RptlaybuyService.GetLaybuyDetails(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log(resultJSON);
        this._laybuywise = true;

        this._Laybuydetails = resultJSON.laybuydetaillists;

        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  onchangecustomertype() {
    var customertype = this._POoutstandingform.get('customertype').value || null;
    var categoryid = this._POoutstandingform.get('categoryid').value || 0;
    var subcategoryid = this._POoutstandingform.get('subcategoryid').value || 0;
    var rights = this._POoutstandingform.get("rights").value || 0;
    this.customerid = [];

    let data = {
      userid: this._userid,
      rights: rights,
      customertype: customertype,
      Categoryid: categoryid,
      SubCategoryid: subcategoryid,
    };
    this._IsProgressSpinner = true;
    this._RptlaybuyService.GetCustomer(data).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._customerlist = resultJSON.customerList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetCategory(event) {
    this._categorylist = [];
    this._POoutstandingform.controls["categoryid"].reset();
    this._POoutstandingform.get('subcategoryid').reset();
    this._subcategorylist = [];
    this._customerlist = [];
    this.customerid = [];
    var customertype = event.value || 0;

    let obj = {
      userid: this._userid,
      customertype: customertype,
    };
    this._IsProgressSpinner = true;
    this._RptlaybuyService.GetCategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._categorylist = resultJSON.categoryList;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  GetSubcategory(event) {
    this._subcategorylist = [];
    this._POoutstandingform.get('subcategoryid').reset();
    this._customerlist = [];
    this.customerid = [];
    var customercategory = event.value || 0;

    let obj = {
      userid: this._userid,
      Customercategory: customercategory,
    };
    this._IsProgressSpinner = true;
    this._RptlaybuyService.GetSubcategory(obj).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._subcategorylist = resultJSON.subcategorylist;
        this._IsProgressSpinner = false;
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }
  RightsChange()
  {
    if(this._Series == "MSC00093")
    {
      this._POoutstandingform.controls["rights"].setValue("MSC00093");
      this._POoutstandingform.get("rights").disable();
      this.onchangecustomertype();

    }
    else
    {
      this._POoutstandingform.controls["rights"].setValue("MSC00339");
      this.onchangecustomertype();
    }
  }
}
