<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <!-- <form [formGroup]="_branchform" (ngSubmit)="onSave()"> -->
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Branch</h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple type="submit" [disabled]="isDisabledsave" title="Save"
                                icon="pi pi-save" class="" (click)="onSave()"></button>
                            <button pButton pRipple type="button" [disabled]="isDisabledreset" (click)="Clear($event)"
                                title="Clear" icon="pi pi-trash" class="" class="p-button-danger"></button>
                            <button pButton pRiple type="button" icon="pi pi-search" title="Back to Search"
                                class="p-button p-button-success p-mr-2" [routerLink]="['/vBranch']"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <p-tabView>
                    <!-- [(activeIndex)]="selectedtab" -->
                    <p-tabPanel header="Branch Details">
                        <form [formGroup]="_branchform" (ngSubmit)="onSave()">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" formControlName="branchcode" pInputText
                                                name="branchcode">
                                            <label for="branchcode"> Enter Branch Code <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('branchcode').touched) && _branchform.get('branchcode').errors?.BranchCodeRequired">
                                            Please enter Branch Code
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" formControlName="branchname" pInputText
                                                name="branchname">
                                            <label for="branchname"> Enter Branch Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('branchname').touched) && _branchform.get('branchname').errors?.BranchNameRequired">
                                            Please enter Branch Name
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_branchtype" [autoDisplayFirst]="false"
                                                name="branchtype" optionLabel="metasubdescription"
                                                optionValue="metasubcode" formControlName="branchtype">
                                            </p-dropdown>
                                            <label for="branchtype"> Branch Type <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('branchtype').touched) && _branchform.get('branchtype').errors?.SelectBranchType">
                                            Please eelect Branch Type
                                        </span>
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_levels" [autoDisplayFirst]="false" name="levelid"
                                                optionLabel="levelname" optionValue="levelid" formControlName="levelid">
                                            </p-dropdown>
                                            <label for="levelid"> Level <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('levelid').touched) && _branchform.get('levelid').errors?.SelectBranchLevel">
                                            Please select Level
                                        </span>
                                    </td>
                                    <td style="width: 20%;" rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="3" cols="30" name="address" formControlName="address"
                                                pInputTextarea></textarea>
                                            <label for="address"> Address <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('address').touched) && _branchform.get('address').errors?.AddressRequired">
                                            Please enter Address
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_currencies" name="currencyid"
                                                optionLabel="currencyname" [autoDisplayFirst]="false"
                                                optionValue="currencyid" formControlName="currencyid">
                                            </p-dropdown>
                                            <label for="currencyid"> Currency <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('currencyid').touched) && _branchform.get('currencyid').errors?.SelectCurrencyName">
                                            Please select Currency
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="contactperson" pInputText
                                                formControlName="contactperson">
                                            <label for="contactperson">Contact Person Name</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="contactnumber" pInputText maxlength="20"
                                                (keypress)="keypress.kpNumber($event)" formControlName="contactnumber">
                                            <label for="contactnumber">Telephone Number</label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="emailid" pInputText formControlName="emailid">
                                            <label for="emailid">Email ID</label>
                                        </span>
                                    </td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_countries" name="countryid"
                                                optionLabel="countryname" optionValue="countryid"
                                                (onChange)="getStates($event)" [autoDisplayFirst]="false"
                                                formControlName="countryid">
                                            </p-dropdown>
                                            <label for="countryid"> Country <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('countryid').touched) && _branchform.get('countryid').errors?.SelectCountryName">
                                            Please select Country
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_states" name="stateid" optionLabel="statename"
                                                (onChange)="getDistrict($event)" [autoDisplayFirst]="false"
                                                optionValue="stateid" formControlName="stateid">
                                            </p-dropdown>
                                            <label for="stateid"> Province <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('stateid').touched) && _branchform.get('stateid').errors?.SelectStateName">
                                            Please select Province
                                        </span>
                                    </td>

                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_districts" name="districtid"
                                                optionLabel="districtname" optionValue="districtid"
                                                formControlName="districtid" [autoDisplayFirst]="false">
                                            </p-dropdown>
                                            <label for="districtid"> City <span class="hlt-txt">*</span></label>
                                        </span>
                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('districtid').touched) && _branchform.get('districtid').errors?.SelectDistrictName">
                                            Please select City
                                        </span>
                                    </td>
                                    <td>
                                        <!-- 29.status must save  defualt as active while saving -->
                                        <!-- 41. status column need to show in the update screen  -->
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_branchstatus" name="branchstatus"
                                                optionLabel="metasubdescription" optionValue="metasubcode"
                                                formControlName="branchstatus" [autoDisplayFirst]="false">
                                            </p-dropdown>
                                            <label for="branchstatus">Branch Status<span
                                                    class="hlt-txt">*</span></label>
                                        </span>

                                        <span class="text-danger"
                                            *ngIf="(_submitted || _branchform.get('branchstatus').touched) && _branchform.get('branchstatus').errors?.SelectStatus">
                                            Please select Branch Status
                                        </span>
                                    </td>
                                    <td>
                                            <span class="p-float-label">
                                                <p-multiSelect name="subbranchid" [options]="_subbranches" [ngModelOptions]="{standalone: true}"
                                                    [(ngModel)]="_selectedsubBranchIndex" displaySelectedLabel=true
                                                    optionLabel="branchname" optionValue="branchid" [filter]="true"
                                                    (onChange)="onSelectsubBranch($event)">
                                                </p-multiSelect>
                                                <label for="subbranchid">Sub Branch <span class="hlt-txt">*</span></label>
                                            </span>
                                    </td>
                                </tr>
                                <tr>
                                    <!-- <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <p-button label="Branch Logo" styleClass="p-button-link"
                                                        title="{{imageselect}}"
                                                        (click)="showdocumentDialog(document,'bottom-right')">
                                                    </p-button>
                                                </div>
                                            </span>
                                            <p-fileUpload class="p-inputgroup-addon p-button p-button-icon-only"
                                                pTooltip="Browse File" tooltipPosition="top" mode="basic" #document
                                                [disabled]="isDisabledButton" accept="image/*" maxFileSize="1000000"
                                                [auto]="true" chooseLabel="jpeg,jpg,png,gif"
                                                (onSelect)="onDocumentSelect($event,document)">
                                            </p-fileUpload>

                                            <button class="p-inputgroup-addon p-button" pTooltip="Cancel" type="button"
                                                (click)="onDocumentClear()" [disabled]="isDisabledButton"
                                                tooltipPosition="top">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                    </td> -->
                                    <td style="width:20%">
                                        <!-- <label for="inputtext">Profile Image </label> -->
                                        <div class="p-inputgroup" *ngIf="_Iscreatefileupload">

                                            <div class="file-upload-wrapper" data-text="Branch Logo(JPEG)">
                                                <input type="file" accept="image/png, image/gif, image/jpeg"
                                                    class="file-upload-field" (change)="fileChangeEvent($event)"
                                                    value="">
                                            </div>
                                        </div>
                                        <div class="p-inputgroup" *ngIf="_Isviewfileupload">
                                            <span class="p-float-label">
                                                <div class="browse-links">
                                                    <a href="javascript:void(0)" (click)="OpenEmployeeImage($event)">
                                                        <p-button label="Branch Logo" styleClass="p-button-link">
                                                        </p-button>
                                                    </a>
                                                </div>
                                            </span>
                                            <button [disabled]="_action == 'view'" class="p-inputgroup-addon p-button"
                                                pTooltip="Cancel" type="button" (click)="onImageClear()"
                                                tooltipPosition="top">
                                                <i class="pi pi-times"></i>
                                            </button>
                                        </div>
                                    </td>
                                    <td>
                                        <div class="p-formgroup-inline">
                                            <div class="p-field-checkbox">
                                                <p-checkbox formControlName="isdelivered" label="Delivered Required"
                                                    binary="true"></p-checkbox>
                                            </div>
                                        </div>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect name="userid" [options]="_settlementofficers"
                                                optionLabel="username" optionValue="userid"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="_selectedSettlementIds" displaySelectedLabel=true
                                                (onChange)="onSelectSettlementOfficer($event)" [filter]="true">
                                            </p-multiSelect>
                                            <label for="levelid"> Settlement officer <span class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-multiSelect name="frombranchid" [options]="_stockAllocationBranches"
                                                optionLabel="branchname" optionValue="branchid"
                                                [ngModelOptions]="{standalone: true}"
                                                [(ngModel)]="_selectedbranchallocationIds" displaySelectedLabel="true"
                                                (onChange)="onSelectStockAllocationBranch($event)" [filter]="true">
                                            </p-multiSelect>
                                            <label for="frombranchid"> Stock Allocate From <span
                                                    class="hlt-txt">*</span>
                                            </label>
                                        </span>
                                    </td>
                                    <td *ngIf="_Iscurrencylimit">
                                        <span class="p-float-label">
                                        <p-inputNumber name="ziglimitinusd" maxlength="16" formControlName="ziglimitinusd" mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2">
                                        </p-inputNumber>
                                        <label for="ziglimitinusd"> Zig Limit in USD <span class="hlt-txt">*</span></label>
                                        </span>
                                    </td>
                                </tr>
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" formControlName="ecbranchname" pInputText
                                                name="ecbranchname">
                                            <label for="ecbranchname"> Enter EC-Branch Name </label>
                                        </span>
                                    </td>

                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" (keypress)="keypress.kpNumber($event)" name="mobileno"
                                                pInputText formControlName="mobileno" maxlength="20">
                                            <label for="mobileno">Mobile Number </label>
                                        </span>
                                    </td>

                                    <td style="width: 20%;" rowspan="2">
                                        <span class="p-float-label">
                                            <textarea rows="5" cols="30" name="branchlocation"
                                                formControlName="branchlocation" pInputTextarea></textarea>
                                            <label for="branchlocation"> Branch Location </label>
                                        </span>
                                    </td>
                                </tr>

                            </table>

                            <!-- Image Upload -->
                            <p-dialog header="Branch Logo" [(visible)]="displaydocumentpopup" [position]="position1"
                                [style]="{width: '500px'}" [baseZIndex]="10000">
                                <img [src]="branchlogo" alt="" class="img-preview">

                                <ng-template pTemplate="footer">
                                    <p-button icon="pi pi-check" (click)="showPopupDialog()" label="Ok"
                                        styleClass="p-button-text">
                                    </p-button>
                                </ng-template>
                            </p-dialog>
                            <table>
                                <tr>
                                    <td>
                                        <!-- Debug -->
                                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                                    </td>
                                </tr>
                            </table>
                        </form>
                    </p-tabPanel>
                    <p-tabPanel header="Branch Account Details">
                        <form [formGroup]="_Branchaccountdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <div class="p-inputgroup">
                                            <span class="p-float-label">
                                                <input type="text" pInputText name="bankcode" disabled
                                                    formControlName="bankcode">
                                                <label for="bankcode">Bank Code <span class="hlt-txt">*</span></label>
                                            </span>
                                            <button class="p-inputgroup-addon p-button"
                                                [disabled]="IsDisabledPopupButton" (click)="showBasicDialog()">
                                                <i class="pi pi-external-link"></i>
                                            </button>
                                        </div>
                                        <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _Branchaccountdetailform.get('bankcode').touched) && _Branchaccountdetailform.get('bankcode').errors?.SelectBankCode">
                                                Please select Bank Code
                                            </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="bankname" disabled
                                                formControlName="bankname">
                                            <label for="bankname"> Enter Bank Name <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _Branchaccountdetailform.get('bankname').touched) && _Branchaccountdetailform.get('bankname').errors?.SelectBankName">
                                                Please select Bank name
                                            </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="branchname"
                                                formControlName="branchname">
                                            <label for="branchname"> Enter Bank Branch <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _Branchaccountdetailform.get('branchname').touched) && _Branchaccountdetailform.get('branchname').errors?.BankBranchRequired">
                                                Please enter Bank Branch
                                            </span> -->
                                    </td>

                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <input type="text" pInputText name="swiftcode" maxlength="30"
                                                formControlName="swiftcode">
                                            <label for="swiftcode"> Enter Swift Code <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _Branchaccountdetailform.get('swiftcode').touched) && _Branchaccountdetailform.get('swiftcode').errors?.SwiftCodeRequired">
                                                Please enter Swift Code
                                            </span> -->
                                    </td>
                                    <td style="width: 20%;"></td>
                                </tr>
                                <tr>
                                    <td>
                                        <span class="p-float-label">
                                            <input type="text" name="accountno" maxlength="20"
                                                (keypress)="keypress.kpNumber($event)" pInputText
                                                formControlName="accountno">
                                            <label for="accountno">Bank Account Number <span
                                                    class="hlt-txt">*</span></label>
                                        </span>
                                        <!-- <span class="text-danger"
                                                *ngIf="(_submitted || _Branchaccountdetailform.get('accountno').touched) && _Branchaccountdetailform.get('accountno').errors?.AccountNoRequired">
                                                Please enter Account Number
                                            </span> -->
                                    </td>
                                    <td>
                                        <span class="p-float-label">
                                            <p-dropdown [options]="_accounttypes" name="accounttype"
                                                [autoDisplayFirst]="false" optionLabel="metasubdescription"
                                                optionValue="metasubcode" formControlName="accounttype">
                                            </p-dropdown>
                                            <label for="accounttype">Account Type</label>
                                        </span>
                                    </td>
                                    <td> <span class="p-float-label">
                                            <input type="text" pInputText name="accountholdername"
                                                formControlName="accountholdername">
                                            <label for="accountholdername"> Enter Account Holder Name</label>
                                        </span>
                                    </td>
                                    <td></td>
                                    <td></td>
                                </tr>
                                <tr>
                                    <td>
                                        <button pButton pRipple label="Add" title="Add" icon="pi pi-plus"
                                            [disabled]="_action == 'view'" (click)="onAddAccount($event)"
                                            class="p-button-success p-mr-2"></button>
                                        <button pButton pRipple label="Clear" (click)="ClearAccount($event)"
                                            title="Clear" icon="pi pi-times" class="p-button-danger p-mr-2"
                                            [disabled]="_action == 'view'"></button>
                                    </td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                </tr>
                            </table>
                        </form>
                        <div class="card">
                            <p-table #dt [value]="_BranchAccounts" [rows]="5" [paginator]="true"
                                [rowsPerPageOptions]="[5,10,25,50,100]" [rowHover]="true" dataKey="bankname"
                                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 100px;">Action</th>
                                        <th>Is Default</th>
                                        <th>Bank Code</th>
                                        <th>Bank Name</th>
                                        <th>Bank Branch</th>
                                        <th>Swift Code</th>
                                        <th>Account Number</th>
                                        <th>Account Type</th>
                                        <th>Account Holder Name</th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_BranchAccount let-ri="rowIndex">
                                    <tr (dblclick)="onRowSupplierAddressselect(_BranchAccount,ri)">
                                        <td class="text-center">
                                            <button pButton pRipple label="" title="Remove" type="button"
                                                [disabled]="_action == 'view'" icon="pi pi-trash"
                                                (click)="RemoveAccount(_BranchAccount)"
                                                class="p-button-danger p-mr-2"></button>
                                        </td>
                                        <td style="text-align: center;width: 50px;">
                                            <p-checkbox [(ngModel)]="_BranchAccount.isdefault"
                                                [ngModelOptions]="{standalone: true}"
                                                (onChange)="Removeselectedchecked(_BranchAccount,ri)"
                                                [disabled]="_action == 'view'" binary="true">
                                            </p-checkbox>
                                        </td>
                                        <td>
                                            {{_BranchAccount.bankcode}}

                                        </td>
                                        <td>

                                            {{_BranchAccount.bankname}}

                                        </td>
                                        <td>
                                            {{_BranchAccount.branchname}}

                                        </td>
                                        <td>
                                            {{_BranchAccount.swiftcode}}

                                        </td>
                                        <td>
                                            {{_BranchAccount.accountno}}

                                        </td>
                                        <td>
                                            {{_BranchAccount.accounttype}}

                                        </td>
                                        <td>
                                            {{_BranchAccount.accountholdername}}

                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                        <p-dialog header="Bank Code" [(visible)]="displayBasic" [modal]="true"
                            [style]="{width: '50vw',height: '500px'}" [baseZIndex]="10000">
                            <ng-template pTemplate="content">
                                <div class="popup-body">
                                    <div class="search-container">
                                        <div class="p-inputgroup" style="width:150px !important;">
                                            <span class="p-float-label">
                                                <input type="text" pInputText
                                                    (input)="dtBanklist.filterGlobal($event.target.value, 'contains')"
                                                    placeholder="Search..." />
                                            </span>
                                            <!-- <button class="p-inputgroup-addon p-button" title="Search">
                                                    <i class="pi pi-search"></i>
                                                </button> -->
                                        </div>
                                    </div>


                                    <div class="card">
                                        <p-table #dtBanklist [value]="Banklist" selectionMode="single" [rows]="50"
                                            [paginator]="true" [rowsPerPageOptions]="[5,10,25,50,100]"
                                            [globalFilterFields]="['bankcode','bankname']" [(selection)]="selectedBank"
                                            (onRowSelect)="onRowSelectPopup($event)" [paginator]="true" [rows]="5">
                                            <ng-template pTemplate="header">
                                                <tr>
                                                    <th pSortableColumn="bankcode">Code<p-sortIcon field="bankcode">
                                                        </p-sortIcon>
                                                    </th>
                                                    <th pSortableColumn="bankname">Name <p-sortIcon field="bankname">
                                                        </p-sortIcon>
                                                    </th>
                                                </tr>
                                            </ng-template>
                                            <ng-template pTemplate="body" let-rowData let-sBank>
                                                <tr [pSelectableRow]="rowData">
                                                    <td>{{sBank.bankcode}}</td>
                                                    <td>{{sBank.bankname}}</td>
                                                </tr>
                                            </ng-template>
                                        </p-table>
                                    </div>
                                </div>
                            </ng-template>
                        </p-dialog>
                    </p-tabPanel>
                    <p-tabPanel header="Branch Product Details">

                        <form [formGroup]="_branchproductdetailform">
                            <table class="normal-table">
                                <tr>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect optionLabel="producttypename" [options]="_producttype"
                                                optionValue="producttypeid" [virtualScroll]="true" itemSize="30"
                                                selectedItemsLabel="{0} items selected" [filter]="true"
                                                [(ngModel)]="_selectedproducttype" [ngModelOptions]="{standalone: true}"
                                                (onChange)="getProductCategory()" displaySelectedLabel="true">
                                            </p-multiSelect>
                                            <label for="salestypename">Product Type</label>
                                        </span>
                                        <!-- <p-multiSelect [options]="_producttype" optionLabel="producttypename"
                                            formControlName="producttypeid" optionValue="producttypeid"
                                            [virtualScroll]="true" itemSize="30" [filter]="true"
                                            (onChange)="getProductCategory($event)" defaultLabel="Product Type">
                                        </p-multiSelect> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect optionLabel="categoryname" [options]="_productcategory"
                                                optionValue="productcategoryid" [virtualScroll]="true" itemSize="30"
                                                selectedItemsLabel="{0} items selected" [filter]="true"
                                                [(ngModel)]="_selectedproductcategory"
                                                [ngModelOptions]="{standalone: true}" (onChange)="getProduct()"
                                                displaySelectedLabel="true">
                                            </p-multiSelect>
                                            <label for="salestypename">Product Category</label>
                                        </span>
                                        <!-- <p-multiSelect [options]="_productcategory" formControlName="productcategoryid"
                                            defaultLabel="Product Category" optionLabel="categoryname"
                                            [virtualScroll]="true" itemSize="30" [filter]="true"
                                            (onChange)="getProduct($event)" optionValue="productcategoryid">
                                        </p-multiSelect> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect optionLabel="productname" [options]="_productname"
                                                optionValue="productid" [virtualScroll]="true" itemSize="30"
                                                selectedItemsLabel="{0} items selected" [filter]="true"
                                                [(ngModel)]="_selectedproduct"
                                                [ngModelOptions]="{standalone: true}" (onChange)="getProductdetails()"
                                                displaySelectedLabel="true">
                                            </p-multiSelect>
                                            <label for="salestypename">Product Name</label>
                                        </span>
                                        <!-- <span class="p-float-label">
                                            <p-multiSelect [filter]="true" filterBy="searchfilter"
                                                [options]="_productname" formControlName="productid"
                                                [virtualScroll]="true" itemSize="30" [filter]="true"
                                                (onChange)="getProductdetails($event)" optionLabel="productname"
                                                optionValue="productid">
                                            </p-multiSelect> -->

                                            <!-- <p-dropdown [autoDisplayFirst]="false" [options]="_productname"
                                                    optionValue="productid" (onChange)="getProductdetails($event)"
                                                    optionLabel="productname" formControlName="productid">
                                                </p-dropdown> -->
                                            <!-- <label for="productid">Product Name </label>
                                        </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-multiSelect optionLabel="brandname" [options]="_brandlist"
                                                optionValue="brandid" [virtualScroll]="true" itemSize="30"
                                                selectedItemsLabel="{0} items selected" [filter]="true"
                                                [(ngModel)]="_selectedbrand"
                                                [ngModelOptions]="{standalone: true}" (onChange)="getProductbrand()"
                                                displaySelectedLabel="true">
                                            </p-multiSelect>
                                            <label for="salestypename">Brand Name</label>
                                        </span>
                                        <!-- <span class="p-float-label">
                                            <p-multiSelect [options]="_brandlist" optionLabel="brandname"
                                                optionValue="brandid" (onChange)="getProductbrand($event)"
                                                [(ngModel)]="brandid" [ngModelOptions]="{standalone: true}">
                                            </p-multiSelect>
                                            <label for="brandid">Brand Name </label>
                                        </span> -->
                                    </td>
                                    <td style="width: 20%;">
                                        <span class="p-float-label">
                                            <p-dropdown [autoDisplayFirst]="false" [options]="_recordstatus"
                                                name="recordstatus" optionLabel="metasubdescription"
                                                optionValue="metasubcode" formControlName="recordstatus">
                                            </p-dropdown>
                                            <label for="recordstatus">Status </label>
                                        </span>
                                    </td>
                                </tr>

                            </table>
                        </form>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input type="text" pInputText
                                            (input)="product.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                            </p-toolbar>
                            <p-table [value]="_BranchProducts" #product [rows]="25" [paginator]="true"
                                [rowsPerPageOptions]="[50,100,500,1000]"
                                [globalFilterFields]="['producttypename','categoryname','productname']"
                                [rowHover]="true" dataKey="productid" [(selection)]="selectedrows"
                                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center; width: 100px;">
                                            <p-tableHeaderCheckbox [disabled]="_action == 'view'">
                                            </p-tableHeaderCheckbox>
                                        </th>
                                        <th>Product Type </th>
                                        <th>Product Category</th>
                                        <th>Product Name</th>
                                        <th>Brand Name</th>
                                        <th>Minimum Level</th>
                                        <th>Maximum Level</th>
                                        <th>Reorder Level</th>

                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_BranchProduct let-ri="rowIndex">
                                    <tr>
                                        <td style="text-align:center;">
                                            <p-tableCheckbox [value]="_BranchProduct" [disabled]="_action == 'view'">
                                            </p-tableCheckbox>
                                        </td>
                                        <td>{{_BranchProduct.producttypename}}</td>
                                        <td>{{_BranchProduct.categoryname}}</td>
                                        <td>{{_BranchProduct.productname}}</td>
                                        <td>{{_BranchProduct.brandname}}</td>
                                        <!-- <td pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input type="number"   [(ngModel)]="_BranchProduct.markuppercentage" 
                                                             
                                                            >
                                                            (keyup)="onKeyupSellingPrice(_BranchProduct,$event)" 
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{_BranchProduct.minimumlevel}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td> -->
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_BranchProduct.minimumlevel"
                                                        (focusout)="OnChangeMinimum(_BranchProduct)" pInputText
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_BranchProduct.minimumlevel}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_BranchProduct.minimumlevel}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>



                                        <!-- <td pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input type="number"   [(ngModel)]="_BranchProduct.maximumlevel" 
                                                             
                                                            >
                                                         (keyup)="onKeyupSellingPrice(_BranchProduct,$event)"
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{_BranchProduct.maximumlevel}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td> -->

                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_BranchProduct.maximumlevel"
                                                        (focusout)="OnChangeMaximum(_BranchProduct)" pInputText
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_BranchProduct.maximumlevel}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_BranchProduct.maximumlevel}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                        <!-- (keyup)="OnChangeMaximum(_BranchProduct,$event)" -->
                                        <!-- <td pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <input type="number"   [(ngModel)]="_BranchProduct.reorderlevel" 
                                                             
                                                            >
                                                             (keyup)="onKeyupSellingPrice(_BranchProduct,$event)"
                                                    </ng-template>
                                                    <ng-template pTemplate="output">
                                                        {{_BranchProduct.reorderlevel}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td> -->
                                        <td pEditableColumn>
                                            <p-cellEditor>
                                                <ng-template pTemplate="input">
                                                    <input type="text" [(ngModel)]="_BranchProduct.reorderlevel"
                                                        (focusout)="OnChangeReorder(_BranchProduct)" pInputText
                                                        *ngIf="_action != 'view'">
                                                    <div *ngIf="_action == 'view'">
                                                        {{_BranchProduct.reorderlevel}}</div>
                                                </ng-template>
                                                <ng-template pTemplate="output">
                                                    {{_BranchProduct.reorderlevel}}
                                                </ng-template>
                                            </p-cellEditor>
                                        </td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>

                    </p-tabPanel>
                    <!-- Ecommerce Branch Brand Details Tab -->
                    <p-tabPanel header="Branch Brand Details(E-Commerce)">
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input type="text" pInputText
                                            (input)="brand.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                            </p-toolbar>
                            <p-table [value]="_BranchBrands" #brand [rows]="25" [paginator]="true"
                                [rowsPerPageOptions]="[50,100,500,1000]" [globalFilterFields]="['brandname']"
                                [rowHover]="true" dataKey="brandid" [(selection)]="selectedbrandrows"
                                (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center; width: 100px;">
                                            <p-tableHeaderCheckbox [disabled]="_action == 'view'">
                                            </p-tableHeaderCheckbox>
                                        </th>
                                        <th pSortableColumn="brandname">Brand Name<p-sortIcon field="brandname">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_BranchBrand let-ri="rowIndex">
                                    <tr>
                                        <td style="text-align:center;">
                                            <p-tableCheckbox [value]="_BranchBrand" [disabled]="_action == 'view'">
                                            </p-tableCheckbox>
                                        </td>
                                        <td>{{_BranchBrand.brandname}}</td>
                                    </tr>
                                </ng-template>
                            </p-table>

                        </div>

                    </p-tabPanel>
                    <p-tabPanel *ngIf="_Iscurrencylimit" header="Invoice Limit for Default Customer">
                        <!-- <div class="form-container scroll-y"> -->
                            <div class="row">
                                <div class="col-md-3"></div> 
                                <div class="col-md-6">    
                            <div class="card">
                                <p-table #dt [value]="_branchwisecurrencylimit" 
                                    [rowHover]="true"
                                    [showCurrentPageReport]="true">
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th pSortableColumn="currencyname" class="text-center">Currency Name <p-sortIcon
                                                    field="currencyname"></p-sortIcon>
                                            </th>
                                            <th pSortableColumn="maximumlimit" class="text-center">Standard Customer Limit upto <p-sortIcon field="maximumlimit">
                                                </p-sortIcon>
                                            </th>                  
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-_branchwisecurrencylimit>
                                        <tr>
                                            <td class="text-center">
                                                {{_branchwisecurrencylimit.currencyname }}
                                            </td>
                                            <td pEditableColumn>
                                                <p-cellEditor>
                                                    <ng-template pTemplate="input">
                                                        <p-inputNumber autocomplete="off"
                                                            maxlength="18"
                                                            [disabled]="_Ispurchaselimit"
                                                            [(ngModel)]="_branchwisecurrencylimit.currencyvalue" inputId="minmaxfraction"
                                                            mode="decimal" [minFractionDigits]="2" [maxFractionDigits]="2" 
                                                            (ngModelChange)="onchanglimit(_branchwisecurrencylimit)"
                                                            (keyup)="onchanglimit(_branchwisecurrencylimit)"
                                                            (blur)="onchanglimit(_branchwisecurrencylimit)"
                                                            (keyup.enter)="onchanglimit(_branchwisecurrencylimit)"
                                                            (Keydown.enter)="onchanglimit(_branchwisecurrencylimit)"
                                                            (focusout)="onchanglimit(_branchwisecurrencylimit)"
                            
                                                            >
                                                        </p-inputNumber>
                                                        <div *ngIf="_action == 'view'">{{_branchwisecurrencylimit.currencyvalue}}</div>
                                                    </ng-template>
                                                    <ng-template pTemplate="output" style="text-align: right;">
                                                        {{_branchwisecurrencylimit.currencyvalue | number:'1.2-2'}}
                                                    </ng-template>
                                                </p-cellEditor>
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>

                                </div> 
                                <div class="col-md-3"></div> 
                            </div>
                        <!-- </div> -->
                    </p-tabPanel>
                </p-tabView>
                <p-confirmDialog [style]="{width: '450px'}"></p-confirmDialog>
            </div>
            <!-- </form> -->
        </div>
    </div>
</div>
<p-dialog header="Image Cropper" [(visible)]="imageCropperTool" [modal]="true" [style]="{width: '500px'}"
    [baseZIndex]="10000">
    <div class="text-center mb10">
        <button pButton pRipple label="" icon="las la-plus" pTooltip="Zoom +" tooltipPosition="top" (click)="zoomIn()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-minus" pTooltip="Zoom -" tooltipPosition="top" (click)="zoomOut()"
            class="p-button p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-redo-alt" pTooltip="Reset Image" tooltipPosition="top"
            (click)="resetImage()" class="p-button-danger p-mr-2"></button>
        <button pButton pRipple label="" icon="las la-save" pTooltip="Save" tooltipPosition="top"
            (click)="onSaveImage()" class="p-button-success p-mr-2"></button>
    </div>
    <div class="row">
        <div class="col-md-6">
            <h5>Input</h5>
            <div class="crop-input">
                <image-cropper class="img-fluid" [imageChangedEvent]="imageChangedEvent" [maintainAspectRatio]="true"
                    [containWithinAspectRatio]="containWithinAspectRatio" [aspectRatio]="7 / 3" [resizeToWidth]="140"
                    [cropperMinWidth]="128" [onlyScaleDown]="true" [roundCropper]="false"
                    [canvasRotation]="canvasRotation" [transform]="transform" [alignImage]="'left'"
                    [style.display]="showCropper ? null : 'none'" format="png" (imageCropped)="imageCropped($event)"
                    (imageLoaded)="imageLoaded()" (cropperReady)="cropperReady($event)"
                    (loadImageFailed)="loadImageFailed()"></image-cropper>
            </div>
        </div>
        <div class="col-md-6">
            <h5>Output</h5>
            <img [src]="croppedImage" />
        </div>
    </div>
</p-dialog>