import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Currency } from 'src/app/core/Models/Currency';
import { Branch } from 'src/app/core/Models/Branch';
import * as ace from "ace-builds";
import { Supplier } from 'src/app/core/Models/Supplier';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Port } from 'src/app/core/Models/Port';
import { vContainerNo, vDateSelect, vSupplierName } from 'src/app/core/Validators/validation';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { DatePipe } from '@angular/common';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { MetadataMaster } from 'src/app/core/Models/overallclass';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';
import { RptsalesprofitandlossService } from 'src/app/core/Services/reports/Sales/rptsalesprofitandloss/rptsalesprofitandloss.service';
import { Level } from 'src/app/core/Models/Level';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { RptsalesreturnService } from 'src/app/core/Services/reports/Sales/RptSalesReturn/rptsalesreturn.service';

@Component({
  selector: 'app-rptsalesprofitandloss',
  templateUrl: './rptsalesprofitandloss.component.html',
  styleUrls: ['./rptsalesprofitandloss.component.css']
})
export class RptsalesprofitandlossComponent implements OnInit {

  _IsProgressSpinner: boolean = true;
  _rptsalesprofitandlossform: FormGroup;
  _branchid: number;
  _userid: number;
  _src: SafeResourceUrl;
  _producttypelist: Producttype[] = [];
  _currencylist: Currency[] = [];
  _levellist: Level[] = [];
  _branchlist: Branch[];
  _productcategorylist: Productcategory[];
  _productlist: Productoverallview[] = [];
  _salesProfitandlossObj: any;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  productcatid: number[] = [];
  producttypeid: number[] = [];
  productid: number[] = [];
  branchid: number;
  markuplevelid: number[] = [];
  _Action: IAction;
  _brandlist: any;
  brandid: number[] = [];
  _ProductObj: any;
  _Tempbrandlist: any;
  mindate: Date;
  maxdate: Date;
  customertypes: any[] = [];
  _categorylist: any[];
  _subcategorylist: any[];
  _Rightslist: any[];
  _Series = sessionStorage["Series"];
  _customertypelist: any[]
  _customerlist: any[];
  customerid: any[] = [];

  constructor(private utility: CommonUtilities, private _AccessRightsService: AccessRightsService, private fb: FormBuilder, private _RptsalesprofitandlossService: RptsalesprofitandlossService,
    private _RptSalesReturnService: RptsalesreturnService, private _router: Router, private _CustomExceptionService: CustomExceptionService, private _hotkeysService: HotkeysService, private sanitizer: DomSanitizer) { }


  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(154);
    this.utility.pageLoadScript();
    this.mindate = new Date(sessionStorage['Environmentstartdate']);
    this.maxdate = new Date(sessionStorage['Environmentenddate']);
    this._rptsalesprofitandlossform = this.fb.group({
      fromdate: [''],
      todate: [''],
      branchid: [],
      currencyid: [0],
      levelid: [0],
      rights: [],
      categoryid:[],
      subcategoryid:[],
      customertype:[]
    })
    this._branchid = parseInt(sessionStorage["currentbranchid"]);
    this._userid = parseInt(sessionStorage["userid"]);
    this.RightsChange();
    this._IsProgressSpinner = true;
    // this._rptsalesprofitandlossform.controls['levelid'].setValue(1);
    this._RptsalesprofitandlossService.PageOnload(this._userid).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));
      this._currencylist = resultJSON.currencyList;
      this._branchlist = resultJSON.branchList;
      this._producttypelist = resultJSON.producttypeList;
      this._levellist = resultJSON.levels;
      this._brandlist = resultJSON.brandList;
      this._Tempbrandlist = resultJSON.brandList;
      this._Rightslist = resultJSON.rightslist;

      this._IsProgressSpinner = false;
      this.SetTimeAndBranch();
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  SetTimeAndBranch() {
    this.branchid = this._branchid
    this.markuplevelid = [];
    this.markuplevelid.push(1);


    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesprofitandlossform.get("fromdate").setValue(new Date);
      this._rptsalesprofitandlossform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesprofitandlossform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesprofitandlossform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptsalesprofitandlossform.get("fromdate").value;
    dt.setDate(dt.getDate() - 1);
    this._rptsalesprofitandlossform.controls['fromdate'].setValue(dt);
    this._rptsalesprofitandlossform.controls['currencyid'].setValue(1);

  }
  clear(event) {
    //25.If clear clicking all fields should clear in grid
    this._rptsalesprofitandlossform.reset();
    if (sessionStorage['Environmentenddate'] == "null") {
      this._rptsalesprofitandlossform.get("fromdate").setValue(new Date);
      this._rptsalesprofitandlossform.get("todate").setValue(new Date);
      this.maxdate = new Date();
    }
    else {
      this._rptsalesprofitandlossform.get("fromdate").setValue(new Date(sessionStorage['Environmentenddate']));
      this._rptsalesprofitandlossform.get("todate").setValue(new Date(sessionStorage['Environmentenddate']))
    }
    var dt = this._rptsalesprofitandlossform.get("fromdate").value;
    dt.setDate(dt.getDate() - 1);
    this._rptsalesprofitandlossform.controls['fromdate'].setValue(dt);
    this._rptsalesprofitandlossform.controls['currencyid'].setValue(1);
    this.RightsChange();

    // this._rptsalesprofitandlossform.controls['levelid'].setValue(1);
    this.productcatid = [];
    this.productid = [];
    this.producttypeid = [];
    this.brandid = [];
    this._brandlist = this._Tempbrandlist
    this._src = this.sanitizer.bypassSecurityTrustResourceUrl("");
    this.SetTimeAndBranch();
  }
  getProductCategory(event) {
    let branchid = [];
    branchid.push(this.branchid);
    var Branchid = branchid || [];

    var selectedProductType = event.value;
    if (selectedProductType.length == 0 || selectedProductType == null) {
      this._productcategorylist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductTypeObj = { "Producttype": selectedProductType || [], "branchid": Branchid };
      this._RptsalesprofitandlossService.getProductCategory(this._ProductTypeObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productcategorylist = resultJSON.productcategories;
        //this._productcategory = [];
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  getProduct(event) {
    let branchid = [];
    branchid.push(this.branchid);
    var Branchid = branchid || [];

    var selectedProductCategory = event.value;
    if (selectedProductCategory.length == 0 || selectedProductCategory == null) {
      this._productlist = [];
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductCategoryObj = { "Productcategory": selectedProductCategory || [], "branchid": Branchid };
      this._RptsalesprofitandlossService.getProduct(this._ProductCategoryObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._productlist = resultJSON.productoverallList;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }
  GenerateReport(event) {
    var _fromdate = this._rptsalesprofitandlossform.get("fromdate").value;
    var _todate = this._rptsalesprofitandlossform.get("todate").value;
    var Rights = this._rptsalesprofitandlossform.get("rights").value;

    var datePipe = new DatePipe("en-US");
    var fromdate = datePipe.transform(_fromdate, 'yyyy-MM-dd');
    var todate = datePipe.transform(_todate, 'yyyy-MM-dd');
    if (fromdate == null) {
      this._CustomExceptionService.handleError("Please Select From Date");
      return;
    }
    if (todate == null) {
      this._CustomExceptionService.handleError("Please Select To Date");
      return;
    }
    else if (fromdate > todate) {
      this._CustomExceptionService.handleError("From Date should be less than to Date");
      return;
    }
    let branchid = [];
    branchid.push(this.branchid);
    // var Branchid =this._rptsalesprofitandlossform.get("branchid").value==null?0:this._rptsalesprofitandlossform.get("branchid").value;
    var Branchid = branchid || []
    var Currencyid = this._rptsalesprofitandlossform.get("currencyid").value == null ? 0 : this._rptsalesprofitandlossform.get("currencyid").value;
    // var Levelid =this._rptsalesprofitandlossform.get("levelid").value==null?0:this._rptsalesprofitandlossform.get("levelid").value;
    let levelid = [];
    levelid.push(this.markuplevelid);
    var Levelid = levelid || [];
    var mediaType = 'application/pdf';
    this._IsProgressSpinner = true;
    this._salesProfitandlossObj = {
      "FromDate": fromdate,
      "ToDate": todate,
      "Branchid": Branchid,
      "ProductIds": this.productid,
      "CurrencyId": Currencyid,
      "CurrenctBranchId": parseInt(sessionStorage["currentbranchid"]),
      "UserId": parseInt(sessionStorage["userid"]),
      "MarkupLevel": this.markuplevelid,
      "brandid": this.brandid || [],
      "rights": Rights
    };
    this._RptsalesprofitandlossService.Print(this._salesProfitandlossObj).subscribe((result) => {
      var blob = new Blob([result], { type: mediaType });
      var url = window.URL.createObjectURL(blob);
      this._src = this.sanitizer.bypassSecurityTrustResourceUrl(url);
      this._IsProgressSpinner = false;
    },
      error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
  }
  ChangeProduct(event) {
    var selectedProduct = event.value;

    if (selectedProduct.length == 0 || selectedProduct == null) {
      this._brandlist = this._Tempbrandlist
      // this._rptbranchwisestockform.controls["productcategoryid"].reset();
      return;
    }
    else {
      this._IsProgressSpinner = true;
      this._ProductObj = { "Products": selectedProduct };
      this._RptsalesprofitandlossService.getBrand(this._ProductObj).subscribe((result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        console.log("brand", resultJSON);

        let productbrand = resultJSON.productbrandlist;
        let uniqueChars = [...new Set(productbrand)];
        uniqueChars = productbrand.filter((test, index, array) =>
          index === array.findIndex((findTest) =>
            findTest.brandid === test.brandid
          )
        );
        this._brandlist = uniqueChars;
        // this._brandlist = resultJSON.productbrandlist;
        this._IsProgressSpinner = false;
      },
        error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
    }
  }

  RightsChange() {
    if (this._Series == "MSC00093") {
      this._rptsalesprofitandlossform.controls["rights"].setValue("MSC00093");
      this._rptsalesprofitandlossform.get("rights").disable();
    }
    else {
      this._rptsalesprofitandlossform.controls["rights"].setValue("MSC00339");
    }
  }
}
