<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3> Stock Inward </h3>
                    </div>
                    <div class="col-md-7 text-right">
                        <div class="action-btn">
                            <button pButton pRipple label="" title="Save" icon="pi pi-save" type="button" [disabled]="isDisabledSave" (click)="onSave()"></button>
                            <button pButton pRipple label="" title="Clear" icon="pi pi-trash" (click)="reset($event)" [disabled]="isDisabledClear" class="p-button-danger"></button>
                            <button pButton pRiple label="" icon="pi pi-search" title="Back to Search" (click)="goBack($event)" class="p-button p-button-success p-mr-2"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <form [formGroup]="_stockinwardform" (ngSubmit)="onSave()">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <!-- If Inward Date"  is not select  show error as  "please select Inward Date " -->
                                <span class="p-float-label">
                                    <p-calendar [showIcon]="true" formControlName="inwarddate" [monthNavigator]="true"
                                        dateFormat="dd/mm/yy" [yearNavigator]="true" yearRange="1980:2030" [minDate]="mindate" [maxDate]="maxdate"
                                        [disabled]="this._action == 'edit'">
                                    </p-calendar>
                                    <label for="inwarddate">Inward Date<span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockinwardform.get('inwarddate').touched || _submitted) && _stockinwardform.get('inwarddate').errors?.SelectInwardDate">
                                    Please Select Inward Date
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_inwardtype"
                                        formControlName="inwardtype" optionLabel="metasubdescription"
                                        [disabled]="this._action == 'edit'"
                                        (onChange)="getSupplierNameorBranchName($event)" optionValue="metasubcode">
                                    </p-dropdown>
                                    <label for="gender">Inward Type <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockinwardform.get('inwardtype').touched || _submitted) && _stockinwardform.get('inwardtype').errors?.SelectInwardType">
                                    Please Select Inward Type
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="invoicebarcode" autocomplete="off" [(ngModel)]="Inwardbarcode" [disabled]="isDisabledbarcode" 
                                    [ngModelOptions]="{standalone: true}" (keyup.enter)="FetchBarcode()" (keydown)="onKeydown($event)"
                                        pInputText
                                         >
                                    <label for="invoicebarcode">Bar code</label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_stockinwardgetsupplierorbranch"
                                        [disabled]="this._action == 'edit'" formControlName="supplieridorbranchid"   [virtualScroll]="true" 
                                        optionLabel="suppliername" [filter]="true" filterBy="suppliername" itemSize="10"
                                        (onChange)="getReferenceNo()" optionValue="supplierid"></p-dropdown>
                                    <label for="gender">Supplier/Branch Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger" *ngIf="(_stockinwardform.get('supplieridorbranchid').touched || _submitted) && _stockinwardform.get('supplieridorbranchid').errors?.SelectBranchSupplierName">
                                    Please Select Supplier/Branch Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [autoDisplayFirst]="false" [options]="_referencenumber"
                                        *ngIf="hidepercentage" formControlName="referenceno"
                                        optionLabel="purchaseorderno" [disabled]="this._action == 'edit'"
                                        (onChange)="getProductDetails($event)" (onKeyUp)="getProductDetails($event)"   optionValue="purchaseorderno"
                                        [filter]="true" filterBy="purchaseorderno" [showClear]="true">
                                    </p-dropdown>
                                    <label *ngIf="hidepercentage" for="gender">Reference No <span
                                            class="hlt-txt">*</span></label>
                                </span>
                                <div *ngIf="hide" class="p-formgroup-inline">
                                    <div class="p-field-checkbox">
                                        <p-checkbox formControlName="isbarcode" label="Is Barcode" binary="true" (onChange)="barcodebased()"></p-checkbox>
                                    </div>
                                </div>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown *ngIf="hidepercentage" [autoDisplayFirst]="false"
                                        [options]="_stockDelivery" formControlName="deliveryno"
                                        optionLabel="purchaseorderno" [disabled]="isConsignment"
                                        [disabled]="this._action == 'edit'" (onChange)="getProductDetails($event)"
                                        optionValue="purchaseorderno" [filter]="true" filterBy="purchaseorderno"
                                        [showClear]="true">
                                    </p-dropdown>
                                    <label *ngIf="hidepercentage" for="gender">Delivery No <span
                                            class="hlt-txt"></span></label>
                                </span>

                                <span class="p-float-label">
                                    <input *ngIf="hide" type="text" name="productbarcode" autocomplete="off"
                                        [(ngModel)]="productbarcode" [ngModelOptions]="{standalone: true}"
                                        [disabled]="Isbarcodeenable" pInputText
                                        (keyup.enter)="FetchProductUsingBarcode()">
                                    <label *ngIf="hide" for="productbarcode">Product Barcode</label>
                                </span>

                            </td>

                        </tr>
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="vehicleno" [disabled]="false">
                                    <label for="vehicleno">Vehicle No </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_stockinwardform.get('vehicleno').touched || _submitted) && _stockinwardform.get('vehicleno').errors?.VehicleNoRequired">
                                    Please Enter Vehicle No
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="drivername">
                                    <label for="drivername">Driver/Person Name </label>
                                </span>
                                <!-- <span class="text-danger"
                                    *ngIf="(_stockinwardform.get('drivername').touched || _submitted) && _stockinwardform.get('drivername').errors?.PersonNameRequired">
                                    Please Enter Driver/Person Name
                                </span> -->
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="blnumber"
                                        [disabled]="this._action == 'edit'">
                                    <label for="blnumber">BL Number </label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" pInputText formControlName="containernumber">
                                    <label for="containernumber">Container No. </label>
                                </span>
                            </td>
                        </tr>
                    </table>
                </form>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Invoice Details</h5>

                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-table #dt [value]="_stockinwardinvoicedetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [globalFilterFields]="['invoiceno','invoicedate','invoiceamount']" [rowHover]="true" dataKey="invoiceno">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="width:100px;text-align: center;">Action</th>
                                <th>Invoice No / Delivery No</th>
                                <th>Invoice Date</th>
                                <th>Invoice Amount</th>
                                <th>Currency</th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_stockinwardinvoicedetail>
                            <tr>
                                <td style="width:100px;text-align: center;">
                                    <button pButton pRipple title="Add" icon="pi pi-plus" class="p-button-success p-mr-2" [disabled]="isDisabledadd" [disabled]="this._action == 'edit' || this._action == 'view' " (click)="AddInvoiceDetails($event)"></button>
                                    <button pButton pRipple title="Remove" icon="pi pi-trash" class="p-button-danger p-mr-2" [disabled]="isDisabledremove" [disabled]="this._action == 'edit'|| this._action == 'view'" (click)="RemoveInvoiceDetails(_stockinwardinvoicedetail)"></button>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="text" [(ngModel)]="_stockinwardinvoicedetail.invoiceno" [disabled]="this._action == 'edit'" *ngIf="_action != 'view'" pInputText>
                                            <div *ngIf="_action == 'view'">{{_stockinwardinvoicedetail.invoiceno}}</div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_stockinwardinvoicedetail.invoiceno}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-calendar [(ngModel)]="_stockinwardinvoicedetail.invoicedate" [disabled]="this._action == 'edit'" *ngIf="_action != 'view'" [showIcon]="false" dateFormat="dd/mm/yy">
                                            </p-calendar>
                                            <div *ngIf="_action == 'view'">{{_stockinwardinvoicedetail.invoicedate | date:'dd/MM/yyyy'}}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_stockinwardinvoicedetail.invoicedate | date:'dd/MM/yyyy'}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_stockinwardinvoicedetail.invoiceamount" [disabled]="this._action == 'edit'" *ngIf="_action != 'view'" pInputText>
                                            <div *ngIf="_action == 'view'">{{_stockinwardinvoicedetail.invoiceamount }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_stockinwardinvoicedetail.invoiceamount}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-dropdown #id [autoDisplayFirst]="true" [options]="_currency" [disabled]="this._action == 'edit'" *ngIf="_action != 'view'" optionLabel="currencyname" optionValue="currencyid" [(ngModel)]="_stockinwardinvoicedetail.currencyid">
                                            </p-dropdown>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            <p-dropdown [disabled]="true" [autoDisplayFirst]="true" [options]="_currency" optionLabel="currencyname" optionValue="currencyid" [(ngModel)]="_stockinwardinvoicedetail.currencyid">
                                            </p-dropdown>
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <div class="sub-title">
                    <div class="row">
                        <div class="col-md-5">
                            <h5>Product Details</h5>
                        </div>
                        <div class="col-md-7"></div>
                    </div>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text"
                                    (input)="dtInwardProductDetails.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dtInwardProductDetails [value]="_stockinwardproductdetails" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" (onRowSelect)="onRowSelect($event)" (onRowUnselect)="onRowUnselect($event)" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                        [showCurrentPageReport]="true" [globalFilterFields]="['productname','variantdescription','orderqunatity']" [rowHover]="true" [(selection)]="selectedProductDetailsRows" dataKey="productid">
                        <ng-template pTemplate="header">
                            <tr>
                                <th style="text-align: center;width: 50px;" [hidden]="isConsignmentproduct">
                                    <!-- <p-tableHeaderCheckbox [disabled]="_action == 'view' || _action == 'edit'" [value]="_productdetails" (click)="SelectAll($event)" ></p-tableHeaderCheckbox> -->
                                    <p-tableHeaderCheckbox [disabled]="_action == 'view' || _action == 'edit'" (click)="SelectAll($event)"></p-tableHeaderCheckbox>
                                </th>
                                <th>Product Name</th>
                                <th>Variant</th>
                                <th>PO / STO Qty</th>
                                <th>Lot No.</th>
                                <th>PO / Delivery Qty</th>
                                <th>Invoice Qty</th>
                                <th>Received Qty</th>
                                <th>UOM</th>
                                <!-- <th>Partially Delivered</th> -->
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_stockinwardproductdetail let-ri="rowIndex">
                            <tr>
                                <td style="text-align: center;width: 50px;" [hidden]="isConsignmentproduct">
                                    <p-tableCheckbox [disabled]="_action == 'view' || _action == 'edit'" [value]="_stockinwardproductdetail"></p-tableCheckbox>
                                </td>
                                <td>
                                    {{_stockinwardproductdetail.productname}}
                                </td>
                                <td>
                                    {{_stockinwardproductdetail.variantdescription}}
                                </td>
                                <td>
                                    {{_stockinwardproductdetail.quantity}}
                                </td>
                                <td>
                                    {{_stockinwardproductdetail.lotno}}
                                </td>
                                <td>
                                    {{_stockinwardproductdetail.orderqunatity}}
                                </td>
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" *ngIf="_stockinwardproductdetail.uomcode != 'MSC00039'&& _action != 'view' " (keyup)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (keyup.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)"
                                                (Keydown.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (focusout)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_stockinwardproductdetail.invoicequantity"
                                                inputId="minmaxfraction" mode="decimal" [min]="0" [minFractionDigits]="0" [maxFractionDigits]="0" >
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" *ngIf="_stockinwardproductdetail.uomcode == 'MSC00039'&& _action != 'view' " (keyup)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (keyup.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)"
                                                (Keydown.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (focusout)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_stockinwardproductdetail.invoicequantity"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" [maxFractionDigits]="3" >
                                            </p-inputNumber>
                                            <!-- <input type="text" [(ngModel)]="_stockinwardproductdetail.invoicequantity" pInputText *ngIf="_action != 'view'" (keyup)="allocatequantity(_productdetail,$event)" (focusout)="allocatequantity(_productdetail,$event)" maxlength="13" (keypress)="keypress.kpNumber($event)"> -->
                                            <div *ngIf="_action == 'view'">{{_stockinwardproductdetail.invoicequantity }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_stockinwardproductdetail.invoicequantity }}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_stockinwardproductdetail.invoicequantity" (keypress)="keypress.kpNumber($event)"
                                             *ngIf="_action != 'view'" (focusout)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" pInputText>
                                            <div *ngIf="_action == 'view'">{{_stockinwardproductdetail.invoicequantity }}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_stockinwardproductdetail.invoicequantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" *ngIf="_stockinwardproductdetail.uomcode != 'MSC00039'&& _action != 'view' " (keyup)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (keyup.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)"
                                                (Keydown.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (focusout)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_stockinwardproductdetail.receivedquantity" (keydown)="PreventMinus($event)"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="0" [maxFractionDigits]="0" >
                                            </p-inputNumber>
                                            <p-inputNumber autocomplete="off" (onBlur)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" *ngIf="_stockinwardproductdetail.uomcode == 'MSC00039'&& _action != 'view' " (keyup)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (keyup.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)"
                                                (Keydown.enter)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (focusout)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" maxlength="13" (keypress)="keypress.kpNumber($event)" [(ngModel)]="_stockinwardproductdetail.receivedquantity" (keydown)="PreventMinus($event)"
                                                inputId="minmaxfraction" mode="decimal" [minFractionDigits]="3" >
                                            </p-inputNumber>
                                            <div *ngIf="_action == 'view'">
                                                {{_stockinwardproductdetail.receivedquantity}}
                                            </div>
                                        </ng-template>
                                        <ng-template pTemplate="output">
                                            {{_stockinwardproductdetail.receivedquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td>
                                <!-- <td pEditableColumn>
                                    <p-cellEditor>
                                        <ng-template pTemplate="input">
                                            <input type="number" [(ngModel)]="_stockinwardproductdetail.receivedquantity" 
                                            *ngIf="_action != 'view'" (focusout)="onchangeinvoicequantity(_stockinwardproductdetail,$event)" (keypress)="keypress.kpNumber($event)" pInputText>
                                            <div *ngIf="_action == 'view'">{{_stockinwardproductdetail.receivedquantity }}
                                            </div>
                                        </ng-template> -->
                                <!-- (keyup)="onchangereceivedquantity($event)" -->
                                <!-- <ng-template pTemplate="output">
                                            {{_stockinwardproductdetail.receivedquantity}}
                                        </ng-template>
                                    </p-cellEditor>
                                </td> -->
                                <td>
                                    {{_stockinwardproductdetail.uomname}}
                                </td>

                                <!-- <td style="text-align:center">
                                    <p-checkbox [(ngModel)]="_stockinwardproductdetail.partiallydelivered"
                                        [ngModelOptions]="{standalone: true}" [disabled]="_action=='view'"
                                         binary="true">
                                    </p-checkbox>
                                </td> -->
                            </tr>
                        </ng-template>
                    </p-table>
                </div>
            </div>
            <table>
                <tr>
                    <td>
                        <div #editor style="width: 500px;height: 250px; overflow-y:auto;"></div>
                    </td>
                </tr>
            </table>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>