import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Branch } from 'src/app/core/Models/Branch';
import { Branchaccountdetail } from 'src/app/core/Models/Branchaccountdetail';
import { Country } from 'src/app/core/Models/Country';
import { Currency } from 'src/app/core/Models/Currency';
import { District } from 'src/app/core/Models/District';
import { Level } from 'src/app/core/Models/Level';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { State } from 'src/app/core/Models/State';
import { BranchService } from 'src/app/core/services/masters/branch.service';
import {
  vAccountNo,
  vAddress,
  vBankBranch,
  vBankCodeSelect,
  vBankNameSelect,
  vBranchCode,
  vBranchLevelSelect,
  vBranchName,
  vBranchTypeSelect,
  vCountryName,
  vCountryNameSelect,
  vCurrencyName,
  vCurrencyNameSelect,
  vDistrictNameSelect,
  vStateNameSelect,
  vStatusSelect,
  vSwiftCode,
} from 'src/app/core/Validators/validation';
import * as ace from 'ace-builds';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { usererrors } from 'src/app/core/errors/usererrors';
import { Branchproductdetail } from 'src/app/core/Models/Branchproductdetail';
import { Branchpageloadview } from 'src/app/core/Views/Branchpageloadview';
import { Producttype } from 'src/app/core/Models/Producttype';
import { Productcategory } from 'src/app/core/Models/Productcategory';
import { Productheader } from 'src/app/core/Models/Productheader';
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Productoverallview } from 'src/app/core/Views/Productoverallview';
import { FileUpload } from 'primeng/fileupload';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { KeyPress } from 'src/assets/js/KeyPress';
import { User } from 'src/app/core/Models/User';
import { Branchsettlementofficer } from 'src/app/core/Models/BranchSettlementOfficer';
import { Branchbranddetail } from 'src/app/core/Models/Branchbranddetail';
import {
  base64ToFile,
  Dimensions,
  ImageCroppedEvent,
  ImageTransform,
} from 'ngx-image-cropper';
import { environment } from 'src/environments/environment';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { IAction } from 'src/app/core/Services/security/IActions';
import { Subbranchdetail } from 'src/app/core/Models/Subbranchdetail';
import { Branchwisecurrencylimitview } from 'src/app/core/Views/Branchwisecurrencylimitview';
import { Branchwisecurrencylimit } from 'src/app/core/Models/branchwisecurrencylimit';

declare var $: any;

@Component({
  selector: 'app-c-branch',
  templateUrl: './c-branch.component.html',
  styleUrls: ['./c-branch.component.css'],
})
export class CBranchComponent implements OnInit {
  _Action: IAction;
  _branchform: FormGroup;
  _Branchaccountdetailform: FormGroup;
  _branchproductdetailform: FormGroup;
  _action: string = 'create';

  _stockAllocationBranches: Branch[] = [];

  _submitted = false;
  isDisabledChecked = true;
  _recordstatus: Metadatum[] = [];
  _producttype: Producttype;
  _selectedproducttype: any[] = [];
  _selectedproductcategory: any[] = [];
  _selectedproduct: any[] = [];
  _selectedbrand: any[] = [];
  _productcategory: Productcategory[] = [];
  _productname: Productoverallview[] = [];
  _branchstatus: Metadatum;
  _branchtype: Metadatum;
  _levels: Level;
  _settlementofficers: User;
  _currencies: Currency;
  _countries: Country;
  _states: State;
  _districts: District;
  _accounttypes: Metadatum[];
  _branchid: number;
  _branchdetailid: number;
  _Branch: Branch;
  rowsempty: boolean;
  _Branchaccountdetail: Branchaccountdetail;

  _BranchAccounts: Branchaccountdetail[] = [];
  _BranchAccount: Branchaccountdetail;
  selectedCategory: any;
  _Branchproductdetail: Branchproductdetail[] = [];
  _BranchOBJ: any;
  hideInCreatePage: boolean;
  displayBasic: boolean;
  _bankcode: Branchaccountdetail;
  _bankname: Branchaccountdetail;
  selectedBank: any;
  Banklist: Branchaccountdetail;
  sBank: Branchaccountdetail;
  _BranchProducts: any[] = [];
  _TempBranchProducts: any[] = [];
  _BranchProductsList: any[] = [];
  _TempProducts: any[] = [];
  _BranchProduct: Branchproductdetail[] = [];
  selectedrows: Branchproductdetail[] = [];
  position1: string;
  displaydocumentpopup: boolean;
  _BranchFORM: FormData;
  _selectedDocuments: File;
  _LastAddedDocument: File;
  isDisabledsave = false;
  isDisabledreset = false;
  _IsProgressSpinner: boolean = true;
  selectedtab: number = 0;
  branchlogo: SafeUrl;
  documentimage: SafeUrl;
  isDisabledButton = false;
  imageselect: string = '';
  IsDisabledPopupButton = false;
  accountRowIndex: number = -1;
  _ProductTypeObj: any;
  _ProductCategoryObj: any;
  _ProductObj: any;
  _SelectedSettlementofficers: Branchsettlementofficer[];
  _Selectedbranchallocation: any[] = [];
  _selectedSettlementIds: number[] = [];
  _selectedbranchallocationIds: number[] = [];
  _userid = sessionStorage['userid'];
  _branchwisecurrencylimit: Branchwisecurrencylimit[] = [];
  _Ispurchaselimit: boolean = false;
  _roleid = sessionStorage['currentRole'];
  _Iscurrencylimit: boolean = false;
  //Ecommerce
  _BranchBrands: any[] = [];
  selectedbrandrows: any[] = [];
  SelectedSaveBrandSDetails: Branchbranddetail[] = [];

  //Branch Logo
  imageCropperTool: boolean;
  fileUpload: any;
  imageChangedEvent: any;
  croppedImage: any = '';
  canvasRotation = 0;
  rotation = 0;
  scale = 1;
  showCropper = false;
  containWithinAspectRatio = false;
  transform: ImageTransform = {};
  file: any;
  size: any;
  width: number;
  height: number;
  _Initials: any[];
  _CropImage: File;
  _Iscreatefileupload: boolean = true;
  _Isviewfileupload: boolean = true;
  LogoImage: string;
  _brandlist: any;
  brandid: number;
  _selectedsubBranchIndex: number[] = [];
  _selectedsubBranches: any[] = [];
  _subbranches: Branch[] = [];
  _tempsubbranches: Branch[] = [];
  _updatebranchid: number = 0;
  _tempcurrencies: Currency[] = [];
  //Branch Logo
  checkValidation(evt: any) {
    let image: any = evt.target.files[0];
    this.size = image.size;
    let fr = new FileReader();
    fr.onload = () => {
      // when file has loaded
      var img = new Image();

      img.onload = () => {
        this.width = img.width;
        this.height = img.height;
        console.log('Width:', this.width);
        console.log('Height:', this.height);
        if (this.width <= 140 || this.height <= 60) {
          alert('error');
        } else {
          this.fileChangeEvent(event);
        }
      };
      img.src = fr.result as string; // This is the data URL
      // this.imgType.nativeElement.value = '';
    };
    fr.readAsDataURL(image);
  }

  fileChangeEvent(event: any): void {
    console.log('image', event);

    this.imageChangedEvent = event;
    // console.log("Image", this.imageChangedEvent);
    // var fileInput = $(this).find("input[type=file]")[0],
    //     file = fileInput.files && fileInput.files[0];
    // alert(file);
    // console.log("Image Size", this.path[0].value);
    this.imageCropperTool = true;
  }

  imageCropped(event: ImageCroppedEvent) {
    this.croppedImage = event.base64;
    console.log(event, base64ToFile(event.base64));
  }

  imageLoaded() {
    this.showCropper = true;
    console.log('Image loaded');
  }

  cropperReady(sourceImageDimensions: Dimensions) {
    console.log('Cropper ready', sourceImageDimensions);
  }

  onSaveImage() {
    var imgWidth = $('.source-image').width();
    var imgheight = $('.source-image').height();
    this.createBlobImageFileAndShow();
    this.imageCropperTool = false;
    // alert('Cropper ready Width : ' + imgWidth + ' Cropper ready Height : ' + imgheight);
  }
  createBlobImageFileAndShow(): void {
    const imageBlob: Blob = base64ToFile(this.croppedImage);
    const imageName: string = this.generateName();
    this._CropImage = new File([imageBlob], imageName, {
      type: 'image/jpeg',
    });
  }

  generateName(): string {
    const date: number = new Date().valueOf();
    let text: string = '';
    const possibleText: string =
      'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    for (let i = 0; i < 5; i++) {
      text += possibleText.charAt(
        Math.floor(Math.random() * possibleText.length)
      );
    }
    // Replace extension according to your media type like this
    return date + '.' + text + '.jpg';
  }

  loadImageFailed() {
    console.log('Load failed');
  }
  resetImage() {
    this.scale = 1;
    this.rotation = 0;
    this.canvasRotation = 0;
    this.transform = {};
  }
  zoomOut() {
    this.scale -= 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }

  zoomIn() {
    this.scale += 0.1;
    this.transform = {
      ...this.transform,
      scale: this.scale,
    };
  }
  @ViewChild('editor', { static: true })
  private editor: ElementRef<HTMLElement>;
  constructor(
    private fb: FormBuilder,
    private messageService: MessageService,
    private _CustomExceptionService: CustomExceptionService,
    private _BranchService: BranchService,
    private utility: CommonUtilities,
    public keypress: KeyPress,
    private _router: Router,
    private _hotkeysService: HotkeysService,
    private _DomSanitizationService: DomSanitizer,
    private _AccessRightsService: AccessRightsService
  ) {
    this._branchid = history.state?.branchid ? history.state?.branchid : 0;
    this._action =
      history.state.action == null ? 'create' : history.state.action;
    this.HotKeyIntegration();
  }

  InitializeForm() {
    this._branchform = this.fb.group({
      companyid: [],
      branchid: [0],
      branchcode: ['', vBranchCode],
      branchname: ['', vBranchName],
      branchtype: ['', vBranchTypeSelect],
      levelid: ['', vBranchLevelSelect],
      currencyid: ['', vCurrencyNameSelect],
      address: ['', vAddress],
      contactperson: [''],
      contactnumber: [''],
      emailid: [''],
      countryid: ['', vCountryNameSelect],
      stateid: ['', vStateNameSelect],
      districtid: ['', vDistrictNameSelect],
      series: [],
      branchstatus: ['', vStatusSelect],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      branchaccountdetails: [],
      userbranchmaps: [],
      branchproductdetails: [],
      attachmenturl: [],
      attachmentname: [],
      isdelivered: [],
      settlementofficer: [],
      branchsettlementofficers: [],
      branchlocation: [],
      ecbranchname: [],
      mobileno: [],
      branchbranddetails: [],
      branchstockallocationdetails: [],
      subbranchdetails: [],
      ziglimitinusd: [],
      branchwisecurrencylimits: [],
    });
    this._Branchaccountdetailform = this.fb.group({
      companyid: [0],
      branchdetailid: [0],
      branchid: [0],
      bankcode: ['', vBankCodeSelect],
      bankname: ['', vBankNameSelect],
      swiftcode: ['', vSwiftCode],
      branchname: ['', vBankBranch],
      accountholdername: [],
      accountno: ['', vAccountNo],
      accounttype: [],
      accountingyear: [],
      accountstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      isdefault: [],
      branch: [],
    });
    this._branchproductdetailform = this.fb.group({
      branchproductdetailid: [],
      companyid: [0],
      branchid: [0],
      producttypeid: [],
      productcategoryid: [],
      productid: [],
      productprice: [],
      recordstatus: [],
      createdby: [],
      createdon: [],
      modifiedby: [],
      modifiedon: [],
      minimumlevel: [],
      maximumlevel: [],
      reorderlevel: [],
      branch: [],
    });

    if (this._recordstatus && this._recordstatus.length)
      this._branchform
        .get('branchstatus')
        .setValue(this._recordstatus[0].metasubcode);
  }

  ngOnInit(): void {
    this._Action = this._AccessRightsService.getAccessRights(22);
    this.utility.pageLoadScript();
    this.InitializeForm();
    this._IsProgressSpinner = true;
    if (this._roleid == "1") {
      this._Iscurrencylimit = true;
    }
    this._BranchService.PageOnLoad(this._branchid, this._userid).subscribe(
      (result) => {
        const resultJSON = JSON.parse(JSON.stringify(result));
        this._branchstatus = resultJSON.branchStatus;
        this._branchtype = resultJSON.branchTypes;
        this._levels = resultJSON.levels;
        this._currencies = resultJSON.currencies;
        this._tempcurrencies = resultJSON.currencies;
        this._countries = resultJSON.countries;
        this._accounttypes = resultJSON.accountType;
        this.Banklist = resultJSON.banks;
        this._stockAllocationBranches = resultJSON.branches;
        // this._BranchProducts = resultJSON.branchpageloadviews;
        // this._TempProducts = resultJSON.branchpageloadviews;
        this._producttype = resultJSON.producttypes;
        this._productcategory = resultJSON.productcategories;
        // this._productname = resultJSON.productoverallviews;
        this._recordstatus = resultJSON.branchStatus;
        this._settlementofficers = resultJSON.settlementofficer;
        this._subbranches = resultJSON.branches;
        this._tempsubbranches = resultJSON.branches;
        this._branchwisecurrencylimit = resultJSON.branchwisecurrencylimitviews;
        this._IsProgressSpinner = false;
        this._branchform.controls['branchstatus'].setValue('MSC00001');
        if (this._recordstatus && this._recordstatus.length)
          this._branchform
            .get('branchstatus')
            .setValue(this._recordstatus[0].metasubcode);
        //Ecommerce
        this._BranchBrands = resultJSON.branchbrandviewlist;
        //For Update
        if (this._action == 'edit' || this._action == 'view') {
          this._Isviewfileupload = true;
          this._Iscreatefileupload = false;

          this._selectedbranchallocationIds = [];
          const updateJSON = JSON.parse(JSON.stringify(result));
          console.log('for logo', updateJSON);

          this._Selectedbranchallocation =
            updateJSON.branchstockallocationselectedlist || [];
          this._Selectedbranchallocation.forEach((element) => {
            this._selectedbranchallocationIds.push(element.frombranchid);
          });
          // Multi Select Sub Branch
          this._selectedsubBranchIndex = [];
          // var selectedBranchArrayIndex: number[] = [];
          this._selectedsubBranches = updateJSON.subbranchdetaillist || [];
          this._selectedsubBranches.forEach((element) => {
            this._selectedsubBranchIndex.push(element.subbranchid);
          });
          if (updateJSON.branch.attachmentname == null) {
            this._Iscreatefileupload = true;
            this._Isviewfileupload = false;
          } else {
            // this._Isviewfileupload = false
            this.LogoImage = updateJSON.branch.attachmenturl;
          }
          //this._selectedbranchallocationIds = updateJSON.branchstockallocationselectedlist;
          this._branchform.setValue(updateJSON.branch);

          this._updatebranchid = updateJSON.branch.branchid;

          this._BranchAccounts = updateJSON.branchaccountdetails;
          this._productcategory = updateJSON.branchproductcategories;
          this.SubbranchLoad();
          //this.accountRowIndex = updateJSON.customeraddressdetails;
          this._countries = updateJSON.countries;
          this._states = updateJSON.states;
          this._districts = updateJSON.districts;
          //Multiselect Settlement Officers
          var SettlementIDS: number[] = [];
          Object.keys(updateJSON.branchsettlementofficers).map((Index) => {
            SettlementIDS.push(
              updateJSON.branchsettlementofficers[Index].settlementofficerid
            );
          });
          this._selectedSettlementIds = SettlementIDS;
          this._SelectedSettlementofficers =
            updateJSON.branchsettlementofficers;

          //Ecommerce
          this.selectedbrandrows = updateJSON.branchbrandviewselectedlist;
          if (updateJSON.branchaccountdetails == null) {
            this._branchform.disable();
            this._Branchaccountdetailform.disable();
            this._branchproductdetailform.disable();
            this.isDisabledsave = true;
            this.isDisabledreset = true;
            this.isDisabledChecked = true;
            this.isDisabledButton = true;
            this.IsDisabledPopupButton = true;
            return;
          }

          var selectedRows: Branchproductdetail[] = [];
          // var l_suppliers: any = this._BranchProducts;
          // var selectedProductObj = updateJSON.branchproductdetails;
          // if (updateJSON.branch.attachmenturl) {
          //   this.branchlogo = this._DomSanitizationService.bypassSecurityTrustUrl((updateJSON.branch.attachmenturl));
          // }
          // Object.keys(l_suppliers).map((Index) => {
          //   var obj = selectedProductObj.find(obj => obj.productid == l_suppliers[Index].productid);
          //   if (obj) {
          //     l_suppliers[Index].reorderlevel = obj.reorderlevel;
          //     l_suppliers[Index].minimumlevel = obj.minimumlevel;
          //     l_suppliers[Index].maximumlevel = obj.maximumlevel;
          //     selectedRows.push(obj);
          //   }
          // });
          // this.selectedrows = selectedRows || [];
        }
        if (this._action == 'view') {
          this._branchform.disable();
          this._Branchaccountdetailform.disable();
          this._branchproductdetailform.disable();
          this.isDisabledsave = true;
          this.isDisabledreset = true;
          this.isDisabledChecked = true;
          this.isDisabledButton = true;
          this.IsDisabledPopupButton = true;
          this._Ispurchaselimit = true;
        }
        if (this._action == 'edit') {
          this.isDisabledreset = true;
        }
        if (this._action == 'create') {
          this._Iscreatefileupload = true;
          this._Isviewfileupload = false;
        }
        $(document).on('change', '.file-upload-field', function () {
          $(this)
            .parent('.file-upload-wrapper')
            .attr(
              'data-text',
              $(this)
                .val()
                .replace(/.*(\/|\\)/, '')
            );
        });
      },
      (error) => {
        this._CustomExceptionService.handleError(error);
        this._IsProgressSpinner = false;
      }
    );
  }

  onAddAccount(event) {
    var accountdetail: any = {};
    accountdetail.branchdetailid = 0;
    accountdetail.companyid = 0;
    accountdetail.accountstatus = '';
    accountdetail.createdby = sessionStorage['userid'];
    accountdetail.createdon = new Date();
    accountdetail.modifiedby = sessionStorage['userid'];
    accountdetail.modifiedon = new Date();
    accountdetail.accountingyear = new Date().getFullYear();
    accountdetail.branchid = 0;
    accountdetail.bankcode =
      this._Branchaccountdetailform.get('bankcode').value;
    accountdetail.bankname =
      this._Branchaccountdetailform.get('bankname').value;
    accountdetail.branchname =
      this._Branchaccountdetailform.get('branchname').value;
    accountdetail.swiftcode =
      this._Branchaccountdetailform.get('swiftcode').value;
    accountdetail.accountno =
      this._Branchaccountdetailform.get('accountno').value;
    accountdetail.accounttype =
      this._Branchaccountdetailform.get('accounttype').value;
    accountdetail.accountholdername =
      this._Branchaccountdetailform.get('accountholdername').value;
    accountdetail.isdefault =
      this._Branchaccountdetailform.get('isdefault').value;
    if (
      this._Branchaccountdetailform.get('accounttype').value == '' ||
      this._Branchaccountdetailform.get('accounttype').value == null
    ) {
      accountdetail.acccountid = 0;
      accountdetail.metasubdescription = '';
    } else {
      var acccountid = this._Branchaccountdetailform.get('accounttype').value;
      var Accounttype = (this._accounttypes || []).filter(
        (f) => f.metasubcode == acccountid
      )[0].metasubdescription;
      accountdetail.accounttype = acccountid;
      accountdetail.accounttype = Accounttype;
    }
    //accountdetail.supplier = null;
    if (this.accountRowIndex > -1) {
      for (var i = 0; i < this._BranchAccounts.length; i++) {
        if (
          accountdetail.bankname == this._BranchAccounts[i].bankname &&
          accountdetail.bankname == this._BranchAccounts[i].bankname &&
          this.accountRowIndex != i
        ) {
          this._CustomExceptionService.handleWarning(
            'Bank Name Already Exists!'
          );
          return;
        }
      }
      this._BranchAccounts[this.accountRowIndex] =
        this._Branchaccountdetailform.value;
      // this._SuppliersAddress[this.addressRowIndex].createdon = new Date();
    } else {
      if (this._BranchAccounts.length > 0) {
        for (var i = 0; i < this._BranchAccounts.length; i++) {
          if (
            accountdetail.bankname == this._BranchAccounts[i].bankname &&
            accountdetail.bankname == this._BranchAccounts[i].bankname
          ) {
            this._CustomExceptionService.handleWarning(
              'Bank Name Already Exists!'
            );
            return;
          }
        }
        this._BranchAccounts.push(accountdetail);
      } else {
        this._BranchAccounts.push(accountdetail);
      }
    }
    this.ClearAccount(event);
  }

  ClearAccount(event) {
    this._Branchaccountdetailform.reset();
    //this.InitializeForm();
    this._submitted = false;
    this.accountRowIndex = -1;
  }
  RemoveAccount(pAccount) {
    var index = this._BranchAccounts.indexOf(pAccount);
    this._BranchAccounts.splice(index, 1);
  }
  onSelectSettlementOfficer(event) {
    var l_settlementofficer: Branchsettlementofficer;
    var l_SettlementOfficers: Branchsettlementofficer[] = [];
    Object.keys(event.value).map((Index) => {
      l_settlementofficer = new Branchsettlementofficer();
      l_settlementofficer.branchsettlementofficerid = 0;
      l_settlementofficer.companyid = 0;
      l_settlementofficer.branchid = 0;
      l_settlementofficer.settlementofficerid = event.value[Index];
      l_SettlementOfficers.push(l_settlementofficer);
    });
    this._SelectedSettlementofficers = l_SettlementOfficers;
  }

  onSelectStockAllocationBranch(event) {
    this._Selectedbranchallocation = [];
    Object.keys(event.value).map((Index) => {
      let data = {
        companyid: 0,
        branchid: 0,
        frombranchid: event.value[Index],
      };
      this._Selectedbranchallocation.push(data);
    });
  }

  onRowSupplierAddressselect(Account: Branchaccountdetail, rowIndex: number) {
    if (this._action == 'view') {
      return;
    }
    this._Branchaccountdetailform.setValue(Account);
    this.accountRowIndex = rowIndex;
  }
  Removeselectedchecked(_BranchAccount: Branchaccountdetail, rowIndex: number) {
    if (this._BranchAccounts && this._BranchAccounts.length) {
      for (let i = 0; i < this._BranchAccounts.length; i++) {
        if (i === rowIndex) continue;

        this._BranchAccounts[i].isdefault = false;
      }
    }
    this._BranchAccounts[rowIndex].isdefault = _BranchAccount.isdefault;
    //_SupplierAddress.isdfaultaddress = true;
  }
  Clear(event) {
    this._branchform.reset();
    this._branchproductdetailform.reset();
    this._Branchaccountdetailform.reset();
  }
  // Document Upload
  showdocumentDialog(document: FileUpload, position1: string) {
    if (this._LastAddedDocument) {
      this.branchlogo = this._DomSanitizationService.bypassSecurityTrustUrl(
        URL.createObjectURL(this._LastAddedDocument)
      );
      this.imageselect = this._LastAddedDocument.name;
    }
    if (this.branchlogo == null) {
      return;
    }
    this.position1 = position1;
    this.displaydocumentpopup = true;
  }
  OpenEmployeeImage(event) {
    if (this.LogoImage != null) {
      window.open(this.LogoImage, '_blank');
      // window.open(this._Url + "/upload/Employee" + this.imageselect, "_blank");
    } else {
      this._CustomExceptionService.handleWarning('Please select image');
    }
  }
  onImageClear() {
    this._Iscreatefileupload = true;
    this._Isviewfileupload = false;
    this.LogoImage = null;
    // this._LastAddedImage = null;
  }
  //Document Select:
  onDocumentSelect(event, document) {
    this._LastAddedDocument = event.currentFiles[0];
    this._branchform
      .get('attachmentname')
      .setValue(this._LastAddedDocument.name);
    document.clear();
  }

  //Document Clear:
  onDocumentClear() {
    this._branchform.get('attachmenturl').setValue(undefined);
    this._branchform.get('attachmentname').setValue(undefined);
    this.branchlogo = null;
    this._LastAddedDocument = null;
  }
  showPopupDialog(position1: string) {
    this.position1 = position1;
    this.displaydocumentpopup = false;
  }
  onSave() {
    this._submitted = true;

    // 28. if all the data is valid allow to save the record in the database
    if (this._branchform.valid) {
      //if (this._Branchaccountdetailform.valid) {
      this.fillFunctionRoleMap();
      this.fillBranchBrandMap();
      this._Branchaccountdetailform
        .get('branchid')
        .setValue(this._branchform.get('branchid').value);
      this._Branch = this._branchform.value;

      // this._Branchaccountdetail = this._Branchaccountdetailform.value;
      if (!this._BranchAccounts || this._BranchAccounts.length == 0) {
        this._CustomExceptionService.handleError(
          usererrors.BranchAccountdetails_Tab
        );
        this.selectedtab = 2;
        return;
      }

      if (
        this._BranchAccounts &&
        this._BranchAccounts.length &&
        !this._BranchAccounts.some((s) => s.isdefault == true)
      ) {
        this._CustomExceptionService.handleError(
          usererrors.BranchAccountdetails_01
        );
        this.selectedtab = 2;
        return;
      }

      for (var i = 0; i < this.selectedrows.length; i++) {
        if (
          this.selectedrows[i].minimumlevel == 0 ||
          this.selectedrows[i].maximumlevel == 0 ||
          this.selectedrows[i].reorderlevel == 0
        ) {
          this._CustomExceptionService.handleError(
            'Please enter Minimum Level, Maximum Level and reorder level'
          );
          return;
        }
      }
      if (this._action == 'create') {
        this._Branch.createdon = new Date();
        this._Branch.companyid = 0;
        this._Branch.createdby = sessionStorage['userid'];

        console.log('createlogo', this._CropImage);

        if (this._CropImage == null || this._CropImage == undefined) {
          this._CustomExceptionService.handleError(
            'Please Select the Branch Logo'
          );
          return;
        }
        this._IsProgressSpinner = true;
        this._BranchFORM = this.formconverstion(
          this._Branch,
          this._BranchAccounts,
          this._Branchproductdetail,
          this._SelectedSettlementofficers,
          this._CropImage,
          this.selectedCategory,
          this.SelectedSaveBrandSDetails,
          this._Selectedbranchallocation,
          this._selectedsubBranches,
          this._branchwisecurrencylimit
        );

        //this.onEd();
        this._BranchService.create(this._BranchFORM).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              this._CustomExceptionService.handleSuccess(
                usererrors.Saved_Success_00
              );
              this._IsProgressSpinner = false;
              this.reset(null);
              this._states = null;
              this._districts = null;
            } else {
              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );
              this._IsProgressSpinner = false;
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
      } else if (this._action == 'edit') {
        this._Branch.modifiedon = new Date();
        this._Branch.modifiedby = sessionStorage['userid'];
        this._IsProgressSpinner = true;
        this._BranchFORM = this.formconverstion(
          this._Branch,
          this._BranchAccounts,
          this._Branchproductdetail,
          this._SelectedSettlementofficers,
          this._CropImage,
          this.selectedCategory,
          this.SelectedSaveBrandSDetails,
          this._Selectedbranchallocation,
          this._selectedsubBranches,
          this._branchwisecurrencylimit,
        );
        //this.onEd();
        this._BranchService.edit(this._BranchFORM).subscribe(
          (result) => {
            const resutJSON = JSON.parse(JSON.stringify(result));
            if (
              resutJSON.tranStatus.result == true &&
              (resutJSON != null || resutJSON != undefined)
            ) {
              // this.isDisabledsave = true;
              this._CustomExceptionService.handleSuccess(
                usererrors.Update_Success_04
              );
            } else {
              this._CustomExceptionService.handleError(
                resutJSON.tranStatus.lstErrorItem[0].message
              );
            }
            this._IsProgressSpinner = false;
          },
          (error) => {
            this._CustomExceptionService.handleError(error);
            this._IsProgressSpinner = false;
          }
        );
        this.goBack(event);
      }
    } else {
      this._CustomExceptionService.handleError(usererrors.BranchDetails_Tab);
      this.selectedtab = 0;
    }
  }
  // this._BranchOBJ = { "Branch": this._Branch, "Branchaccountdetail": this._Branchaccountdetail, "Branchproductdetail": this._Branchproductdetail };
  formconverstion(
    p_Branch: Branch,
    p_BranchAccounts: Branchaccountdetail[],
    p_branchproductdetail: Branchproductdetail[],
    p_settlementofficers: Branchsettlementofficer[],
    _CropImage: File,
    selectedCategory: any,
    p_branchbranddetails: Branchbranddetail[],
    stockallocationdetails: any[],
    subbranchdetails: Subbranchdetail[],
    branchwisecurrencylimits: Branchwisecurrencylimit[]
  ) {
    let time = new Date();

    const formData: FormData = new FormData();
    //TimeStamp Add:
    if (_CropImage != undefined) {
      var file = base64ToFile(this.croppedImage);
      var NewFileName = time.getTime() + '_' + _CropImage.name;
      p_Branch.attachmenturl = NewFileName;
      p_Branch.attachmentname = NewFileName;
      formData.append('files', _CropImage, NewFileName);
      //}
    }
    this._BranchOBJ = {
      Branch: p_Branch,
      Branchaccountdetail: p_BranchAccounts,
      Branchproductdetail: p_branchproductdetail,
      Branchsettlementofficer: p_settlementofficers || [],
      Productcategory: selectedCategory || [],
      BranchBranddetail: p_branchbranddetails,
      BranchStockAllocationdetail: stockallocationdetails || [],
      Subbranchdetail: subbranchdetails || [],
      Branchwisecurrencylimit: branchwisecurrencylimits || []

    };
    var Branch = JSON.stringify(this._BranchOBJ);
    formData.append('strBranch', Branch);
    return formData;
  }

  // 13.on selecting state name , corresponding city must be loaded In the city drop down from city master
  getStates(event) {
    var selectedCountry = event.value;

    this._BranchService.getStates(selectedCountry).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._states = resultJSON.states;
    });
  }
  getDistrict(event) {
    var selectedState = event.value;

    this._BranchService.getDistrict(selectedState).subscribe((result) => {
      const resultJSON = JSON.parse(JSON.stringify(result));

      this._districts = resultJSON.districts;
    });
  }
  reset(event) {
    // 32.clear all the fields which the data has entered
    this._submitted = false;
    this._branchform.reset();
    this._Branchaccountdetailform.reset();
    this.InitializeForm();
    this.onDocumentClear();
    this.selectedrows = [];
    this._selectedSettlementIds = [];
  }
  onRowSelect(event) { }
  onRowUnselect(event) { }
  fillFunctionRoleMap() {
    var selectedproducts = this.selectedrows;
    var Branchproductdetails: Branchproductdetail[] = [];
    var productDetails: Branchproductdetail;
    var branchId: any = this._branchform.get('branchid').value;
    var recordstatus = this._branchproductdetailform.get('recordstatus').value;
    if (recordstatus == undefined) {
      this._branchproductdetailform.controls['recordstatus'].setValue(
        'MSC00001'
      );
    }
    if (this.selectedrows != undefined) {
      (this._BranchProducts || []).forEach((brElement) => {
        let selectedProduct = (this.selectedrows || []).find(
          (f) => f.productid == brElement.productid
        );
        if (selectedProduct) {
          productDetails = new Branchproductdetail();
          productDetails.branchid = branchId;
          productDetails.branchproductdetailid =
            brElement.branchproductdetailid;
          productDetails.productid = brElement.productid;
          productDetails.productcategoryid = brElement.productcategoryid;
          productDetails.producttypeid = brElement.producttypeid;
          productDetails.minimumlevel = brElement.minimumlevel;
          productDetails.maximumlevel = brElement.maximumlevel;
          productDetails.reorderlevel = brElement.reorderlevel;
          productDetails.recordstatus = recordstatus;
          productDetails.createdon = new Date();
          productDetails.modifiedon = new Date();
          productDetails.createdby = sessionStorage['userid'];
          productDetails.modifiedby = sessionStorage['userid'];
          Branchproductdetails.push(productDetails);
        }
      });
      this._Branchproductdetail = Branchproductdetails;
      this.rowsempty = false;
    }
  }
  fillBranchBrandMap() {
    var Branchbranddetails: Branchbranddetail[] = [];
    var selectedBranches = this._selectedsubBranches;

    var branchId: any = this._branchform.get('branchid').value;
    (this.selectedbrandrows || []).forEach((brElement) => {
      var branddetails: Branchbranddetail = new Branchbranddetail();
      branddetails.branchid = branchId;
      branddetails.branchbranddetailsid = 0;
      branddetails.companyid = 0;
      branddetails.brandid = brElement.brandid;
      Branchbranddetails.push(branddetails);
    });
    this.SelectedSaveBrandSDetails = Branchbranddetails;
  }
  OnChangeMaximum(branchproduct: Branchproductdetail) {
    // var MaximumLevel = this._TempProducts.filter(f=> f.productid ==branchproduct.productid )[0].maximumlevel;
    // for (var i = 0; i < this.selectedrows.length; i++) {
    // if(MaximumLevel<branchproduct.maximumlevel){
    //   this._CustomExceptionService.handleWarning("list ok");
    // }
    // }
    var Maximumlevel: number;
    Maximumlevel = parseInt('' + branchproduct.maximumlevel);
    var MinimumLevel: number;
    MinimumLevel = parseInt('' + branchproduct.minimumlevel);
    if (MinimumLevel > Maximumlevel) {
      this._CustomExceptionService.handleWarning(
        'Maximum level should be greater than Minimum level'
      );
      branchproduct.maximumlevel = 0;
      return;
    }
  }
  OnChangeMinimum(branchproduct: Branchproductdetail) {
    var Maximumlevel: number;
    Maximumlevel = parseInt('' + branchproduct.maximumlevel);
    var MinimumLevel: number;
    MinimumLevel = parseInt('' + branchproduct.minimumlevel);
    if (MinimumLevel > Maximumlevel) {
      this._CustomExceptionService.handleWarning(
        'Maximum level should be greater than Minimum level'
      );
      //branchproduct.minimumlevel = 0;
      return;
    }
  }
  OnChangeReorder(branchproduct: Branchproductdetail) {
    var Reorerlevel: number;
    Reorerlevel = branchproduct.reorderlevel;
    var MinimumLevel: number;
    var Maximumlevel: number;

    MinimumLevel = parseInt('' + branchproduct.minimumlevel);
    Maximumlevel = parseInt('' + branchproduct.maximumlevel);
    if (MinimumLevel > Reorerlevel || Maximumlevel < Reorerlevel) {
      this._CustomExceptionService.handleWarning(
        'Reorder level should in between  minimum level and maximum level'
      );
      branchproduct.reorderlevel = 0;
      return;
    }
  }

  goBack(event) {
    this._router.navigate(['/vBranch']);
  }
  showBasicDialog() {
    this.displayBasic = true;
  }
  onRowSelectPopup(event: any) {
    var row = event.data;
    this._Branchaccountdetailform.controls['bankcode'].setValue(row.bankcode);
    this._Branchaccountdetailform.controls['bankname'].setValue(row.bankname);
    this.displayBasic = false;
  }
  onEd() {
    //alert("test");
    ace.config.set('fontSize', '14px');
    ace.config.set(
      'basePath',
      'https://unpkg.com/ace-builds@1.4.12/src-noconflict'
    );
    const aceEditor = ace.edit(this.editor.nativeElement);
    aceEditor.getSession().setMode('ace/mode/json');
    var myObj = this._BranchOBJ;
    var myJSON = JSON.stringify(myObj);
    aceEditor.session.setValue(myJSON);
  }

  getProductCategory() {
    this._productcategory = [];
    this._productname = [];
    this._selectedproduct = [];
    this._selectedproductcategory = [];
    var selectedtype = this._selectedproducttype;

    if (selectedtype.length > 0) {
      this._ProductTypeObj = {
        Producttype: selectedtype || [],
        branchid: this._branchid || 0,
      };
      this._BranchService
        .getProductCategory(this._ProductTypeObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));

          // if (this._action == 'edit') {
          //   this._productcategory = resultJSON.branchproductcategories;
          // } else {
          this._productcategory = resultJSON.productcategories;
          this.getProduct();
          // }
        });
    } else {
      this._BranchProducts = [];
    }
  }
  //Product Category Multiselect:
  getProduct() {
    var selectedCategory = this._selectedproductcategory;
    this.selectedCategory = selectedCategory;
    if (selectedCategory.length > 0) {
      this._ProductCategoryObj = {
        Productcategory: selectedCategory || [],
        branchid: this._branchid || 0,
      };
      this._BranchService
        .getProduct(this._ProductCategoryObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));

          this._productname = resultJSON.productheaders;
          this._BranchProducts = resultJSON.branchpageloadviews;
          var selectedRows: Branchproductdetail[] = [];
          var l_suppliers: any = this._BranchProducts;
          var selectedProductObj = resultJSON.branchproductdetails;
          if (l_suppliers)
            Object.keys(l_suppliers).map((Index) => {
              if (selectedProductObj)
                var obj = selectedProductObj.find(
                  (obj) => obj.productid == l_suppliers[Index].productid
                );
              if (obj) {
                l_suppliers[Index].reorderlevel = obj.reorderlevel;
                l_suppliers[Index].minimumlevel = obj.minimumlevel;
                l_suppliers[Index].maximumlevel = obj.maximumlevel;
                selectedRows.push(obj);
              }
            });
          if (this._action == 'edit') {
            this.selectedrows = selectedRows || [];
          }
        });
    } else {
      this._BranchProducts = [];
    }
  }
  getProductdetails() {
    this._brandlist = [];
    this._selectedbrand = [];
    var selectedproduct = this._selectedproduct;
    this.brandid = null;
    if (selectedproduct.length > 0) {
      this._ProductObj = {
        Product: selectedproduct || [],
        branchid: this._branchid || 0,
      };
      this._BranchService
        .getProductdetails(this._ProductObj)
        .subscribe((result) => {
          const resultJSON = JSON.parse(JSON.stringify(result));
          console.log('bh', resultJSON);
          let productbrand = resultJSON.productbrandlist;
          let uniqueChars = [...new Set(productbrand)];
          uniqueChars = productbrand.filter(
            (test, index, array) =>
              index ===
              array.findIndex((findTest) => findTest.brandid === test.brandid)
          );
          this._brandlist = uniqueChars;
          this._BranchProducts = resultJSON.branchpageloadviews;
          this._TempBranchProducts = resultJSON.branchpageloadviews;
        });
    } else {
      this.getProduct();
    }
  }
  getProductbrand() {
    var selectedbrand = this._selectedbrand || [];
    this._BranchProductsList = this._TempBranchProducts.filter((f) =>
      selectedbrand.includes(f.brandid)
    );
    if (this._BranchProductsList.length) {
      this._BranchProducts = this._BranchProductsList;
    } else {
      this._BranchProducts = this._TempBranchProducts;
    }
  }
  HotKeyIntegration() {
    this._hotkeysService.reset();
    if (this._action != 'view') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+s',
          (event: KeyboardEvent): boolean => {
            this.onSave();
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    if (this._action == 'create') {
      this._hotkeysService.add(
        new Hotkey(
          'ctrl+alt+c',
          (event: KeyboardEvent): boolean => {
            this.reset(null);
            return false; // Prevent bubbling
          },
          ['INPUT', 'SELECT', 'TEXTAREA']
        )
      );
    }
    this._hotkeysService.add(
      new Hotkey(
        'ctrl+left',
        (event: KeyboardEvent): boolean => {
          this.goBack(null);
          return false; // Prevent bubbling
        },
        ['INPUT', 'SELECT', 'TEXTAREA']
      )
    );
  }
  OnChangeofBanches(event) {
    this._selectedsubBranches = event.value;
  }
  onSelectsubBranch(event) {
    this._selectedsubBranches = [];
    Object.keys(event.value).map((Index) => {
      let data = {
        companyid: 0,
        branchid: 0,
        subbranchid: event.value[Index],
      };
      this._selectedsubBranches.push(data);
    });
  }
  SubbranchLoad() {
    this._subbranches = this._tempsubbranches.filter(
      (f) => (f.branchid != this._updatebranchid)
    );
  }
  onchanglimit(row: any) {
    for (var i = 0; i < this._branchwisecurrencylimit.length; i++) {
      var exchangeRate = this._tempcurrencies.find(f => f.currencyid == this._branchwisecurrencylimit[i].currencyid).exchangerate;
      if (row.currencyid == 1) {
        if (this._branchwisecurrencylimit[i].currencyid != 1) {
          this._branchwisecurrencylimit[i].currencyvalue = row.currencyvalue * exchangeRate ?? 0;
          this._branchwisecurrencylimit[i].maximumlimit = parseFloat((this._branchwisecurrencylimit[i].currencyvalue / exchangeRate ?? 0).toFixed(2));
        }
        else {
          this._branchwisecurrencylimit[i].maximumlimit = row.currencyvalue;
        }
      }
      else {
        this._branchwisecurrencylimit[i].maximumlimit = parseFloat((this._branchwisecurrencylimit[i].currencyvalue / exchangeRate ?? 0).toFixed(2));
      }
    }
  }
}
