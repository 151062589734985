<div class="page-container ">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Laybuy Report</h3>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <div class="input-container">
                    <form [formGroup]="_POoutstandingform">
                        <table class="normal-table">
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="fromdate" [monthNavigator]="true"
                                            [yearNavigator]="true" yearRange="1980:2090" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="fromdate">From Date</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-calendar [showIcon]="true" formControlName="todate" [monthNavigator]="true"
                                            [yearNavigator]="true" yearRange="1980:2090" [minDate]="mindate" [maxDate]="maxdate" dateFormat="dd/mm/yy">
                                        </p-calendar>
                                        <label for="todate">To Date</label>
                                    </span>
                                </td>
                                <td>
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_Rightslist" optionLabel="metasubdescription"
                                            optionValue="metasubcode" formControlName="rights" [filter]="true" (onChange)="onchangecustomertype()"
                                            filterBy="metasubdescription">
                                        </p-dropdown>
                                        <label for="userid">Rights</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_customertypelist"
                                            optionValue="metasubcode" optionLabel="metasubdescription"
                                            (onChange)="onchangecustomertype();GetCategory($event)" formControlName="customertype"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Customer Type</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_categorylist"
                                            optionValue="categoryid" optionLabel="categoryname" (onChange)="onchangecustomertype();GetSubcategory($event)"
                                            formControlName="categoryid"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Customer Category</label>
                                    </span>
                                </td> 
                            </tr>
                            <tr>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_subcategorylist"
                                            optionValue="subcategoryid" optionLabel="subcategoryname" (onChange)="onchangecustomertype()"
                                            formControlName="subcategoryid"
                                            [filter]="true" filterBy="customertype">
                                        </p-dropdown>
                                        <label for="customertype">Customer SubCategory</label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="false" [options]="_customerlist" optionLabel="customername"
                                            optionValue="customerid" [(ngModel)]="customerid"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel="true"
                                            [filter]="true" filterBy="customername" [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label>Customer </label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-multiSelect [filter]="false" [options]="_branchlist" optionLabel="branchname"
                                            optionValue="branchid" [(ngModel)]="branchid"
                                            [ngModelOptions]="{standalone: true}" displaySelectedLabel="true"
                                            [filter]="true" filterBy="branchname" [virtualScroll]="true" itemSize="30">
                                        </p-multiSelect>
                                        <label>Branch </label>
                                    </span>
                                </td>
                                <td style="width: 20%;">
                                    <span class="p-float-label">
                                        <p-dropdown [autoDisplayFirst]="false" [options]="_laybuystslist" name="laybuystatusdesc"
                                    [(ngModel)]="laybuystatus" [ngModelOptions]="{standalone: true}" optionLabel="laybuystatusdesc" optionValue="laybuystatusid">
                                </p-dropdown>
                                        <label>Laybuy Status </label>
                                    </span>
                                </td>
                            </tr>


                            <tr>
                                <td>
                                    <button pButton pRipple label="Search" icon="las la-search" (click)="GenerateReport()" class="p-button-sm p-button-success"></button>
                                    <button pButton pRipple label="Clear" icon="pi pi-times" (click)="clear()" class="p-button-danger"></button>
                                </td>
                            </tr>
                        </table>
                    </form>
                </div>
                <div class="card">
                    <p-toolbar styleClass="p-mb-4">
                        <ng-template pTemplate="left">
                            <span class="p-input-icon-left">
                                <i class="pi pi-search"></i>
                                <input pInputText type="text" (input)="dt.filterGlobal($event.target.value, 'contains')"
                                    placeholder="Search..." />
                            </span>
                        </ng-template>
                        <ng-template pTemplate="right">
                            <button type="button" pButton pRipple icon="pi pi-file-o" title="Export CSV" (click)="exportExcel()" class="p-mr-2" pTooltip="CSV" tooltipPosition="bottom"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-excel" title="Export Excel" class="p-button-success p-mr-2" pTooltip="XLS" tooltipPosition="bottom" (click)="exportExcel()"></button>
                            <button type="button" pButton pRipple icon="pi pi-file-pdf" title="Export PDF" class="p-button-warning p-mr-2" pTooltip="PDF" tooltipPosition="bottom" (click)="ExportToPdf()"></button>
                        </ng-template>
                    </p-toolbar>
                    <p-table #dt [value]="_Laybuyreports" [rows]="10" [globalFilterFields]="['LAYBUYCODE','SALESINVOICENO','CUSTOMERNAME','TOTALAMOUNT','CURRENCYNAME','PAIDAMOUNT','BALANCEAMOUNT','LAYBUYSTATUS']" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" [rowHover]="true" dataKey="LAYBUYCODE" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true">
                        <ng-template pTemplate="header">
                            <tr>
                                <th class="text-center" style="width: 60px;" pSortableColumn="sno">S.No
                                    <p-sortIcon field="sno"></p-sortIcon>
                                </th>

                                <th pSortableColumn="LAYBUYCODE">Laybuy No
                                    <p-sortIcon field="LAYBUYCODE"></p-sortIcon>
                                </th>
                                <th pSortableColumn="SALESINVOICENO">Sales Invoice No
                                    <p-sortIcon field="SALESINVOICENO"></p-sortIcon>
                                </th>
                                <th pSortableColumn="STARTDATE">Start Date
                                    <p-sortIcon field="STARTDATE"></p-sortIcon>
                                </th>
                                <th pSortableColumn="ENDDATE">End Date
                                    <p-sortIcon field="ENDDATE"></p-sortIcon>
                                </th>
                                
                                <th pSortableColumn="CUSTOMERNAME">Customer Name
                                    <p-sortIcon field="CUSTOMERNAME">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="SALESINVOICEDATE">Sales Invoice Date
                                    <p-sortIcon field="SALESINVOICEDATE"></p-sortIcon>
                                </th>
                                <th pSortableColumn="CURRENCYNAME">Currency
                                    <p-sortIcon field="CURRENCYNAME">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="TOTALAMOUNT">Total Amount
                                    <p-sortIcon field="TOTALAMOUNT">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="PAIDAMOUNT">Paid Amount
                                    <p-sortIcon field="PAIDAMOUNT">
                                    </p-sortIcon>
                                </th>
                                <th pSortableColumn="BALANCEAMOUNT">Balance Amount
                                    <p-sortIcon field="BALANCEAMOUNT"> </p-sortIcon>
                                </th>
                                <th pSortableColumn="LAYBUYSTATUS">Laybuy Status
                                    <p-sortIcon field="LAYBUYSTATUS"> </p-sortIcon>
                                </th>
                            
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-_Laybuyreport let-i="rowIndex">
                            <tr>
                                <td class="text-center" style="width: 60px;">
                                    {{i+1}}
                                </td>
                                <td>
                                    <a href="javascript:void(0);" (click)="GetLaybuyDetails(_Laybuyreport)">
                                    {{_Laybuyreport.LAYBUYCODE}}</a>
                                </td>
                                <td>
                                    {{_Laybuyreport.SALESINVOICENO}}
                                </td>
                                <td>
                                    {{_Laybuyreport.STARTDATE |date:'dd/MM/yyyy' }}
                                </td>
                                <td>
                                    {{_Laybuyreport.ENDDATE |date:'dd/MM/yyyy' }}
                                </td>
                                <td>
                                    {{_Laybuyreport.CUSTOMERNAME }}
                                </td>
                                <td>
                                    {{_Laybuyreport.SALESINVOICEDATE |date:'dd/MM/yyyy' }}
                                </td>
                                <td>
                                    {{_Laybuyreport.CURRENCYNAME }}
                                </td>
                                <td>
                                    {{_Laybuyreport.TOTALAMOUNT }}
                                </td>

                                <td>
                                    {{_Laybuyreport.PAIDAMOUNT}}
                                </td>
                                <td>
                                    {{_Laybuyreport.BALANCEAMOUNT}}
                                </td>
                                <td>
                                    {{_Laybuyreport.LAYBUYSTATUS}}
                                </td>

                            </tr>
                        </ng-template>
                    </p-table>
                </div>
                <p-dialog header="Laybuy Product Wise" [(visible)]="_laybuywise" [modal]="true" [style]="{width: '800px',height: '500px'}" [baseZIndex]="10000">
                    <ng-template pTemplate="content">
                        <div class="popup-body">
                            <div class="card">
                                <p-table [value]="_Laybuydetails" selectionMode="single" [rowHover]="true" [rows]="10" [paginator]="true" [rowsPerPageOptions]="[10,25,50,100]" dataKey="LAYBUYCODE" [showCurrentPageReport]="true" [(selection)]="selectedProductRows" currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries" [showCurrentPageReport]="true"
                                >
                                    <ng-template pTemplate="header">
                                        <tr>
                                            <th class="text-center" style="width: 60px;">S.No

                                            </th>
                                            <th pSortableColumn="LAYBUYCODE">laybuy No
                                                <p-sortIcon field="LAYBUYCODE">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="PRODUCTTYPENAME">Product type
                                                <p-sortIcon field="PRODUCTTYPENAME">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="PRODUCTCATEGORYNAME">Product Category
                                                <p-sortIcon field="PRODUCTCATEGORYNAME">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="PRODUCTNAME">Product Name
                                                <p-sortIcon field="PRODUCTNAME">
                                                </p-sortIcon>
                                            </th>
                                            <th pSortableColumn="QUANTITY">Quantity
                                                <p-sortIcon field="QUANTITY">
                                                </p-sortIcon>
                                            </th>
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-rowData let-_Laybuydetail let-i="rowIndex">
                                        <tr>
                                            <td class="text-center" style="width: 60px;">
                                                {{i+1}}
                                            </td>
                                            <td>
                                                {{_Laybuydetail.LAYBUYCODE}}
                                            </td>
                                            <td>
                                                {{_Laybuydetail.PRODUCTTYPENAME}}
                                            </td>
                                            <td>
                                                {{_Laybuydetail.PRODUCTCATEGORYNAME}}
                                            </td>
                                            <td>
                                                {{_Laybuydetail.PRODUCTNAME}}
                                            </td>
                                            <td>
                                                {{_Laybuydetail.QUANTITY}}
                                            </td>
                                        </tr>
                                    </ng-template>
                                </p-table>
                            </div>
                        </div>
                    </ng-template>
                </p-dialog>
            </div>
        </div>
    </div>
</div>
<p-toast position="bottom-right"></p-toast>