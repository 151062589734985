import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Observable } from 'rxjs/internal/Observable';
import { CustomExceptionService } from 'src/app/core/errorHandlers/custom-exception.service';
import { Branch } from 'src/app/core/Models/Branch';
import { Currency } from 'src/app/core/Models/Currency';
import { Grndocumentdetail } from 'src/app/core/Models/Grndocumentdetail ';
import { Grnothercharge } from 'src/app/core/Models/Grnothercharge ';
import { Metadatum } from 'src/app/core/Models/metadatum';
import { Othercharge } from 'src/app/core/Models/Othercharges';
import { Tax } from 'src/app/core/Models/Tax';
import { GrnService } from 'src/app/core/Services/Inventory/grn.service';
import { vCurrencyNameSelect, vInwardNo, vInwardTypeSelect, vReceivedBranchSelect, vsuplierorbranch } from 'src/app/core/Validators/validation';
import { Grngetinwardproductdetailsview } from 'src/app/core/Views/Grngetinwardproductdetailsview ';
import { CommonUtilities } from 'src/assets/js/common-utilities';
import { Grn } from 'src/app/core/Models/Grn';
import { usererrors } from 'src/app/core/errors/usererrors';
import * as ace from "ace-builds";
import { Hotkey, HotkeysService } from 'angular2-hotkeys';
import { Router } from '@angular/router';
import { DatePipe } from '@angular/common';
import { Supplier } from 'src/app/core/Models/Supplier';
import { DomSanitizer, SafeUrl } from '@angular/platform-browser';
import { FileUpload } from 'primeng/fileupload';
import { Documentdescription } from 'src/app/core/Models/DocumentDescription';
import { KeyPress } from 'src/assets/js/KeyPress';
import { IAction } from 'src/app/core/Services/security/IActions';
import { AccessRightsService } from 'src/app/core/Services/security/access-rights.service';
import { ConfirmationService } from 'primeng/api';

@Component({
	selector: 'app-c-goods-receipt-note',
	templateUrl: './c-goods-receipt-note.component.html',
	styleUrls: ['./c-goods-receipt-note.component.css']
})
export class CGoodsReceiptNoteComponent implements OnInit {
	_currency: any[] = [];
	isDisablereset = false;
	_CurrenciesTemp: Currency[] = [];
	_GrnDetails: any[] = [];
	_GrnDetail: Grngetinwardproductdetailsview;
	_userid: any = sessionStorage["userid"];
	selectedProductDetailsRows: any;
	_receivedbranch: Branch[] = [];
	_Grn: Grn;
	isDisabledother = true;
	_OtherCharges: Grnothercharge[] = [];
	_grndocument: Grndocumentdetail;
	_grndocuments: Grndocumentdetail[] = [];
	_submitted = false;
	_inwardno: any;
	_supplier_branch: Supplier[] = [];
	isDisabledinwardtype = true;
	displayBasictax = false;
	isDisabledtaxcode = true;
	isDisabledreceivedbranch = true;
	selectedOtherChargesRows: any;
	_grnform: FormGroup;
	_grndetailform: FormGroup;
	_grndocumentform: FormGroup;
	_grntaxnameform: FormGroup;
	_OtherCharge: Othercharge[] = [];
	_GoodsreceiptOBJ: any;
	_selectedinwardno: number[] = [];
	isDisabledinwardno = true;
	isDisabledsupplierbranch = true;
	_isDisabledsendtoapproval = true;
	isDisabledsave = false;
	_inwardtype: Metadatum[][];
	isDisabledremoveproduct = true;
	isDisabledconsignmentproduct = false;
	_GRNno: string;
	_UserId: number;
	_InwardType: string;
	_taxdetails: Tax[] = [];
	_Descriptions: Othercharge[] = [];
	_TempDescriptions: Othercharge[] = [];
	_DocumentDescriptions: Documentdescription[];
	_action: string = "create";
	private _OtherBalance: number;
	private _grossamount: number;
	selectedFiles: FileList;
	currentFile: File;
	progress = 0;
	message = '';
	fileInfos: Observable<any>;
	_otherchargeamount: number;
	_GrnFORM: FormData;
	_LastAddedDocument: File;
	_selectedDocuments: File[] = [];
	position1: string;
	_validate: boolean;
	selectedrows: any;
	displaydocumentpopup: boolean;
	isDisabledadddocument = true;
	isDisabledcleardocument = true;
	isDisabledremove = true;
	_SelectedExchangerate: number;
	_grnid: number;
	_IsProgressSpinner: boolean = true;
	_Series: string;
	documentimage: SafeUrl;
	position: string;
	displaydocumentgridpopup: boolean = false;
	ISdisable: boolean = true
	InVoiceNo: string;
	Stockmodel: any = {};
	Ans: any = 0;
	_Issalesbased: boolean = true;
	isGrnApproved: boolean = false;
	_Action: IAction
	isdisabledbarcode = false;
	invoicebarcode: any
	isbarcode: boolean = false
	mindate: Date;
	maxdate: Date;
	roleid: number = sessionStorage["currentRole"];
	inwardtype: string;

	@ViewChild("editor", { static: true }) private editor: ElementRef<HTMLElement>;
	constructor(
		private fb: FormBuilder,
		private _router: Router,
		private utility: CommonUtilities,
		private _grnservice: GrnService,
		private _CustomExceptionService: CustomExceptionService,
		private _hotkeysService: HotkeysService,
		private _DomSanitizationService: DomSanitizer,
		public keypress: KeyPress,
		private _AccessRightsService: AccessRightsService,
		private confirmationService: ConfirmationService

	) {
		this._GRNno = history.state?.grnno ? history.state?.grnno : 0;
		this._action = history.state.action == null ? 'create' : history.state.action;
		this.HotKeyIntegration();
	}
	InitializeGrnForm() {
		this._grnform = this.fb.group({
			companyid: [],
			branchid: [],
			grnid: [],
			grnno: [],
			grndate: [],
			inwardid: ['', vInwardNo],
			receivedbranch: ['', vReceivedBranchSelect],
			receivedby: [],
			currencyreferenceid: [],
			currencyid: ['', vCurrencyNameSelect],
			taxamount: [],
			taxid: [],
			taxpercentage: [],
			otherchargesamount: [],
			invoiceamount: [],
			amountinusd: [],
			status: [],
			statusremarks: [],
			accountingyear: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
			inwardtype: ['', vInwardTypeSelect],
			supplierorbranchid: ['', vsuplierorbranch],
			grossamount: [],
			rv: [],
			grndetails: [],
			grndocumentdetails: [],
			grnothercharges: [],
			isdisabled: [false],
			transactionincompleted: [1]
		});
	}
	InitializeGrndetailForm() {
		this._grndetailform = this.fb.group({
			grndetailid: [],
			grnid: [],
			referenceno: [],
			productid: [],
			variantid: [],
			invoiceunitprice: [],
			invoicequantity: [],
			receivequantity: [],
			damagequantity: [],
			remainingquantity: [],
			uomcode: [],
			productamount: [],
			discountpercentage: [],
			discountamount: [],
			taxid: [],
			taxpercentage: [],
			taxamount: [],
			expirydate: [],
			remarks: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
		});
	}
	InitializeGrndocumentForm() {
		this._grndocumentform = this.fb.group({
			grndocumentdetailid: [],
			grnid: [],
			documentname: [],
			documentdescription: [],
			attachmenturl: [],
			attachmentname: [],
			createdby: [],
			createdon: [],
			modifiedby: [],
			modifiedon: [],
		});
	}
	Initializetaxnameform() {
		this._grntaxnameform = this.fb.group({
			taxname: []
		});
		this._grnform.controls['receivedbranch'].setValue(parseInt(sessionStorage["BranchID"]));

		if (sessionStorage['Environmentenddate'] == "null") {
			this._grnform.get("grndate").setValue(new Date);
		}
		else {
			let date = new Date(sessionStorage['Environmentenddate'])
			this._grnform.get("grndate").setValue(date);
		}

		// this._grnform.controls['grndate'].setValue(new Date());
	}
	ngOnInit(): void {
		this._Action = this._AccessRightsService.getAccessRights(49);
		this.utility.pageLoadScript();
		this.mindate = new Date(sessionStorage['Environmentstartdate']);
		this.maxdate = new Date(sessionStorage['Environmentenddate']);
		this.InitializeGrnForm();
		this.InitializeGrndetailForm();
		this.InitializeGrndocumentForm();
		this.Initializetaxnameform();
		this._UserId = sessionStorage["userid"];
		this._IsProgressSpinner = true;
		this._grnservice.PageOnLoad(this._GRNno).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._currency = resultJSON.currencies;
			this._taxdetails = resultJSON.taxes;
			this._Descriptions = resultJSON.othercharges
			this._TempDescriptions = resultJSON.othercharges
			this._receivedbranch = resultJSON.branches
			this._grnform.controls['receivedbranch'].setValue(parseInt(sessionStorage["BranchID"]));
			this._supplier_branch = resultJSON.grngetsupllierorbranchviews
			this._DocumentDescriptions = resultJSON.documentdescriptions;
			this._inwardtype = resultJSON.inwardTypes;
			this._IsProgressSpinner = false;
			var l_othercharge = new Grnothercharge();
			l_othercharge.grnid = 0;
			l_othercharge.otherchargesamount = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this._OtherCharges.push(l_othercharge);
			if (this._action == 'create') {
				// this._grnform.controls['grndate'].setValue(new Date());
				this.isDisabledsave = false;
				this.isDisablereset = false;
				this.isDisabledinwardtype = false;
				this.displayBasictax = false;
				this.isDisabledtaxcode = false;
				this.isDisabledreceivedbranch = false;
				this.isDisabledinwardno = false;
				this.isDisabledsupplierbranch = false;
				this.isDisabledadddocument = false;
				this.isDisabledcleardocument = false;
				this.isDisabledother = false;
				this.isDisabledremove = false;
			}
			if (this._action == 'edit' || this._action == 'view') {
				const updateJSON = JSON.parse(JSON.stringify(result));
				console.log("grndetailview", updateJSON.grndetailsviews);
				updateJSON.grn.grndate = new Date(updateJSON.grn.grndate);
				this._grnform.setValue(updateJSON.grn);
				this._grnid = updateJSON.grn.grnid;
				this._inwardno = updateJSON.purchaseinwardsNos;
				this._taxdetails = updateJSON.taxes;
				this._GrnDetails = updateJSON.grndetailsviews;
				let grnstatus = this._grnform.get("status").value
				this.inwardtype = this._grnform.get('inwardtype').value;
				if (grnstatus == "MSC00047") {
					this.isGrnApproved = true
				} else {
					this.isGrnApproved = false
				}
				for (var i = 0; i < this._GrnDetails.length; i++) {
					this._GrnDetails[i].expirydate = new Date(this._GrnDetails[i].expirydate);
				}
				this._grndocuments = updateJSON.grndocumentdetails;
				this._selectedDocuments = updateJSON.grndocumentdetails;
				this._OtherCharges = updateJSON.grnnothercharges;
				if (updateJSON.purchaseinwardinvoicedetails.length > 0) {
					this.InVoiceNo = updateJSON.purchaseinwardinvoicedetails[0].invoiceno || "";
				} else {
					this.InVoiceNo = "";
				}
			}
			if (this._action == 'view') {
				this.isDisabledsave = true;
				this.isDisablereset = true;
				this.isDisabledremoveproduct = true;
				this.isDisabledconsignmentproduct = true;
				this.isDisabledcleardocument = true;
				this.isDisabledremove = true;
				this.isDisabledother = true;
				this._grndetailform.disable();
				this._grnform.disable();
				this.isDisabledadddocument = true;
				this.isDisabledother = true;
			}
			if (this._action == 'edit') {
				this.isDisabledsave = false;
				this.isDisablereset = false;
				this._isDisabledsendtoapproval = true;
				this.isDisabledadddocument = false;
				this.isDisabledcleardocument = false;
				this.isDisabledremoveproduct = true;
				this.isDisabledconsignmentproduct = true;
				this.isDisablereset = true;
				this.isDisabledother = false;
				this.isDisabledremove = false;
			}
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	RemoveProductDetail(pdetail: Grngetinwardproductdetailsview) {
		if (this._GrnDetails.length >= 1) {
			var index = this._GrnDetails.indexOf(pdetail);
			this._GrnDetails.splice(index, 1)
		}
	}
	closePopupGridDialog(position: string) {
		this.position = position;
		this.displaydocumentgridpopup = false;
	}
	onDocumentSelect(event, document) {
		this._LastAddedDocument = event.currentFiles[0];
		document.clear();
	}
	onDocumentClear() {
		this.documentimage = null;
		this._LastAddedDocument = null;
	}
	AddOtherCharges(event) {
		let otherChargesExists = (this._OtherCharges.filter(s => s.otherchargesid > 0).length == this._OtherCharges.length) && this._OtherCharges.some(s => s.otherchargesid > 0);
		if (!otherChargesExists)
			return;
		var l_othercharge = new Grnothercharge();
		l_othercharge.grnnotherchargesdetailid = 0;
		l_othercharge.otherchargesamount = 0;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		this._OtherCharges.push(l_othercharge);
	}
	RemoveOtherCharges(pOthercharges: Grnothercharge) {
		var index = this._OtherCharges.indexOf(pOthercharges);
		this._OtherCharges.splice(index, 1)
		this.calculateamountdetails();
		if (this._OtherCharges.length <= 0) {
			var l_othercharge = new Grnothercharge();
			l_othercharge.grnid = 0;
			l_othercharge.otherchargesamount = 0;
			l_othercharge.createdby = sessionStorage["userid"];
			l_othercharge.modifiedby = sessionStorage["userid"];
			l_othercharge.createdon = new Date();
			l_othercharge.modifiedon = new Date();
			this._OtherCharges.push(l_othercharge);
		}
	}
	OnchangeOtherCharge(pOtherCharges: Grnothercharge, event: any) {
		let isAlreadyExists = this._OtherCharges.filter(s => s.otherchargeid == event.value).length > 1;
		if (isAlreadyExists) {
			this._CustomExceptionService.handleWarning("Other Charges Already Exists!");
			var index = this._OtherCharges.indexOf(pOtherCharges);
			this._OtherCharges.splice(index, 1)
			return;
		}
		this._otherchargeamount = (this._TempDescriptions || []).filter(f => f.otherchargeid == event.value)[0].otherchargeamount;
		pOtherCharges.otherchargesamount = this._otherchargeamount;
		pOtherCharges.otherchargesid = event.value;
		this.calculateamountdetails();
	}
	OnChangeotherCharge(row: any, event) {
		let OtherChargesAmount = row.otherchargesamount || 0;
		parseFloat(OtherChargesAmount)
		this._grnform.controls['otherchargesamount'].setValue(OtherChargesAmount.toFixed(3));
		this.calculateamountdetails();
	}
	onAdddocument(event) {
		var _attachmentname = this._LastAddedDocument.name;
		var documentname = this._grndocumentform.get("documentname").value;
		if (!documentname) {
			this._CustomExceptionService.handleWarning("Please select document name");
			return
		}
		if (this._grndocuments.length > 0) {
			for (var i = 0; i < this._grndocuments.length; i++) {
				if (_attachmentname == this._grndocuments[i].attachmentname) {
					this._CustomExceptionService.handleWarning("Document Already Uploaded!");
					return
				}
			}
		}
		var documentdetail = new Grndocumentdetail();
		documentdetail.attachmentname = this._grndocumentform.get("attachmentname").value;
		documentdetail.attachmenturl = URL.createObjectURL(this._LastAddedDocument);
		this.documentimage = this._DomSanitizationService.bypassSecurityTrustUrl(URL.createObjectURL(this._LastAddedDocument));
		documentdetail.attachmentname = this._LastAddedDocument.name;
		documentdetail.grndocumentdetailid = 0;
		documentdetail.documentname = this._grndocumentform.get("documentname").value;
		documentdetail.documentdescription = this._grndocumentform.get("documentdescription").value;
		documentdetail.grnid = 0
		documentdetail.createdby = sessionStorage["userid"];
		documentdetail.modifiedby = this._grndocumentform.get("modifiedby").value;
		documentdetail.modifiedon = this._grndocumentform.get("modifiedon").value;
		documentdetail.createdon = new Date();
		this._grndocuments.push(documentdetail);
		this._selectedDocuments.push(this._LastAddedDocument);
		this._grndocumentform.reset();
	}
	RemoveDocumentDetail(p_proofDetail: Grndocumentdetail) {
		var index = this._grndocuments.indexOf(p_proofDetail);
		this._grndocuments.splice(index, 1)
	}
	onchangereceivedquantity(row: any, event) {
		var selectedindex = this._GrnDetails.findIndex(obj => obj == row);
		var stocktransferamount = 0
		var a = event.value;
		var b = event.key;
		row.remainingquantity = 0;
		if (row.invoicequantity > row.receivequantity) {
			row.remainingquantity = row.invoicequantity - row.receivequantity;
			if (row.uomcode == 'MSC00039') {
				row.remainingquantity = row.remainingquantity.toFixed(3);
			}
		}
		var actualquantity = 0;
		actualquantity = row.receivequantity;
		if (actualquantity > row.invoicequantity) {
			this._CustomExceptionService.handleWarning("Received Quantity and Damaged Quantity should not greater than PO Quanitity!");
			row.remainingquantity = 0;
			row.receivequantity = 0;
			return;
		}
		if (row.damagequantity > row.receivequantity) {
			this._CustomExceptionService.handleWarning("Damaged Quantity should not greater than Received Quanitity!");
			row.damagequantity = 0;
			row.remainingquantity = 0;
		}
		row.productamount = row.receivequantity * row.invoiceunitprice || 0;
		if (row.receivequantity == 0) {
			this._CustomExceptionService.handleWarning("Received Quanitity  is Empty!");
			row.damagequantity = 0;
			row.receivequantity = 0;
		}
		var taxid = row.taxcode;
		if (row.taxcode != 0 && row.taxcode != null) {
			var TaxPercentage = (this._taxdetails || []).filter(f => f.taxcode == taxid)[0].taxpercentage;
			var Taxid = (this._taxdetails || []).filter(f => f.taxcode == taxid)[0].taxid;
			row.taxid = Taxid;
			row.taxpercentage = TaxPercentage;
			row.taxamount = row.productamount * TaxPercentage / 100;
			this._grnform.controls['taxamount'].setValue(this._GrnDetails[selectedindex].taxamount);
		}
		else {
			this._GrnDetails[selectedindex].taxamount = 0;
			this._GrnDetails[selectedindex].taxid = 0;
			this._GrnDetails[selectedindex].taxpercentage = 0;
			this._grnform.controls['taxamount'].setValue(0)
		}
		this.calculateamountdetails();
	}
	calculateamountdetails() {
		if (this._GrnDetails.length > 0) {
			var grossamount = 0;
			var taxamount = 0;
			var otherchargeamount = 0;
			var finalamount = 0;
			for (var i = 0; i < this._GrnDetails.length; i++) {
				if (this._grnform.get("inwardtype").value == "MSC00190" || this._grnform.get("inwardtype").value == "MSC00225") {
					this._GrnDetails[i].productamount = 0;
					this._GrnDetails[i].taxamount = 0;
					this._GrnDetails[i].taxpercentage = 0;
				}
				grossamount += this._GrnDetails[i].productamount;
				taxamount += this._GrnDetails[i].taxamount;
			}
			for (var i = 0; i < this._OtherCharges.length; i++) {
				if (this._OtherCharges[i].otherchargesamount != undefined || this._OtherCharges[i].otherchargesamount != null) {
					otherchargeamount += this._OtherCharges[i].otherchargesamount;
				}
			}
			var currencyid = this._grnform.get("currencyid").value;
			var usd = 1;
			var usdexchangerate = (this._currency || []).filter(f => f.currencyid == usd)[0].exchangerate;
			finalamount = parseFloat(grossamount + '') + parseFloat(otherchargeamount + '') + parseFloat(taxamount + '');
			if (currencyid != 0) {
				var ExchangeRate = (this._currency || []).filter(f => f.currencyid == currencyid)[0].exchangerate;
				this._SelectedExchangerate = ExchangeRate;
				var _SelectedExchangerate = usdexchangerate / this._SelectedExchangerate;
				var amountinusd = _SelectedExchangerate * finalamount;
				this._grnform.controls['invoiceamount'].setValue(amountinusd.toFixed(3));
				this._grnform.controls['amountinusd'].setValue(amountinusd.toFixed(3));
			}
			this._grnform.controls['grossamount'].setValue(grossamount.toFixed(3));
			this._grnform.controls['taxamount'].setValue(taxamount.toFixed(3));
			this._grnform.controls['otherchargesamount'].setValue(otherchargeamount.toFixed(3));
			this._grnform.controls['invoiceamount'].setValue(finalamount.toFixed(3));
		}
	}
	onchangeinwardtype(event) {
		this.InVoiceNo = "";
		var stocktransfer = event.value
		var stocktransferamount = 0
		if (stocktransfer == "MSC00190" || stocktransfer == "MSC00225") {
			this._grnform.controls['invoiceamount'].setValue(stocktransferamount);
		}
		if (stocktransfer == "MSC00277") {
			this._Issalesbased = false;
		} else {
			this._Issalesbased = true;
		}
		this._GrnDetails = [];
		this._grnform.controls["currencyid"].reset();
		this._grnform.controls["supplierorbranchid"].reset();
		var selectedinwardtype = event.value;
		this._IsProgressSpinner = true;
		var branchid = sessionStorage["currentbranchid"];
		var userid = sessionStorage["userid"];
		this._grnservice.onchangeinwardtype(selectedinwardtype, branchid, userid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			console.log(resultJSON.inwardNos, "invoice");

			this._inwardno = resultJSON.inwardNos;
			this._supplier_branch = resultJSON.grngetsupllierorbranchviews;
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	disablepo(event, objectModel) {
		if (this._grnform.get("taxtype").value == "MSC00200") {
			objectModel["isDisabledtaxpercentage"] = true;
		} else {
			objectModel["isDisabledtaxpercentage"] = false;
		}
	}
	GetCurrency(event) { }
	showBasicDialogtax() { this.displayBasictax = true; }
	onSelectinwardno(event) {
		var selectedinwardno = null;
		var barcode = null
		if (this.isbarcode == true) {
			selectedinwardno = 0;
			barcode = event.value;
		}
		else {
			selectedinwardno = event.value;
			barcode = 0;
		}

		var inwardType = this._grnform.controls['inwardtype'].value
		this._IsProgressSpinner = true;
		this._grnservice.onchangeinwardno(selectedinwardno, inwardType, parseInt(sessionStorage["BranchID"]), barcode).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			if (resultJSON.tranStatus.result == true && (resultJSON != null || resultJSON != undefined)) {
				this._GrnDetails = resultJSON.grngetinwardproductdetailsviews;
				if ((resultJSON.purchaseinwardinvoicedetails || []).length > 0) {
					this.InVoiceNo = resultJSON.purchaseinwardinvoicedetails[0].invoiceno || "";
				} else {
					this.InVoiceNo = "";
				}

				if (this.isbarcode == true) {
					var id = this._inwardno.filter(i => i.inwardnumber == barcode[0])
					if (id.length) {
						this._grnform.controls['inwardid'].setValue(id[0].inwardid);
					}

				}
				this._IsProgressSpinner = false;
				for (var i = 0; i < this._GrnDetails.length; i++) {
					const x = this._GrnDetails[i];
					if (x.taxamount > 0 || x.taxcode != null) {
						x.taxcode = "VAT"
					}
					if (inwardType == "MSC00277") {
						x.taxcode = "VAT";
						x.consignmentproduct = true;
						this.isDisabledconsignmentproduct = true;
					} else {
						x.expirydate = new Date(x.expirydate);
						x.consignmentproduct = false;
						x.damagequantity = 0;
						this.isDisabledconsignmentproduct = true;
					}
					if (inwardType == "MSC00190" || inwardType == "MSC00225") {
						x.oldlotno = x.lotnumber
					} else {
						x.oldlotno = 0;
					}
				}
				if (resultJSON.purchaseinward) {
					this._grnform.controls['supplierorbranchid'].setValue(resultJSON.purchaseinward.supplieridorbranchid);
				}

				if (inwardType == "MSC00189") {
					this._currency = resultJSON.currencyreference;
					this._grnform.controls['currencyid'].setValue(resultJSON.purchaseorder.currencyid);
					var currencyid = this._grnform.get("currencyid").value;
					var referenceid = this._currency.filter(f => f.currencyid == currencyid)[0].versionid;
					this._grnform.controls['currencyreferenceid'].setValue(referenceid);
					this._OtherCharges = resultJSON.grnotherchargesviews;
				}
				else {
					this._grnform.controls['currencyid'].setValue(1);
					var currencyid = this._grnform.get("currencyid").value;
					var referenceid = this._currency.filter(f => f.currencyid == currencyid)[0].currencyuniquerefid;
					this._grnform.controls['currencyreferenceid'].setValue(referenceid);
				}
				this._IsProgressSpinner = false;
				console.log(resultJSON, "supplier")
			}
			else { this._CustomExceptionService.handleError(resultJSON.tranStatus.lstErrorItem[0].message); }
			this._IsProgressSpinner = false;
			this.isbarcode = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.isbarcode = false; });
	}
	onSave() {
		if (this.isDisabledsave) return;
		this._submitted = true;
		if (this._grnform.valid) {
			var GRNDate = this._grnform.get("grndate").value;
			var datePipe = new DatePipe("en-US");
			var pGRNDate = datePipe.transform(GRNDate, 'yyyy-MM-dd');
			this._Grn = this._grnform.value;
			this._Grn.grndate = (new Date(pGRNDate));
			for (var i = 0; i < this._GrnDetails.length; i++) {
				if (this._GrnDetails[i].receivequantity == undefined || this._GrnDetails[i].receivequantity == null) {
					this._CustomExceptionService.handleError(usererrors.GRNReceivedQuantity);
					return;
				}
				if (this._GrnDetails[i].invoicequantity != (this._GrnDetails[i].receivequantity + this._GrnDetails[i].damagequantity + this._GrnDetails[i].remainingquantity)) {
					this.onchangereceivedquantity(this._GrnDetails[i], Event);
				}
				var inwardtype = this._grnform.get("inwardtype").value;
				if (inwardtype == "MSC00189" || inwardtype == "MSC00277" || inwardtype == "MSC00278") // Purchase Order and Sales Based and WithOut Purchase Order
				{
					if (!this._GrnDetails[i].productamount || !this._GrnDetails[i].invoiceunitprice) {
						this._CustomExceptionService.handleError("Product Details grid value should greater than 0");
						return;
					}
				}
				if (inwardtype === 'MSC00189' && !this._grndocuments.length) {
					this._CustomExceptionService.handleError("Purchase order document is mandatory.");
					return;
				}
			}

			if (this._action == 'create') {
				this._Grn.grnid = 0;
				this._Grn.createdon = new Date();
				this._GrnDetails[0].createdon = new Date();
				this._Grn.createdby = sessionStorage["userid"];
				this._Grn.receivedby = sessionStorage["userid"];
				this._GrnDetails[0].createdby = sessionStorage["userid"];
				this._Grn.accountingyear = new Date().getFullYear();
				this._Grn.branchid = parseInt(sessionStorage["BranchID"]);
				this._Grn.companyid = 0;
				this._GrnFORM = this.formconverstion(this._Grn, this._selectedDocuments, this._grndocuments);
				this._IsProgressSpinner = true;
				this._grnservice.create(this._GrnFORM).subscribe((result) => {
					const resutJSON = JSON.parse(JSON.stringify(result));
					if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
						this._grnid = resutJSON.grnId;
						this._Series = resutJSON.series;
						this._CustomExceptionService.handleSuccess(usererrors.Saved_Success_00);
						this._isDisabledsendtoapproval = false;
						this._IsProgressSpinner = false;
						this.isDisabledsave = true;
					} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); }
					this._IsProgressSpinner = false;
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			} else if (this._action == 'edit') {
				this._Grn.modifiedon = new Date();
				this._Grn.modifiedby = sessionStorage["userid"];
				this._GrnFORM = this.formconverstion(this._Grn, this._selectedDocuments, this._grndocuments);
				this._IsProgressSpinner = true;
				this._grnservice.edit(this._GrnFORM).subscribe((result) => {
					const resutJSON = JSON.parse(JSON.stringify(result));
					if (resutJSON.tranStatus.result == true && (resutJSON != null || resutJSON != undefined)) {
						this._grnid = resutJSON.grnId;
						this._Series = resutJSON.series;
						this._CustomExceptionService.handleSuccess(usererrors.Update_Success_04);
						this._isDisabledsendtoapproval = false
						this.isDisabledsave = true;
						this._IsProgressSpinner = false;
					} else { this._CustomExceptionService.handleError(resutJSON.tranStatus.lstErrorItem[0].message); }
					this._IsProgressSpinner = false;
				}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
			}
		}
	}
	RemoveProduct(pOthercharges: Grngetinwardproductdetailsview) {
		if (this._GrnDetails.length > 1) {
			var index = this._GrnDetails.indexOf(pOthercharges);
			this._GrnDetails.splice(index, 1)
			this.calculateamountdetails();
		}
	}
	formconverstion(_Grn: Grn, documents: File[], p_documentdetails: Grndocumentdetail[]) {
		let time = new Date();
		const formData: FormData = new FormData();
		for (var x in documents) {
			var index = p_documentdetails.findIndex(obj => obj.attachmentname == documents[x].name);
			console.log("Index", index);
			if (index != -1) {
				var NewFileName = time.getTime() + '_' + documents[x].name;
				p_documentdetails[index].attachmentname = NewFileName;
				p_documentdetails[index].attachmenturl = NewFileName;
				formData.append('files', documents[x], NewFileName);
			}
		}
		this._GoodsreceiptOBJ = {
			"Grn": this._Grn,
			"UserId": parseInt(sessionStorage["userid"]),
			"Grndetail": this._GrnDetails,
			"Grndocumentdetail": this._grndocuments,
			"Grnothercharge": this._OtherCharges,
		};
		var Goodsreceiptnote = JSON.stringify(this._GoodsreceiptOBJ);
		formData.append('strGrn', Goodsreceiptnote);
		return (formData);
	}
	reset(event) {
		this._submitted = true;
		this._grndocumentform.reset();
		this._grnform.reset();
		this._grndetailform.reset();
		this._GrnDetails = [];
		this._OtherCharges = [];
		this._grndocuments = [];
		this.InVoiceNo = "";
		this.InitializeGrnForm();
		this.InitializeGrndetailForm();
		this.InitializeGrndocumentForm();
		this.Initializetaxnameform();
		var l_othercharge = new Grnothercharge();
		l_othercharge.grnid = 0;
		l_othercharge.otherchargesamount = 0;
		l_othercharge.createdby = sessionStorage["userid"];
		l_othercharge.modifiedby = sessionStorage["userid"];
		l_othercharge.createdon = new Date();
		l_othercharge.modifiedon = new Date();
		this.isDisabledsave = false;
		this._isDisabledsendtoapproval = true;
		this._OtherCharges.push(l_othercharge);
		this._submitted = false;
	}
	SendToApproval(event) {
		var workflowid = 9;
		var _Userid = sessionStorage["userid"];
		var _branchid = parseInt(sessionStorage["BranchID"]);
		var grnid = this._grnid;
		var series = this._Series
		this._IsProgressSpinner = true;
		this._grnservice.SendToApproval(workflowid, _Userid, _branchid, grnid, this._Series).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._CustomExceptionService.handleSuccess(usererrors.Sendtoapproval_Success_05);
			this._isDisabledsendtoapproval = true;
			this.isDisabledsave = false;
			if (this._action == 'edit') {
				this.isDisabledsave = true;
			}
			if (this._action != 'edit') {
				this.reset(null);
			}
			this._IsProgressSpinner = false;
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; });
	}
	goBack(event) {
		this._router.navigate(['/vGoodsReceiptNote']);
	}
	onEd() {
		ace.config.set("fontSize", "14px");
		ace.config.set('basePath', 'https://unpkg.com/ace-builds@1.4.12/src-noconflict');
		const aceEditor = ace.edit(this.editor.nativeElement);
		aceEditor.getSession().setMode("ace/mode/json");
		var myObj = this._GoodsreceiptOBJ;
		var myJSON = JSON.stringify(myObj);
		aceEditor.session.setValue(myJSON);
	}
	HotKeyIntegration() {
		this._hotkeysService.reset();
		if (this._action == 'create') {
			this._hotkeysService.add(new Hotkey('ctrl+alt+c', (event: KeyboardEvent): boolean => {
				this.reset(null);
				return false; // Prevent bubbling
			}, ['INPUT', 'SELECT', 'TEXTAREA']));
		}
		this._hotkeysService.add(new Hotkey('ctrl+left', (event: KeyboardEvent): boolean => {
			this.goBack(null);
			return false; // Prevent bubbling
		}, ['INPUT', 'SELECT', 'TEXTAREA']));
	}
	showdocumentDialog(document: FileUpload, position: string) {
		if (!this._LastAddedDocument)
			return;
		let url = null;
		url = URL.createObjectURL(this._LastAddedDocument);
		if (!url)
			return;
		window.open(url, "MsgWindow", "width=800,height=800");
	}
	showdocumentGridDialog(position: string, _grndocument: any) {
		let url = null;
		if (_grndocument.attachmenturl) {
			url = _grndocument.attachmenturl;
		}
		if (!url)
			return;
		window.open(url, "MsgWindow", "width=800,height=800");
	}
	stockupdate(grndetail: any) {
		this._GrnDetail = grndetail;
		if (this.Ans == 0) {
			this.showconfirm('Are you sure you want to proceed this stocks for updation?', () => {
			}, null)
			return
		}
		var grnno = this._grnform.controls['grnno'].value;
		var inwardtype = this._grnform.controls['inwardtype'].value;
		var index = this._GrnDetails.findIndex(f => f.productid == grndetail.productid);
		this._IsProgressSpinner = true;
		this._grnservice.updatestock(grnno, grndetail.productid, grndetail.remainingquantity, this._userid, grndetail.lotnumber ?? 0, grndetail.oldlotno ?? 0, inwardtype).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._GrnDetails[index].remainingquantity = 0;
			this._IsProgressSpinner = false;
			this.Ans = 0;
			this._CustomExceptionService.handleSuccess("Stock Updated Successfully")
		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; this.Ans = 0; })
	}
	showconfirm(message: string, yesHandler: any, NoHandler?: any) {
		this.Stockmodel.showdialog = true;
		this.Stockmodel.message = message;
		this.Stockmodel.Yes = () => {
			if (typeof yesHandler === 'function')
				yesHandler();
			this.Stockmodel.showdialog = false;
		};
		this.Stockmodel.Cancel = () => {
			if (typeof NoHandler === 'function')
				NoHandler();
			this.Stockmodel.showdialog = false;
			return
		};
	}
	Yes() {
		this.Stockmodel.showdialog = false;
		this.Ans = 1;
		this.stockupdate(this._GrnDetail);
		return
	}
	Cancel() {
		this.Stockmodel.showdialog = false;
		this.Ans = 0;
		return
	}

	event: any = {
		value: []
	}

	FetchBarcode() {
		let type = this._grnform.get('inwardtype').value
		if (!type) {
			this._CustomExceptionService.handleWarning("Please Select Inward Type");
			return
		}
		setTimeout(() => {
			let barcodevalue = <string>this.invoicebarcode;
			if (!barcodevalue) {
				this.invoicebarcode = "";
				return;
			}
			barcodevalue = barcodevalue.trim();
			this.event.value.push(barcodevalue);
			this.isbarcode = true;
			this.onSelectinwardno(this.event);

			this.invoicebarcode = "";
			this.event.value = []
		}, 400)


	}
	confirmpriceupdate(grndetail: any) {
		this._GrnDetail = grndetail;
		this.confirmationService.confirm({
			message: 'Are you sure you want to proceed this Price Update?',
			accept: () => {
				this.PriceUpdate(this._GrnDetail);
			}
		});
	}



	PriceUpdate(grndetail: any) {
		var grnno = this._grnform.controls['grnno'].value;
		this._IsProgressSpinner = true;
		this._grnservice.PriceUpdate(grnno, grndetail.productid, this._userid).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			this._CustomExceptionService.handleSuccess("Price Updated Successfully");
			var index = this._GrnDetails.findIndex(f => f.productid == grndetail.productid);
			this._GrnDetails[index].ispriceupdate = true;

		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
	}

	confirmreceiverstockupdate(grndetail: any) {
		this._GrnDetail = grndetail;
		this.confirmationService.confirm({
			message: 'Are you sure you want to proceed this Receiver Stock Update?',
			accept: () => {
				this.UpdateReceiverStock(this._GrnDetail);
			}
		});
	}

	UpdateReceiverStock(grndetail: any) {
		var grnno = this._grnform.controls['grnno'].value;
		this._IsProgressSpinner = true;
		this._grnservice.UpdateReceiverStock(grnno, grndetail.productid, grndetail.lotnumber).subscribe((result) => {
			const resultJSON = JSON.parse(JSON.stringify(result));
			this._IsProgressSpinner = false;
			if (resultJSON.tranStatus.result == true) {
				var index = this._GrnDetails.findIndex(f => f.grndetailid == grndetail.grndetailid);
				this._GrnDetails[index].isreceiverstockupdate = true;
				this._CustomExceptionService.handleSuccess("Receiver Stock Updated Successfully");
			} else { this._CustomExceptionService.handleError("ERROR"); }

		}, error => { this._CustomExceptionService.handleError(error); this._IsProgressSpinner = false; })
	}
	
	PreventMinus(event: KeyboardEvent) {
		if (event.key === '-' || event.keyCode === 189) {
			event.preventDefault();
		}
	}

}