<div class="page-container">
    <div class="inner-page-container">
        <div class="page-loader" *ngIf="_IsProgressSpinner">
            <p-progressSpinner strokeWidth="5"></p-progressSpinner>
        </div>
        <div class="white">
            <div class="page-title">
                <div class="row">
                    <div class="col-md-5">
                        <h3>Advance Adjustment</h3>
                    </div>
                    <div class="col-md-7 text-right"> 
                        <div class="action-btn">
                            <button pButton pRipple type="submit" title="Save" icon="pi pi-save"
                                (click)="UpdateRefNo()"></button>
                            <button pButton pRipple type="button" (click)="reset()" title="Clear" icon="pi pi-trash"
                                class="" class="p-button-danger"></button>
                        </div>
                    </div>
                </div>
            </div>
            <div class="form-container scroll-y">
                <p-toast position="bottom-right"></p-toast>
                <form [formGroup]="_AdvanceAdjustmentForm">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Partytypes" formControlName="partytype"
                                        (onChange)="OnChangePartyType()" optionLabel="metasubdescription"
                                        [autoDisplayFirst]="false" optionValue="metasubcode" itemSize="30">
                                    </p-dropdown>
                                    <label for="fromaccount">Party Type <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Customers" formControlName="customerid" optionLabel="name"
                                        [autoDisplayFirst]="false" [showClear]="true" optionValue="ledgerid"
                                        [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="name"
                                        (onChange)="GetAdvanceAndSalesDetails()" [showClear]="true">
                                    </p-dropdown>
                                    <label for="fromaccount">Party Name <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _AdvanceAdjustmentForm.get('customerid').touched) && _AdvanceAdjustmentForm.get('customerid').errors?.SelectFromAccount">
                                    Please Select Party Name
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <p-dropdown [options]="_Currency" formControlName="currencyid"
                                        optionLabel="currencyname" [autoDisplayFirst]="false" [showClear]="true"
                                        (onChange)="GetAdvanceAndSalesDetails()" optionValue="currencyid"
                                        [virtualScroll]="true" itemSize="30" [filter]="true" filterBy="name"
                                        [showClear]="true">
                                    </p-dropdown>
                                    <label for="fromaccount">Currency <span class="hlt-txt">*</span></label>
                                </span>
                                <span class="text-danger"
                                    *ngIf="(_submitted || _AdvanceAdjustmentForm.get('currencyid').touched) && _AdvanceAdjustmentForm.get('currencyid').errors?.SelectFromAccount">
                                    Please Select Currency
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>
                <div class="row">
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Advance Details</h5>
                                </div>

                            </div>
                        </div>
                        
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" (input)="dtAdvanceDetails.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                            </p-toolbar>
                            <p-table #dtAdvanceDetails [value]="_AdvanceDetails" [rows]="15" [paginator]="true"
                                [rowsPerPageOptions]="[15,25,50,100]"
                                [globalFilterFields]="['billno','pendingamount']" [rowHover]="true"
                                dataKey="billno"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 80px;">

                                        </th>
                                        <th pSortableColumn="voucherno">Ref No<p-sortIcon field="voucherno">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="billdate">Date<p-sortIcon field="billdate">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="pendingamount">Adv Pending Amt<p-sortIcon
                                                field="pendingamount">
                                            </p-sortIcon>
                                        </th>
                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_AdvanceDetail let-i="rowIndex">
                                    <tr>
                                        <td style="text-align: center;width: 80px;">
                                            <p-checkbox [(ngModel)]="_AdvanceDetail.isadvanceselect" binary="true"
                                                (onChange)="OnAdvanceSelect($event,_AdvanceDetail,i)">
                                            </p-checkbox>
                                        </td>
                                        <td>
                                            {{_AdvanceDetail.billno}}
                                        </td>
                                        <td>
                                            {{_AdvanceDetail.billdate| date:'dd-MMM-yyyy'}}
                                        </td>
                                        <td>
                                            {{_AdvanceDetail.pendingamount|number:'1.2-2'}}
                                        </td>

                                    </tr>
                                </ng-template>
                            </p-table>
                        </div>
                    </div>
                    <div class="col-md-6">
                        <div class="sub-title">
                            <div class="row">
                                <div class="col-md-6">
                                    <h5>Invoice Details</h5>
                                </div>
                            </div>
                        </div>
                        <div class="card">
                            <p-toolbar styleClass="p-mb-4">
                                <ng-template pTemplate="left">
                                    <span class="p-input-icon-left">
                                        <i class="pi pi-search"></i>
                                        <input pInputText type="text" (input)="dtProductCategoryWiseStocks.filterGlobal($event.target.value, 'contains')"
                                            placeholder="Search..." />
                                    </span>
                                </ng-template>
                            </p-toolbar>
                            <p-table #dtProductCategoryWiseStocks [value]="_SalesDetails" [rows]="15" [paginator]="true"
                                [rowsPerPageOptions]="[15,25,50,100]"
                                [globalFilterFields]="['voucherno','pendingamount']" [rowHover]="true"
                                dataKey="voucherno"
                                currentPageReportTemplate="Showing {first} to {last} of {totalRecords} entries"
                                [showCurrentPageReport]="true">
                                <ng-template pTemplate="header">
                                    <tr>
                                        <th style="text-align: center;width: 80px;">
                                            
                                        </th>
                                        <th pSortableColumn="voucherno">Invoice No<p-sortIcon field="voucherno">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="billdate">Date<p-sortIcon field="billdate">
                                            </p-sortIcon>
                                        </th>
                                        <th pSortableColumn="pendingamount">Amount<p-sortIcon field="pendingamount">
                                            </p-sortIcon>
                                        </th>


                                    </tr>
                                </ng-template>
                                <ng-template pTemplate="body" let-_SalesDetail let-i="rowIndex">
                                    <tr>
                                        <td style="text-align: center;width: 80px;">
                                            <p-checkbox [(ngModel)]="_SalesDetail.issalesselect"
                                                [disabled]="_SalesDetail.issalesdisabled" binary="true"
                                                (onChange)="OnSalesSelect($event,_SalesDetail)">
                                            </p-checkbox>
                                        </td>
                                        <td>
                                            {{_SalesDetail.voucherno}}
                                        </td>
                                        <td>
                                            {{_SalesDetail.billdate| date:'dd-MMM-yyyy'}}
                                        </td>
                                        <td>
                                            {{_SalesDetail.pendingamount|number:'1.2-2'}}
                                        </td>

                                    </tr>
                                </ng-template>

                            </p-table>
                        </div>


                    </div>

                </div>
                <form [formGroup]="_AdvanceAdjustmentForm">
                    <table class="normal-table">
                        <tr>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="advanceamount" formControlName="advanceamount" disabled
                                        pInputText>
                                    <label for="fromaccount">Advance Amount <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;">
                                <span class="p-float-label">
                                    <input type="text" name="salesinvoiceamount" formControlName="salesinvoiceamount"
                                        disabled pInputText>
                                    <label for="fromaccount"> Invoice Amount <span class="hlt-txt">*</span></label>
                                </span>
                            </td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                            <td style="width: 20%;"></td>
                        </tr>
                    </table>
                </form>

            </div>
        </div>
    </div>
</div>